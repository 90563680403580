import React from 'react';
import {Link} from 'react-router-dom';

import {useIsLightMode} from '../../../redux/ui';

import NFTsList from '../../../components/NFTsList';
import ThemeAwareIcon from '../../../components/ThemeAwareIcon';

import './styles.scss';
import {addClassIfTrue} from '../../../services/utils';

const ProfilePageCollection = ({
  collapsedByDefault,
  collection,
  nfts,
  username,
}) => {
  const isLightMode = useIsLightMode();
  const [collapsed, setCollapsed] = React.useState(collapsedByDefault);

  const toggleCollapsed = React.useCallback(
    () => setCollapsed((prevState) => !prevState),
    [],
  );

  return (
    <div className="ProfilePageCollection">
      <div
        className={addClassIfTrue(
          'ProfilePageCollection__header',
          'ProfilePageCollection__header--isLightMode',
          isLightMode,
        )}>
        <ThemeAwareIcon
          className="ProfilePageCollection__caret"
          darkModeIconSrc={
            collapsed ? '/caret-right-dark.svg' : '/caret-down-dark.svg'
          }
          lightModeIconSrc={
            collapsed ? '/caret-right-light.svg' : '/caret-down-light.svg'
          }
          onClick={toggleCollapsed}
          alt="Close Collection"
        />
        <div
          className="ProfilePageCollection__collapse-button"
          onClick={toggleCollapsed}>
          <span>{collection.name}</span>
        </div>
        {collection.id && (
          <Link to={`/${username}/${collection.id}`}>
            <ThemeAwareIcon
              className="ProfilePageCollection__link"
              lightModeIconSrc="/link-light.svg"
              darkModeIconSrc="/link-dark.svg"
              alt="See Collection View"
            />
          </Link>
        )}
      </div>
      <NFTsList
        collectionId={collection.id}
        nfts={nfts}
        username={username}
        collapsed={collapsed}
      />
    </div>
  );
};

export default ProfilePageCollection;
