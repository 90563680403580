import React from 'react';
import {useDispatch} from 'react-redux';

import CustomModal from '../../../../components/CustomModal';
import FormInput from '../../../../components/Form/FormInput';
import FormTextarea from '../../../../components/Form/FormTextarea';
import ModalButtons from '../../../../components/ModalButtons';

import {addNewCollection, editCollection} from '../../../../redux/crypto';
import {useIsCreatorView} from '../../../../redux/ui';

import './styles.scss';

const AdminPageCollectionsAddModal = ({collectionToEdit, closeModal}) => {
  const isCreatorView = useIsCreatorView();
  const dispatch = useDispatch();

  const [name, setName] = React.useState(
    collectionToEdit ? collectionToEdit.name : '',
  );
  const [description, setDescription] = React.useState(
    collectionToEdit ? collectionToEdit.description : '',
  );

  const handleSubmit = React.useCallback(() => {
    if (name) {
      collectionToEdit
        ? dispatch(
            editCollection(collectionToEdit.id, name, description, closeModal),
          )
        : dispatch(
            addNewCollection(name, description, isCreatorView, closeModal),
          );
    }
  }, [
    closeModal,
    collectionToEdit,
    description,
    dispatch,
    isCreatorView,
    name,
  ]);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <CustomModal
      title={collectionToEdit ? 'Edit collection' : 'New Collection'}
      onRequestClose={closeModal}>
      <form onSubmit={onSubmit}>
        <FormInput
          autoFocus
          label="Collection name"
          maxLength={48}
          value={name}
          onChange={setName}
        />
        <FormTextarea
          label="description"
          value={description}
          onChange={setDescription}
        />
        <ModalButtons
          buttonText={
            collectionToEdit ? 'edit collection' : 'create collection'
          }
          closeModal={closeModal}
        />
      </form>
    </CustomModal>
  );
};

export default AdminPageCollectionsAddModal;
