import React from 'react';

import {useIsLightMode} from '../redux/ui';

const ThemeAwareIcon = React.forwardRef(
  ({alt, lightModeIconSrc, darkModeIconSrc, ...rest}, ref) => {
    const isLightMode = useIsLightMode();

    const src = React.useMemo(
      () => (isLightMode ? lightModeIconSrc : darkModeIconSrc),
      [lightModeIconSrc, darkModeIconSrc, isLightMode],
    );

    return <img ref={ref} {...rest} src={src} alt={alt} />;
  },
);

export default ThemeAwareIcon;
