import React from 'react';
import {useDispatch} from 'react-redux';
import {useAsyncDebounce} from 'react-table';

import {refreshAddresses} from '../../../redux/crypto';

import './styles.scss';

const NFTsTableToolbar = ({
  globalFilter,
  numRows,
  numSelected,
  onPageChange,
  setDisplayOverlay,
  setGlobalFilter,
  openModal,
  closeModal
}) => {
  // TODO: move dispatch, and refreshAddresses up to AdminNFTs class
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = React.useState(globalFilter);

  const onSearchChange = useAsyncDebounce(
    (value) => setGlobalFilter(value || undefined),
    200,
  );

  const onRefreshSuccess = (delay) => {
    if (delay && delay > 0) {
      setTimeout(() => setDisplayOverlay(false), delay);
    } else {
      setDisplayOverlay(false);
    }
  }

  const refreshAddressesClicked = React.useCallback(() => {
    setDisplayOverlay(true);
    dispatch(refreshAddresses(onRefreshSuccess));
  }, []);

  const addNFTClicked = React.useCallback(() => {
    openModal('add-nft')
  }, [])

  return (
    <div className="NFTsTableToolbar">
      <div className="NFTsTableToolbar__search">
        <input
          value={searchValue || ''}
          onChange={(e) => {
            const value = e.target.value;
            if (value && value.length > 0) {
              onPageChange(null, 0);
            }
            setSearchValue(value);
            onSearchChange(value);
          }}
          placeholder={`Search ${numRows} NFTs`}
          aria-label="search"
        />
        <img src="/search.svg" alt="search" />
      </div>
      <img style={{marginRight: 10}} src="/refresh.svg" onClick={refreshAddressesClicked} alt="refresh" />
      <img src="/addNFT.svg" onClick={addNFTClicked} alt="add individual NFT" />
    </div>
  );
};

export default NFTsTableToolbar;
