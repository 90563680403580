import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {resetPassword} from '../../../redux/user';

import {useQueryParams} from '../../../services/utils';

import CustomModal from '../../CustomModal';
import FormInput from '../../Form/FormInput';
import ModalButtons from '../../ModalButtons';

import '../../components.scss';

const LayoutHeaderResetPasswordModal = ({closeModal}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();

  const [buttonState, setButtonState] = React.useState('default');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');

  const handleCloseModal = React.useCallback(() => {
    history.replace(`/`);
    closeModal();
  }, [closeModal, history]);

  const handleSubmit = React.useCallback(() => {
    setErrorMsg('');
    setSuccessMsg('');

    let valid = true;

    if (password.length < 8 || password2 < 8) {
      setErrorMsg('Password must be at least 8 characters');
      valid = false;
    }
    if (password !== password2) {
      setErrorMsg('Passwords do not match');
      valid = false;
    }

    if (valid) {
      setButtonState('pending');
      dispatch(
        resetPassword(
          queryParams.get('code'),
          password,
          password2,
          (msg) => {
            setButtonState('default');
            setSuccessMsg(msg);
            history.push(`/reset_password`);
          },
          (msg) => {
            setButtonState('default');
            setErrorMsg(msg);
          },
        ),
      );
    } else {
      setButtonState('default');
    }
  }, [dispatch, history, password, password2, queryParams]);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <CustomModal title="Reset Password" onRequestClose={handleCloseModal}>
      <form onSubmit={onSubmit}>
        <FormInput
          autoFocus
          label="Password"
          type="password"
          value={password}
          onChange={setPassword}
        />
        <FormInput
          label="Confirm Password"
          type="password"
          value={password2}
          onChange={setPassword2}
        />
        <ModalButtons
          buttonText="Reset Password"
          buttonState={buttonState}
          buttonPendingText="resetting..."
          closeModal={handleCloseModal}
        />
        {successMsg.length > 0 && (
          <div className="form__validation success">{successMsg}</div>
        )}
        {errorMsg.length > 0 && (
          <div className="form__validation">{errorMsg}</div>
        )}
      </form>
    </CustomModal>
  );
};

export default LayoutHeaderResetPasswordModal;
