import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import moment from 'moment';
import * as Web3 from 'web3'

import opensea from '../../services/opensea';
import { initializeOpensea, getListings, getBestOffer } from '../../services/opensea';
import { OrderSide } from 'opensea-js/lib/types'

import NFTPageItem from './NFTPageItem';

const ANIMATION_EXCLUDED_COLLECTIONS = ["station3-patron"]

const NFTPageOpensea = ({asset, nft}) => {
  const location = useLocation();

  const [sellOrder, setSellOrder] = useState(null)
  const [buyOrder, setBuyOrder] = useState(null)
  const [collection, setCollection] = useState(null)

  const isPolygon = location.pathname.includes('polygon');

  let currentAssetsEvents = useSelector(
    (state) => state.Crypto.currentAssetsEvents,
  );

  const _isImage = (url) => {
    return url && (url.includes('.jpg') || url.includes('.jpeg') || url.includes('.png'))
  }

  const _isVideo = (url) => {
    return url && (url.includes('.mp4') || url.includes('.mov'))
  }

  const _isAudio = (url) => {
    return url.includes('.mp3') || url.includes('.wav')
  }

  const isERC1155 = () => {
    // opensea's minting contract is ERC1155 but doesn't behave as such, so exclude that from our calculations
    return asset.token_standard === 'erc1155'
  };

  const _getListings = async (tokenAddress, tokenId, side=OrderSide.Sell) => {
    try {
      const orders = await getListings(tokenAddress, tokenId)
      if (orders.length > 1) {
        console.log('found orders > 1', orders)
      }
      return isERC1155() ? orders[orders.length-1] : orders[0]
    } catch (err) {
      console.error(err)
    }
  }

  const _getOffers = async (slug, tokenId, side=OrderSide.Sell) => {
    try {
      const offer = await getBestOffer(slug, tokenId)
      return offer
    } catch (err) {
      console.error(err)
    }
  }

  React.useEffect(() => {
    if (!collection) {
      opensea.getCollection(asset.collection, setCollection)
    }
  }, [collection])

  React.useEffect(() => {
    if (!sellOrder) {
      _getListings(asset.contract_address, asset.chain_token_id).then(order => {
        setSellOrder(order)
      })
    }
  }, [])

  React.useEffect(() => {
    if (!buyOrder) {
      console.log(asset)
      _getOffers(asset.collection, asset.chain_token_id, OrderSide.Buy).then(order => {
        setBuyOrder(order)
      })
    }
  }, [])

  let image_url = asset.image_url;
  let video_url, audio_url, svg_url, model_url;

  if (asset.animation_url && asset.animation_url.includes('.glb')) {
    model_url = asset.animation_url;
  } else if (asset.animation_url && !ANIMATION_EXCLUDED_COLLECTIONS.includes(asset.collection)) {
    if (asset.animation_url.includes('artblocks')) {
      svg_url = asset.animation_url
    } else {
      video_url = asset.animation_url
    }
  } else if (_isVideo(asset.image_url)) {
    video_url = asset.image_url;
  } else if (asset.animation_url && _isAudio(asset.animation_url)) {
    audio_url = asset.animation_url;
  } else if (asset.animation_url && !_isImage(asset.animation_url)) {
    svg_url = asset.animation_original_url;
  }

  if (!image_url) {
    image_url = nft.image_url;
  }

  if (!image_url) {
    image_url = 'https://via.placeholder.com/640x640/000?text=Image%20cannot%20be%20displayed';
  }

  if (image_url && image_url.includes('/ipfs/')) {
    const parts = image_url.split('/ipfs/')
    image_url = `${process.env.REACT_APP_IPFS_GATEWAY}${parts[parts.length-1]}`
  }

  let imageCls = image_url.indexOf('.svg') > -1 ? 'svg img' : 'img';
  let mediaDiv = <img className={imageCls} src={image_url} alt={image_url} />;

  if (video_url && video_url.includes('/ipfs/')) {
    const parts = video_url.split('/ipfs/')
    video_url = `https://ipfs.io/ipfs/${parts[parts.length-1]}`
  }

  if (model_url) {
    mediaDiv = (
      <model-viewer
        auto-rotate="true"
        autoplay="true"
        camera-controls="true"
        className="model-viewer"
        src={model_url}
        ar-status="not-presenting"
      ></model-viewer>
    )
  } else if (video_url) {
    mediaDiv = (
      <video controls="controls" preload="metadata" autoPlay loop muted poster={image_url}>
        <source src={video_url} type="video/mp4" />
      </video>
    );
  } else if (audio_url) {
    mediaDiv = (
      <div className="audio">
        <img className={imageCls} src={image_url} alt={image_url} />
        <audio
          controls
          controlsList="nodownload"
          loop
          preload="none"
          src={audio_url}></audio>
      </div>
    );
  } else if (svg_url) {
    mediaDiv = <iframe title={svg_url} src={svg_url} />;
  }

  let mintDate = asset.created_at
    ? 'Retrieving...'
    : moment(asset.created_at).format('MMMM D, YYYY');
  if (currentAssetsEvents && currentAssetsEvents.length) {
    mintDate = moment(
      currentAssetsEvents[currentAssetsEvents.length - 1].created_date,
    ).format('MMMM D, YYYY');
  }

  let listingPrice = null
  let listingCurrency = null
  let listingQuantity = 1
  if (sellOrder) {
    listingQuantity = parseInt(sellOrder.protocol_data.parameters.offer[0].endAmount)
    listingPrice = +parseFloat(Web3.utils.fromWei(sellOrder.current_price.toString()) / listingQuantity).toFixed(4)
    listingCurrency = (sellOrder.taker_asset_bundle && sellOrder.taker_asset_bundle.asset_contract) ? sellOrder.taker_asset_bundle.asset_contract.symbol : 'ETH'
  }

  let offerPrice = null;
  let offerCurrency = null;
  if (buyOrder) {
    offerPrice = +parseFloat(Web3.utils.fromWei(buyOrder.value.toString())).toFixed(4)
    offerCurrency = (buyOrder.currency) || 'WETH'
  }

  return (
    <NFTPageItem
      allowFullscreen={!Boolean(video_url)}
      collection={collection || asset.collection}
      username={asset.creator?.user?.username}
      description={asset.description}
      imageUrl={image_url}
      listingPrice={listingPrice && `${listingPrice} ${listingCurrency}`}
      mediaDiv={mediaDiv}
      // mintDate={mintDate}
      mintDateDarkModeIcon={isPolygon ? '/polygon-dark.svg' : '/eth-dark.svg'}
      mintDateLightModeIcon={
        isPolygon ? '/polygon-light.svg' : '/eth-light.svg'
      }
      name={asset.name || asset.chain_token_id}
      nft={nft}
      offerPrice={offerPrice && `${offerPrice} ${offerCurrency}`}
      linkDiv={
        <a target="_blank" rel="noreferrer" href={`${asset.opensea_url}?ref=${process.env.REACT_APP_STCL_ADDRESS}`}>
          View on opensea.io
        </a>
      }
      traits={asset.traits}
    />
  );
};

export default NFTPageOpensea;
