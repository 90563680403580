import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {addAddress} from '../../redux/crypto';

import {handleSolanaAddress} from '../../services/solana';
import {handleTopshotAddress} from '../../services/topshot';

import {
  getCoinTypeFromAddress,
  getCoinTypeFromAlias,
  getEthAddress,
  getTezAddress,
} from '../../services/addressUtils';

import CustomModal from '../CustomModal';
import FormInput from '../Form/FormInput';
import ModalButtons from '../ModalButtons';
import TextButton from '../TextButton';

import AddAddressModalAddresses from './AddAddressModalAddresses';

import './styles.scss';

// TODO: move this to env var
// long_basking_shark3007
const ENABLE_FLOW = false;

const AddAddressModal = ({openModal, closeModal, hideAddresses, onAddSuccess}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User.user);

  const [address, setAddress] = React.useState('');
  const [coinType, setCoinType] = React.useState('');
  const [alias, setAlias] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [addressValidationMessage, setAddressValidationMessage] =
    React.useState('');
  const [addressIsValid, setAddressIsValid] = React.useState();
  const [usernameValidationMessage, setUsernameValidationMessage] =
    React.useState('');
  const [usernameIsValid, setUsernameIsValid] = React.useState();

  const resetAddress = React.useCallback(() => {
    setAddress('');
    setAlias('');
    setAddressValidationMessage('');
    setAddressIsValid(false);
  }, []);

  const resetUsername = React.useCallback(() => {
    setUsername('');
    setUsernameValidationMessage('');
    setUsernameIsValid(false);
  }, []);

  const validateAddressAndSet = React.useCallback(
    (value) => {
      resetUsername();
      setAddress(value);
      setAlias('');

      if (!value) {
        setAddressValidationMessage('');
        setAddressIsValid(false);
      } else {
        const coinTypeFromAlias = getCoinTypeFromAlias(value);
        if (coinTypeFromAlias) {
          // .eth or .tez domain
          setAddressIsValid();
          setAddressValidationMessage(
            `Checking for valid .${coinTypeFromAlias[0]} address...`,
          );
          (coinTypeFromAlias[0] === 'eth'
            ? getEthAddress(value)
            : getTezAddress(value)
          ).then((address) => {
            if (address) {
              setAlias(value);
              setAddress(address);
              setAddressValidationMessage(
                `Valid ${coinTypeFromAlias[1]} address`,
              );
              setCoinType(coinTypeFromAlias[1])
              setAddressIsValid(true);
            } else {
              setAddressValidationMessage('Invalid address (ETH / SOL / XTZ / BTC currently supported)');
              setAddressIsValid(false);
            }
          }).catch(err => {
            console.warn(err)
            setAddressValidationMessage('Invalid address (ETH / SOL / XTZ / BTC currently supported)');
            setAddressIsValid(false);
          });
        } else {
          // validate address for eth / tez / sol / btc
          const coinType = getCoinTypeFromAddress(value)
          setCoinType(coinType)
          if (coinType) {
            setAddressValidationMessage(`Valid ${coinType} address`);
            setAddressIsValid(true);
          } else {
            setAddressValidationMessage('Invalid address (ETH / SOL / XTZ / BTC currently supported)');
            setAddressIsValid(false);
          }
        }
      }
    },
    [resetUsername],
  );

  // NBA top shot specific code
  const validateUsernameAndSet = React.useCallback(
    (value) => {
      resetAddress();
      setUsername(value);

      if (value.length > 2) {
        setUsernameValidationMessage(`https://nbatopshot.com/user/@${value}`);
        setUsernameIsValid(true);
      } else {
        !value.length
          ? setUsernameValidationMessage('')
          : setUsernameValidationMessage(
              'Invalid: Username must be between 3 and 30 characters.',
            );
        setUsernameIsValid(false);
      }
    },
    [resetAddress],
  );

  const handleOnSuccess = React.useCallback(
    () => (onAddSuccess ? onAddSuccess() : closeModal()),
    [onAddSuccess, closeModal],
  );

  const addAddressFailure = React.useCallback((err) => {
    setAddressValidationMessage(err);
    setAddressIsValid(false);
  }, []);

  const addUsernameFailure = React.useCallback((err) => {
    setUsernameValidationMessage(err);
    setUsernameIsValid(false);
  }, []);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();

      if (addressIsValid) {
        dispatch(
          addAddress(
            address,
            alias,
            coinType,
            handleOnSuccess,
            addAddressFailure,
          ),
        );
      } else {
        dispatch(
          handleTopshotAddress(username, handleOnSuccess, addUsernameFailure),
        );
      }
    },
    [
      addAddressFailure,
      address,
      addressIsValid,
      addUsernameFailure,
      alias,
      coinType,
      dispatch,
      handleOnSuccess,
      username,
    ],
  );

  return (
    <CustomModal
      title="Add a wallet"
      titleButton={
        user ?
        (<TextButton onClick={() => openModal('add-nft')}>
          Add Individual NFT
        </TextButton>)
        :
        (<div></div>)
      }
      onRequestClose={closeModal}
    >
      {!hideAddresses && <AddAddressModalAddresses />}
      <form onSubmit={onSubmit}>
        <FormInput
          autoFocus
          isValid={addressIsValid}
          label="Wallet Address"
          onChange={validateAddressAndSet}
          placeholder="ETH / SOL / XTZ / BTC Address"
          validationMessage={addressValidationMessage}
          value={alias || address}
        />
        {ENABLE_FLOW && (
          <FormInput
            isValid={usernameIsValid}
            label="TopShot Username"
            onChange={validateUsernameAndSet}
            placeholder="Username"
            validationMessage={usernameValidationMessage}
            value={username}
          />
        )}
        <ModalButtons
          buttonText="Add wallet"
          buttonState={
            addressIsValid || usernameIsValid ? 'default' : 'disabled'
          }
          buttonPendingText="Adding wallet..."
          closeModal={closeModal}
        />
      </form>
    </CustomModal>
  );
};

export default AddAddressModal;
