import React from 'react';
import {useDispatch} from 'react-redux';

import {toggleNFT, hideNFTs, showNFTs} from '../../../redux/crypto';
import {useIsCreatorView} from '../../../redux/ui';

import {useNFTsForTable} from '../../../services/NFTUtils';

import CollectorCreatorToggle from '../../../components/CollectorCreatorToggle';
import NFTsTable from '../../../components/NFTsTable';
import AddAddressModal from '../../../components/AddAddressModal';
import AddNFTModal from '../../../components/AddNFTModal';
import AddNftsByFileModal from '../../../components/AddNFTModal/AddNftsByFileModal';

import AdminPageNFTsAddToCollectionModal from './AdminPageNFTsAddToCollectionModal';
import AdminPageNFTsNotesModal from './AdminPageNFTsNotesModal';
import AdminPageNFTsRemoveModal from './Remove'

import {getColumns} from './getColumns';

import './styles.scss';

const AdminNFTs = () => {
  const isCreatorView = useIsCreatorView();
  const dispatch = useDispatch();

  const [displayOverlay, setDisplayOverlay] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedNFTs, setSelectedNFTs] = React.useState([]);
  const [nftToEditNotes, setNFTToEditNotes] = React.useState(null);

  const [nft, setNFT] = React.useState(null)

  const data = useNFTsForTable();

  const toggleShowSelected = (selectedRowIds) => {
    const selectedNFTs = Object.keys(selectedRowIds).map((i) => data[i].id);
    const hidden = Object.keys(selectedRowIds).some((i) => !data[i].enabled);
    // if one NFT is hidden, show them all
    // otherwise if all NFTs are showing. hide them all
    const showHideFunc = hidden ? showNFTs : hideNFTs;
    dispatch(showHideFunc(selectedNFTs));
  };

  const addToCollection = (selectedRowIds) => {
    const newSelectedNFTs = [];

    Object.keys(selectedRowIds).forEach((i) => {
      newSelectedNFTs.push(data[i]);
    });

    setSelectedNFTs(newSelectedNFTs);
    setIsOpen('add-to-collection');
  };

  const editNFTNotes = (nft) => {
    setNFTToEditNotes(nft);
    setIsOpen('edit-nft-notes');
  };

  const toggleShowNFT = React.useCallback((nftId) => {
    dispatch(toggleNFT(nftId));
  }, []);

  const removeNFTClicked = (nft) => {
    setNFT(nft)
    setIsOpen('remove-nft')
  }

  const openModal = React.useCallback((modalType) => setIsOpen(modalType), []);
  const closeModal = React.useCallback(() => setIsOpen(false), []);

  const columns = React.useMemo(
    () => getColumns(addToCollection, toggleShowNFT, editNFTNotes, removeNFTClicked),
    [data, isCreatorView],
  )

  const addNFTClicked = React.useCallback(() => {
    openModal('add-nft')
  }, [])

  const addNftsByFileClicked = React.useCallback(() => {
    openModal('add-nft-by-file')
  }, [])

  return (
    <div className="AdminPageNFTs">
      {/*<CollectorCreatorToggle
        baseClassName="AdminPage"
        collectorText="Collected"
        creatorText="Created"
      />*/}
      {data.length === 0 &&
        <div>
          <div className='AdminPageCollections__item-container'>
            <div className={'AdminPageCollections__item italic margin-top'}>
              {!isCreatorView &&
                "You have not added a wallet containing any collected NFTs to your profile"
              }
              {isCreatorView &&
                "You have not added a wallet containing any created NFTs to your profile"
              }
            </div>
            <img src="/addNFT.svg" onClick={addNFTClicked} alt="add individual NFT" />
          </div>
          <div className='AdminPageCollections__subtext'>Organize your NFTs into collections. NFTs not assigned to a collection will appear under an "Uncategorized" heading.</div>
        </div>
      }
      {data.length > 0 &&
        <NFTsTable
          addToCollection={addToCollection}
          columns={columns}
          data={data}
          setDisplayOverlay={setDisplayOverlay}
          toggleShowSelected={toggleShowSelected}
          openModal={openModal}
          closeModal={closeModal}
        />
      }
      {displayOverlay && (
        <div className="AdminPageNFTs__overlay">
          <div>Loading...</div>
        </div>
      )}
      {modalIsOpen === 'add-to-collection' && (
        <AdminPageNFTsAddToCollectionModal
          closeModal={closeModal}
          nfts={selectedNFTs}
        />
      )}
      {modalIsOpen === 'edit-nft-notes' && (
        <AdminPageNFTsNotesModal closeModal={closeModal} nft={nftToEditNotes} />
      )}
      {modalIsOpen === 'add-nft' && (
        <AddNFTModal openModal={openModal} closeModal={closeModal} />
      )}
      {modalIsOpen === 'add-nft-by-file' && (
        <AddNftsByFileModal openModal={openModal} closeModal={closeModal} />
      )}
      {modalIsOpen === 'remove-nft' && (
        <AdminPageNFTsRemoveModal nft={nft} openModal={openModal} closeModal={closeModal} />
      )}
      {modalIsOpen === 'add-address' && (
        <AddAddressModal openModal={openModal} closeModal={closeModal} hideAddresses />
      )}
    </div>
  );
};

export default AdminNFTs;
