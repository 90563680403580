import {useDispatch} from 'react-redux';

import {removeNewCollection} from '../../../redux/crypto';

import CustomModal from '../../../components/CustomModal';
import ModalButtons from '../../../components/ModalButtons';

const AdminPageCollectionsRemoveModal = ({collection, index, closeModal}) => {
  const dispatch = useDispatch();

  const removeCollection = () =>
    dispatch(removeNewCollection(collection.id, index, closeModal));

  return (
    <CustomModal title="Delete collection" onRequestClose={closeModal}>
      <div className="modal-boxed-text">{collection.name}</div>
      <div className="modal-confirmation-title">Are you sure?</div>
      <div className="modal-confirmation-text">
        Deleting this collection from your profile will erase record of which
        NFT’s you assigned to the collection, as well as break any links on
        other sites pointed at this collection. This will not delete your NFTs.
      </div>
      <ModalButtons
        buttonType="default"
        buttonClassName="red"
        buttonOnClick={removeCollection}
        buttonText="Yes, Delete"
        closeModal={closeModal}
      />
    </CustomModal>
  );
};

export default AdminPageCollectionsRemoveModal;
