import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import CHAINS from '../../consts/Chains';

import {removeAddress} from '../../redux/crypto';

const AddAddressModalAddresses = () => {
  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.Crypto.addresses);

  const removeAddressClicked = React.useCallback(
    (addressId) => {
      dispatch(removeAddress(addressId));
    },
    [dispatch],
  );

  return addresses && addresses.length ? (
    <div className="AddAddressModalAddresses">
      {CHAINS.map((x) => {
        const filteredAddresses = addresses.filter((a) => a.chain === x.chain);

        return (
          filteredAddresses.length > 0 && (
            <div key={x.name}>
              <div className="AddAddressModalAddresses__subheader">
                {x.name}
              </div>
              {filteredAddresses.map((address) => (
                <div
                  key={address.address}
                  className="AddAddressModalAddresses__address">
                  <span
                    className="AddAddressModalAddresses__address__remove"
                    onClick={() => removeAddressClicked(address.id)}>
                    ×
                  </span>
                  <span className="AddAddressModalAddresses__address__value">{address.alias || address.address}</span>
                </div>
              ))}
            </div>
          )
        );
      })}
    </div>
  ) : null;
};

export default AddAddressModalAddresses;
