import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import {addClassIfTrue} from '../../services/utils';

import './styles.scss';

const ProfileHeader = ({
  avatarSrc = '/profile.svg',
  avatarTooltipTitle,
  bio,
  button,
  error,
  onAvatarClick,
  username,
}) => (
  <div className="ProfileHeader">
    {avatarTooltipTitle ? (
      <Tooltip title={avatarTooltipTitle} aria-label="add" placement="right">
        <img
          className="ProfileHeader__img ProfileHeader__img__clickable"
          onClick={onAvatarClick}
          src={avatarSrc}
          alt="avatar"
        />
      </Tooltip>
    ) : (
      <img className="ProfileHeader__img" src={avatarSrc} alt="avatar" />
    )}
    {error && <span className="ProfileHeader__error">{error}</span>}
    <div
      className={addClassIfTrue(
        'ProfileHeader__username-wrapper',
        'ProfileHeader__username-wrapper--withButton',
        button,
      )}>
      <span className="ProfileHeader__username">{username}</span>
      <div className="ProfileHeader__button">{button}</div>
    </div>
    {bio && <span className="ProfileHeader__bio">{bio}</span>}
  </div>
);

export default ProfileHeader;
