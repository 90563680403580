import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useRouteMatch} from 'react-router-dom';
import {isEmpty} from 'lodash';

import {
  getAddresses,
  removeGuest,
  getNFTsByAddresses,
} from '../../redux/crypto';
import {useIsCreatorView} from '../../redux/ui';

import {getNFTsForCollection} from '../../services/utils';

import AddAddressModal from '../../components/AddAddressModal';
import Button from '../../components/Button';
import CollectorCreatorToggle from '../../components/CollectorCreatorToggle';
import CopyButton from '../../components/CopyButton';
import FloatingMessage from '../../components/FloatingMessage';
import GllryMetaTags from '../../components/GllryMetaTags';
import ProfileHeader from '../../components/ProfileHeader';

import ProfilePageCollection from './ProfilePageCollection';
import ProfilePageNftsLoadingModal from './ProfilePageNftsLoadingModal';

import './styles.scss';

// covalent key: ckey_863f68d285b34be982f632bc093

const ProfilePage = () => {
  const dispatch = useDispatch();
  const isCreatorView = useIsCreatorView();
  const {
    params: {username},
  } = useRouteMatch();

  const user = useSelector((state) => state.User.user);

  const isNewUserView = username === 'app';

  const isGuestView =
    username && !isNewUserView && (!user || user.username !== username);

  const guestUser = useSelector((state) => state.Crypto.guest.user);
  const addresses = useSelector((state) =>
    isGuestView ? state.Crypto.guest.addresses : state.Crypto.addresses,
  );
  const collections = useSelector((state) =>
    isGuestView
      ? state.Crypto.guest.newCollections
      : state.Crypto.newCollections,
  );

  const collectionNFTs = useSelector((state) =>
    isGuestView
      ? state.Crypto.guest.collectionNFTs
      : state.Crypto.collectionNFTs,
  );

  const nfts = useSelector((state) =>
    isGuestView ? state.Crypto.guest.nfts : state.Crypto.nfts,
  );

  const uncategorizedNFTs = useSelector((state) =>
    isGuestView
      ? state.Crypto.guest.uncategorizedNFTs
      : state.Crypto.uncategorizedNFTs,
  );

  const guestUsername = useSelector((state) => state.Crypto.guest.username);

  const [appState, setAppState] = React.useState({
    loading: true,
    initialLoad: false,
  });

  const [floatingMessage, setFloatingMessage] = React.useState(false);
  const [openModal, setOpenModal] = React.useState();

  const closeModal = React.useCallback(() => setOpenModal(undefined), []);

  const onAddSuccess = React.useCallback(() => {
    addresses.length < 2 ? setOpenModal('nfts-loading') : closeModal();
  }, [addresses, closeModal]);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    let foundGuest = false;

    if (isGuestView) {
      if (username !== guestUsername) {
        dispatch(removeGuest());
      }
      foundGuest = true;
      dispatch(getAddresses(username));
    } else {
      dispatch(removeGuest());
    }

    if (!addresses || !addresses.length) {
      setAppState({loading: false, initialLoad: true});
    } else {
      setAppState({loading: false, initialLoad: false});
    }

    if (!foundGuest && !user && addresses.length > 0) {
      // not logged in, but addresses found. get NFTs from server
      setFloatingMessage('Loading NFTs...');
      dispatch(getNFTsByAddresses(addresses, () => setFloatingMessage(false)));
    }
  }, []);

  if (appState.initialLoad && ((addresses && addresses.length) || (collections && collections.length))) {
    setAppState({initialLoad: false});
  }

  React.useEffect(() => {
    if (
      !isGuestView &&
      isEmpty(addresses) &&
      isEmpty(nfts) &&
      isNewUserView
    ) {
      setOpenModal('add-address');
    }
  }, [appState, addresses, nfts, isGuestView, isNewUserView]);

  const filteredCollections = React.useMemo(
    () =>
      collections
        .filter((c) => (isCreatorView ? c.creation : !c.creation))
        .filter((c) => c.enabled),
    [isCreatorView, collections],
  );

  const filteredNFTs = React.useMemo(
    () => nfts.filter((n) => (isCreatorView ? n.creation : !n.creation)),
    [isCreatorView, nfts],
  );

  // handle uncategorized collection
  const filteredUncategorizedNFTs = React.useMemo(
    () =>
      uncategorizedNFTs
        .filter((n) => (isCreatorView ? n.creation : !n.creation))
        .filter((nft) => nft.enabled),
    [isCreatorView, uncategorizedNFTs],
  );

  if (isNewUserView && user) {
    return <Redirect to={`/${user.username}`} />;
  }

  let bio = '';
  let displayName = username;
  let profile_picture_url = null;

  if (isGuestView && guestUser) {
    bio = guestUser.bio;
    displayName = guestUser.display_name || guestUser.username;
    profile_picture_url = guestUser.profile_picture_url;
  } else if (user) {
    bio = user.bio;
    displayName = user.display_name || user.username;
    profile_picture_url = user.profile_picture_url;
  } else if (isNewUserView) {
    displayName = '(unsaved profile)'
  }

  let avatarSrc;

  if (profile_picture_url) {
    if (profile_picture_url.startsWith('blob')) {
      avatarSrc = profile_picture_url;
    } else {
      avatarSrc = `https://gllry-image-uploads.s3.amazonaws.com/${profile_picture_url}`;
    }
  }

  return (
    <div className="ProfilePage">
      <GllryMetaTags
        title={`${displayName} | GLLRY`}
        description={bio}
        url={window.location.href}
        imageUrl={avatarSrc}
      />
      <ProfileHeader
        avatarSrc={avatarSrc}
        bio={bio}
        button={false && <CopyButton />}
        username={displayName}
      />
      {(!isGuestView && (isNewUserView || (user && (!addresses || !addresses.length) && (!nfts || !nfts.length)))) &&
        (
          <Button
            text="Add a wallet"
            onClick={() => setOpenModal('add-address')}
            className="ProfilePage__button"
          />
        )
      }
      {nfts.length > 0 && false && (
        <CollectorCreatorToggle baseClassName="ProfilePage" />
      )}
      {filteredCollections.map((collection, i) => (
        <ProfilePageCollection
          key={`collection_${collection.name}_${i}`}
          nfts={getNFTsForCollection(
            collection.id,
            filteredNFTs,
            collectionNFTs,
          )}
          collection={collection}
          collapsedByDefault={false && filteredCollections.length > 5 && i > 0}
          username={username}
        />
      ))}
      {filteredUncategorizedNFTs.length > 0 && (
        <ProfilePageCollection
          key="uncategorized"
          nfts={filteredUncategorizedNFTs}
          collection={{name: 'Uncategorized'}}
          collapsedByDefault={filteredCollections.length > 5}
          username={username}
        />
      )}
      {openModal === 'add-address' && (
        <AddAddressModal closeModal={closeModal} onAddSuccess={onAddSuccess} />
      )}
      {openModal === 'nfts-loading' && (
        <ProfilePageNftsLoadingModal
          closeModal={closeModal}
          isLoggedIn={Boolean(user)}
        />
      )}
      {floatingMessage && <FloatingMessage text={floatingMessage} />}
    </div>
  );
};

export default ProfilePage;
