import {useSelector} from 'react-redux';

import {USER_LOGOUT} from './user';

/**
 * Selectors
 */

export const useIsCreatorView = () =>
  useSelector((state) => state.Ui.isCreatorView);

export const useIsLightMode = () =>
  useSelector((state) => state.Ui.isLightMode);

/**
 * Constants
 */

const SET_IS_CREATOR_VIEW = 'SET_IS_CREATOR_VIEW';
const SET_IS_LIGHT_MODE = 'SET_IS_LIGHT_MODE';

/*
  Actions logic
*/
export function setIsCreatorView(isCreatorView) {
  return (dispatch) => {
    localStorage.setItem('isCreatorView', isCreatorView);
    dispatch({type: SET_IS_CREATOR_VIEW, payload: isCreatorView});
  };
}

export function setIsLightMode(isLightMode) {
  return (dispatch) => {
    localStorage.setItem('isLightMode', isLightMode);
    dispatch({type: SET_IS_LIGHT_MODE, payload: isLightMode});
  };
}

/*
  Reducer logic
*/
const initialState = {
  isCreatorView: false,
  isLightMode: localStorage.getItem('isLightMode'),
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_CREATOR_VIEW:
      return {
        ...state,
        isCreatorView: false,
      };
    case SET_IS_LIGHT_MODE:
      return {
        ...state,
        isLightMode: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        isLightMode: false,
        isCreatorView: false
      }
    default:
      return state;
  }
}
