import Bugsnag from '@bugsnag/js';

const apiUrl = process.env.REACT_APP_API_PREFIX;

export async function callApi(
  endpoint,
  options = {method: 'get'},
  body = {},
  withAuth = false,
) {
  const url = `${apiUrl}/${endpoint}`;

  let headers = {
    'Content-Type': 'application/json',
  };
  if (withAuth) {
    const accessToken = localStorage.getItem('accessToken');
    headers['Authorization'] = `Bearer ${accessToken}`;
  } else {
    headers['Wlt-Client-ID'] = process.env.REACT_APP_CLIENT_ID;
  }
  const fetchOptions = {
    ...options,
    headers,
  };
  if (options.method && options.method !== 'get') {
    fetchOptions.body = JSON.stringify(body);
  }

  return fetch(url, fetchOptions)
    .then((res) => {
      // console.log(res)
      return res.json();
    })
    .then((data) => {
      if (data.length === 0) {
        return [];
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
      Bugsnag.notify(error);
      return {error}
    });
}

// EXTERNAL APIs
export function uploadPhoto(uploadUrl, file) {
  return fetch(uploadUrl, {
    method: 'PUT',
    headers: {'Content-Type': 'image/*', 'x-amz-acl': 'public-read'},
    body: file,
  }).then((res) => {
    console.log(res);
  });
}

// APIs
export function getVersion() {
  return callApi('version');
}

// AUTH APIs
export function login(username, password) {
  return callApi('login', {method: 'post'}, {username, password});
}

export function register(username, password, email = null, addresses = []) {
  return callApi(
    'register',
    {method: 'post'},
    {username, password, email, addresses},
  );
}

export function forgotPassword(email) {
  return callApi('forgot_password', {method: 'post'}, {email});
}

export function resetPassword(code, password, passwordConfirm) {
  return callApi(
    'reset_password',
    {method: 'post'},
    {code, password, passwordConfirm},
  );
}

export function getUser() {
  return callApi('user', {}, {}, true);
}

export function updateUser(
  username,
  displayName,
  passwordOld,
  password,
  passwordConfirm,
  bio,
) {
  return callApi(
    'user',
    {method: 'post'},
    {username, displayName, passwordOld, password, passwordConfirm, bio},
    true,
  );
}

export function updateUserPhoto(fileType) {
  return callApi('user/profile_picture', {method: 'post'}, {fileType}, true);
}

// WLT APIs
export function getAddresses(username) {
  return callApi(`addresses/${username}`);
}

export function getAddressesAuth() {
  return callApi('addresses', {}, {}, true);
}

export function addAddress(address, alias, chain) {
  return callApi('addresses', {method: 'post'}, {address, alias, chain}, true);
}

export function addTopshotAddress(address, alias, nft_ids) {
  return callApi(
    'addresses/topshot',
    {method: 'post'},
    {address, alias, nft_ids},
    true,
  );
}

export function saveAddresses(addresses) {
  return callApi('addresses/multiple', {method: 'post'}, {addresses}, true);
}

export function removeAddress(addressId) {
  return callApi(`addresses/${addressId}`, {method: 'delete'}, {}, true);
}

export function refreshAddresses(nft_ids = [], nfts = []) {
  return callApi('addresses/refresh', {method: 'post'}, {nft_ids, nfts}, true);
}

export function getCollections() {
  return callApi('collections', {}, {}, true);
}

export function getNFTsAuth() {
  return callApi('nfts', {}, {}, true);
}

export function getNFTs(addresses, nft_ids, nfts = []) {
  return callApi('nfts', {method: 'post'}, {addresses, nft_ids, nfts});
}

export function editNFT(nftId, notes) {
  return callApi(`nfts/${nftId}`, {method: 'post'}, {notes}, true);
}

export function refreshNfts(assets) {
  return callApi(`nfts/refresh`, {method: 'post'}, {assets}, true)
}

export function addCollection(name, description, creation) {
  return callApi(
    'collections',
    {method: 'post'},
    {name, description, creation},
    true,
  );
}

export function editCollection(collectionId, name, description) {
  return callApi(
    `collections/${collectionId}`,
    {method: 'post'},
    {name, description},
    true,
  );
}

export function getNFTsWithCollections() {
  return callApi(`collections/nfts`, {}, {}, true);
}

export function addNFTstoCollections(collection_ids, nft_ids) {
  return callApi(
    `collections/nfts`,
    {method: 'post'},
    {collection_ids, nft_ids},
    true,
  );
}

export function removeNewCollection(collectionId) {
  return callApi(`collections/${collectionId}`, {method: 'delete'}, {}, true);
}

export function toggleNewCollection(collectionId) {
  return callApi(
    `collections/${collectionId}/toggle`,
    {method: 'post'},
    {},
    true,
  );
}

export function swapCollections(collectionId, collectionId2) {
  return callApi(
    `collections/swap/${collectionId}/${collectionId2}`,
    {method: 'post'},
    {},
    true,
  );
}

export function swapNFTs(collectionId, nftId, nftId2) {
  return callApi(
    `collections/${collectionId}/swap_nft/${nftId}/${nftId2}`,
    {method: 'post'},
    {},
    true,
  );
}

export function hideNFTs(nft_ids) {
  return callApi(`nfts/hide`, {method: 'post'}, {nft_ids}, true);
}

export function showNFTs(nft_ids) {
  return callApi(`nfts/show`, {method: 'post'}, {nft_ids}, true);
}

export function toggleNFT(nftId) {
  return callApi(`nfts/${nftId}/toggle`, {method: 'post'}, {}, true);
}

export function getTopshotAsset(tokenId) {
  return callApi(`topshot/moment/${tokenId}`);
}

export function addNFTs(nfts) {
  return callApi('nfts/add', {method: 'post'}, {nfts}, true);
}

export function removeNFT(nftId) {
  return callApi(`nfts/${nftId}`, {method: 'delete'}, {}, true);
}
