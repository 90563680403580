import React from 'react';
import Modal from 'react-modal';

import {addClassIfTrue} from '../../services/utils';

import './styles.scss';

const CustomModal = ({
  buttons,
  children,
  onRequestClose,
  scrollable,
  title,
  titleButton,
}) => {
  React.useEffect(() => {
    if (document) {
      document.documentElement.style.overflow = 'hidden';
    }

    return () => {
      if (
        document &&
        !document.querySelector('.LayoutHeaderMenu__list--isOpen')
      ) {
        document.documentElement.style.overflow = 'unset';
      }
    };
  }, []);

  return (
    <Modal
      className={addClassIfTrue(
        'CustomModal',
        'CustomModal--scrollable',
        scrollable,
      )}
      isOpen
      onRequestClose={onRequestClose}>
      <div className="CustomModal__header">
        <div className="CustomModal__title">{title}</div>
        {titleButton && (
          <div className="CustomModal__titleButton">{titleButton}</div>
        )}
      </div>
      <div className="CustomModal__body">{children}</div>
      <div className="CustomModal__buttons">{buttons}</div>
    </Modal>
  );
};

export default CustomModal;
