import * as fcl from '@onflow/fcl';

import {addTopshotAddress, addAssetsForCollection} from '../redux/crypto';
import {getTopshotAsset} from '../services/wlt';

const SERVER_URL = process.env.REACT_APP_API_PREFIX;
const headers = {
  'Content-Type': 'application/json',
  'Wlt-Client-ID': process.env.REACT_APP_CLIENT_ID,
};

export const getTopshotMediaUrl = (nft, video = false) => {
  if (video) {
    return nft.image_url + 'Animated_1080_1920_Black.mp4';
  } else {
    return nft.image_url + 'Hero_2880_2880_Black.jpg?width=582';
  }
};

export const getAsset = (tokenId, dispatch) => {
  getTopshotAsset(tokenId).then((data) => {
    if (data.asset) {
      dispatch(addAssetsForCollection([data.asset]));
    }
  });
};

export function handleTopshotAddress(username, onSuccess, onFailure) {
  return (dispatch) => {
    const apiUrl = `${SERVER_URL}/topshot/exists/${username}`;

    fetch(apiUrl, {headers})
      .then((res) => res.json())
      .then((address) => {
        // flow address saved as "USERNAME:ADDRESS"
        if (address.toLowerCase().indexOf('invalid') > -1) {
          console.log('username does not exist');
          if (onFailure) {
            onFailure(address);
          }
        } else {
          getTopshotMoments(address).then((resp) => {
            let momentIDs = resp.momentIDs;
            let saleMomentIDs = resp.saleMomentIDs;
            dispatch(
              addTopshotAddress(
                `${username}:${address}`,
                username,
                [...momentIDs, ...saleMomentIDs],
                onSuccess,
              ),
            );
          });
        }
      });
  };
}

export const getTopshotMoments = async (address) => {
  // javascript method to get all moments IDs for a particular address
  // we use this to get the moment IDs and pass them to the server, which then gets all the info related to each moment
  // this should probably be handled on the server side, but I'm not sure how to formulate this query there atm, so this is easier
  const resp = await fcl.send([
    fcl.script`
  import TopShot from 0x0b2a3299cc857e29
  import Market from 0xc1e4f4f4c4257510
  pub struct TopshotAccount {
    pub var momentIDs: [UInt64]
    pub var saleMomentIDs: [UInt64]
    init(momentIDs: [UInt64], saleMomentIDs: [UInt64]) {
      self.momentIDs = momentIDs
      self.saleMomentIDs = saleMomentIDs
    }
  }
  pub fun main(): TopshotAccount {
  let acct = getAccount(0x${address})
  let collectionRef = acct.getCapability(/public/MomentCollection)!
                .borrow<&{TopShot.MomentCollectionPublic}>()!
  let momentIDs = collectionRef.getIDs()
  var saleMomentIDs: [UInt64] = []
  let salePublic = acct.getCapability(/public/topshotSaleCollection)
  if salePublic!.check<&{Market.SalePublic}>(){
    let saleCollectionRef = salePublic!.borrow<&{Market.SalePublic}>() ?? panic("Could not borrow capability from public collection")
    saleMomentIDs = saleCollectionRef.getIDs()
  }
  return TopshotAccount(momentIDs: momentIDs, saleMomentIDs: saleMomentIDs)
}  `,
  ]);
  return fcl.decode(resp);
};

const exportedObject = {handleTopshotAddress, getAsset, getTopshotMoments};
export default exportedObject;
