import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {updateUser} from '../../../redux/user';

import CustomModal from '../../../components/CustomModal';
import ModalButtons from '../../../components/ModalButtons';
import FormInput from '../../../components/Form/FormInput';
import FormTextarea from '../../../components/Form/FormTextarea';

const AdminPageEditProfileModal = ({closeModal}) => {
  const user = useSelector((state) => state.User.user);

  const [buttonState, setButtonState] = React.useState('default');
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [passwordOld, setPasswordOld] = React.useState('');
  const [bio, setBio] = React.useState(user.bio);
  const [displayName, setDisplayName] = React.useState(user.display_name);

  const [validationMsg, setValidationMsg] = React.useState('');

  const dispatch = useDispatch();

  const onFailure = (msg) => {
    setButtonState('default');
    setValidationMsg(msg);
  };

  const handleSubmit = React.useCallback(() => {
    if (password.length > 0 && passwordConfirm.length > 0) {
      if (password.length < 8 || passwordConfirm.length < 8) {
        setValidationMsg('New passwords must be at least 8 characters');
        return;
      }
      if (password !== passwordConfirm) {
        setValidationMsg("New passwords don't match");
        return;
      }
      if (passwordOld.length === 0) {
        setValidationMsg('Please enter your current password');
        return;
      }
      dispatch(
        updateUser(
          user.username,
          displayName,
          passwordOld,
          password,
          passwordConfirm,
          bio,
          closeModal,
          onFailure,
        ),
      );
    } else if (bio !== user.bio || displayName !== user.display_name) {
      dispatch(
        updateUser(user.username, displayName, '', '', '', bio, closeModal, onFailure),
      );
      return;
    }
  }, [
    bio,
    closeModal,
    displayName,
    dispatch,
    password,
    passwordConfirm,
    passwordOld,
    user.bio,
    user.username,
  ]);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <CustomModal title="Edit profile" onRequestClose={closeModal}>
      <form onSubmit={onSubmit}>
        <FormInput label="Username" disabled value={user.username} />
        <FormInput
          label="Display Name"
          value={displayName}
          onChange={setDisplayName}
        />
        <FormTextarea
          label="Profile Bio"
          maxLength={140}
          value={bio}
          onChange={setBio}
        />
        <FormInput
          label="Current Password"
          type="password"
          value={passwordOld}
          onChange={setPasswordOld}
        />
        <FormInput
          label="New Password"
          note="Minimum 8 characters"
          type="password"
          value={password}
          onChange={setPassword}
        />
        <FormInput
          label="Confirm New Password"
          type="password"
          value={passwordConfirm}
          onChange={setPasswordConfirm}
        />
        <ModalButtons
          buttonText="save profile"
          buttonState={buttonState}
          buttonPendingText="saving..."
          closeModal={closeModal}
        />
        {validationMsg.length > 0 && (
          <div className="form__validation">{validationMsg}</div>
        )}
      </form>
    </CustomModal>
  );
};

export default AdminPageEditProfileModal;
