import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import createReducer from './redux';

import {loadState, saveState} from './localstorage';

export default function configureStore() {
  const persistedState = loadState();
  const store = createStore(
    createReducer(),
    persistedState,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f,
    ),
  );
  store.subscribe(() => {
    saveState(store.getState());
  });
  return store;
}
