import React from 'react';

import Switch from '@material-ui/core/Switch';

import './styles.scss';

const FormSwitch = ({checked, onChange}) => (
  <Switch
    className="FormSwitch"
    checked={checked}
    onChange={onChange}
    color="default"
  />
);

export default FormSwitch;
