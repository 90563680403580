import React from 'react';
import moment from 'moment';

import NFTPageItem from './NFTPageItem';

import { getPlainTextMedia } from '../../services/NFTUtils'

const NFTPageOrdinals = ({asset, nft}) => {
  const { inscriptionId, mediaUrl, mimeType, externalUrl, traits } = asset
  const isVideo = mimeType.includes('video')

  const [plainText, setPlainText] = React.useState(null)

  React.useEffect(() => {
    if (mimeType === 'text/plain') {
      getPlainTextMedia(mediaUrl, setPlainText)
    }
  }, [])

  const mediaDiv = React.useMemo(() => {
    if (isVideo) {
      return (
        <video controls="controls" preload="metadata" loop>
          <source src={mediaUrl} type="video/mp4" />
        </video>
      )
    } else if (plainText) {
      return (
        <span>{plainText}</span>
      )
    }
    return (<img style={{'image-rendering': 'pixelated'}} alt={mediaUrl} src={mediaUrl} />)
  }, [plainText])

  let linkDiv = (
    <a
      target="_blank"
      rel="noreferrer"
      href={externalUrl}>
      View on Ordinals.com
    </a>
  );

  return (
    <NFTPageItem
      allowFullscreen={!(isVideo || plainText)}
      className="xcp"
      collection={asset.category}
      description={asset.description}
      imageUrl={mediaUrl}
      mediaDiv={mediaDiv}
      mintDate={'Minted on Bitcoin'}
      mintDateText={null}
      mintDateDarkModeIcon="/ordinal-dark.svg"
      mintDateLightModeIcon="/ordinal-light.svg"
      name={asset.name}
      nft={nft}
      linkDiv={linkDiv}
      traits={traits}
      simpleDescription
    />
  );
};

export default NFTPageOrdinals;
