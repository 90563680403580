import React from 'react';

import {getMediaSources, getPlainTextMedia} from '../../services/NFTUtils';

import './styles.scss';

const NFTMedia = ({nft, showControls, size, square}) => {
  const [image_url, video_url] = getMediaSources(nft);

  // specifically for ordinals. can be plain text media
  const [plainText, setPlainText] = React.useState(null)
  React.useEffect(() => {
    if (nft.media_type && nft.media_type === 'text/plain') {
      getPlainTextMedia(nft.media_url, setPlainText)
    }
  })

  const style = React.useMemo(
    () => ({
      width: size,
      height: square ? size : undefined,
    }),
    [size, square],
  );

  const mediaDiv = React.useMemo(() => {
    if (video_url) {
      return (
        <>
          <div className="NFTMedia__cover" />
          <video
            controls={showControls ? 'controls' : undefined}
            preload="metadata">
            <source src={`${video_url}#t=0.01`} type="video/mp4" />
          </video>
        </>
      )
    } else if (plainText) {
      return (
        <span>{plainText}</span>
      )
    } else if (image_url) {
      return (<img style={{minWidth: size, 'image-rendering': 'pixelated'}} src={image_url} alt="NFT" />)
    }
    return null
  }, [video_url, image_url, plainText])

  return <div className="NFTMedia" style={style}>{mediaDiv}</div>
};

export default NFTMedia;
