import React from 'react';
import Modal from 'react-modal';
import {useSelector} from 'react-redux';
import {useLocation, useHistory, Link} from 'react-router-dom';

import {isRizzle, isBitBasel, isGGallery} from '../../../services/utils';

import ThemeAwareIcon from '../../ThemeAwareIcon';

import LayoutHeaderForgotPasswordModal from './LayoutHeaderForgotPasswordModal';
import LayoutHeaderLoginModal from './LayoutHeaderLoginModal';
import LayoutHeaderMenu from './LayoutHeaderMenu';
import LayoutHeaderRegister from './LayoutHeaderRegisterModal';
import LayoutHeaderResetPasswordModal from './LayoutHeaderResetPasswordModal';
import LayoutHeaderThemeSwitch from './LayoutHeaderThemeSwitch';

import './styles.scss';

Modal.setAppElement('#root');

const LayoutHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const [openModal, setOpenModal] = React.useState();

  const user = useSelector((state) => state.User.user);

  const closeModal = React.useCallback(() => {
    openModal === 'reset-password' && history.push('/');
    setOpenModal(undefined);
  }, [history, openModal]);

  const openForgotPasswordModal = React.useCallback(
    () => setOpenModal('forgot-password'),
    [],
  );

  const openLoginModal = React.useCallback(() => setOpenModal('login'), []);

  const openRegisterModal = React.useCallback(
    () => setOpenModal('register'),
    [],
  );

  React.useEffect(() => {
    if (location.pathname === '/reset_password') {
      setOpenModal('reset-password');
    }
  }, [location.pathname]);

  const isPurchase = window.location.pathname === '/purchase'
  const headerLeftLink = isPurchase ? "/purchase" : isRizzle() ? "/rizzle" : user ? "/app" : "/"

  const headerIconDiv =
  isRizzle() ?
  (
    <span className='LayoutHeader__left__rizzle'>
      <ThemeAwareIcon
        lightModeIconSrc="/rizzle.png"
        darkModeIconSrc="/rizzle.png"
        alt="Rizzle Header icon"
      />
    </span>
  )
  : isBitBasel() ?
  (
    <span className='LayoutHeader__left__bitbasel'>
      <ThemeAwareIcon
        lightModeIconSrc="/bitbasel.svg"
        darkModeIconSrc="/bitbasel.svg"
        alt="BitBasel Header icon"
      />
    </span>
  )
  : isGGallery() ?
  (
    <span className='LayoutHeader__left__ggallery'>
      <ThemeAwareIcon
        lightModeIconSrc="/g-gallery-light-mode.png"
        darkModeIconSrc="/g-gallery-dark-mode.png"
        alt="G Gallery Header icon"
      />
    </span>
  )
  :
  (
    <ThemeAwareIcon
      lightModeIconSrc="/headerIcon-light.svg"
      darkModeIconSrc="/headerIcon-dark.svg"
      alt="Header icon"
    />
  )

  const headerLeftDiv = isRizzle() ?
    (<span>
       <ThemeAwareIcon
         lightModeIconSrc="/rizzle-light.svg"
         darkModeIconSrc="/rizzle-dark.svg"
         alt="Rizzle icon"
       />
    </span>)
    : isBitBasel() ?
    (<span>
       <ThemeAwareIcon
         lightModeIconSrc="/bitbasel-light.svg"
         darkModeIconSrc="/bitbasel-dark.svg"
         alt="BitBasel icon"
       />
    </span>)
    :
    (<span></span>)

  return (
    <div className="LayoutHeader">
      <Link to={headerLeftLink} className="LayoutHeader__left">
        {headerIconDiv}
        {headerLeftDiv}
      </Link>
      <LayoutHeaderThemeSwitch />
      <LayoutHeaderMenu
        openLoginModal={openLoginModal}
        openRegisterModal={openRegisterModal}
      />
      {openModal === 'login' && (
        <LayoutHeaderLoginModal
          closeModal={closeModal}
          openForgotPasswordModal={openForgotPasswordModal}
        />
      )}
      {openModal === 'register' && (
        <LayoutHeaderRegister closeModal={closeModal} />
      )}
      {openModal === 'forgot-password' && (
        <LayoutHeaderForgotPasswordModal closeModal={closeModal} />
      )}
      {openModal === 'reset-password' && (
        <LayoutHeaderResetPasswordModal closeModal={closeModal} />
      )}
    </div>
  );
};

export default LayoutHeader;
