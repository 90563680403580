import update from 'immutability-helper';
import moment from 'moment';

import {getAssetsByTokenIds} from '../services/opensea'
import {handleSolanaAddress} from '../services/solana';
import {getTopshotMoments} from '../services/topshot';
import * as api from '../services/wlt';

import {USER_LOGOUT} from './user';

/**
 * Constants
 */

const SAVE_ADDRESSES_GUEST = 'SAVE_ADDRESSES_GUEST';
const REMOVE_GUEST = 'REMOVE_GUEST';
const STORE_NFTS_GUEST = 'STORE_NFTS_GUEST';
const STORE_COLLECTIONS_GUEST = 'STORE_COLLECTIONS_GUEST';
const SET_LAST_RETRIEVED_GUEST = 'SET_LAST_RETRIEVED_GUEST';

const SAVE_ADDRESSES = 'SAVE_ADDRESSES';
const SAVE_ADDRESS = 'SAVE_ADDRESS';
const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
const ADD_COLLECTIONS = 'ADD_COLLECTIONS';
const ADD_ASSETS = 'ADD_ASSETS';
const ADD_EVENTS = 'ADD_EVENTS';
const CLEAR_ASSETS = 'CLEAR_ASSETS';

const SET_LAST_RETRIEVED = 'SET_LAST_RETRIEVED';
const STORE_NFTS = 'STORE_NFTS';
const TOGGLE_NFT = 'TOGGLE_NFT';
const STORE_COLLECTIONS = 'STORE_COLLECTIONS';
const ADD_NEW_COLLECTION = 'ADD_NEW_COLLECTION';
const REMOVE_NEW_COLLECTION = 'REMOVE_NEW_COLLECTION';
const TOGGLE_NEW_COLLECTION = 'TOGGLE_NEW_COLLECTION';
const SWAP_COLLECTIONS = 'SWAP_COLLECTIONS';
const SWAP_NFTS = 'SWAP_NFTS';
const REMOVE_NFT = 'REMOVE_NFT';

/**
 * Actions
 */

function _handleCollectionsAndNFTs(data, dispatch, guest = false) {
  if (data.collections) {
    dispatch({
      type: guest ? STORE_COLLECTIONS_GUEST : STORE_COLLECTIONS,
      payload: data.collections,
    });
  }

  if (data.nfts) {
    let categorizedNFTs = [],
      uncategorizedNFTs = [];
    data.collection_nfts &&
      data.collection_nfts.forEach((cnft) => {
        categorizedNFTs.push({id: cnft.nft_id});
      });

    if (categorizedNFTs.length > 0) {
      uncategorizedNFTs = [].concat(
        data.nfts.filter((obj1) =>
          categorizedNFTs.every((obj2) => obj1.id !== obj2.id),
        ),
        categorizedNFTs.filter((obj2) =>
          data.nfts.every((obj1) => obj2.id !== obj1.id),
        ),
      );
    } else {
      uncategorizedNFTs = data.nfts;
    }

    uncategorizedNFTs.sort((a, b) => {
      if (a.contract_address > b.contract_address) return 1;
      if (b.contract_address > a.contract_address) return -1;
      if (parseInt(a.token_id) > parseInt(b.token_id)) return 1;
      if (parseInt(b.token_id) > parseInt(a.token_id)) return -1;
      return 0;
    });

    const payload = {nfts: data.nfts, uncategorizedNFTs};
    if (data.collection_nfts) {
      payload.collectionNFTs = data.collection_nfts;
    }
    if (data.addresses) {
      payload.addresses = data.addresses;
    }
    dispatch({
      type: guest ? STORE_NFTS_GUEST : STORE_NFTS,
      payload,
    });
  }
}

export function getAddresses(username, onSuccess) {
  return (dispatch, getState) => {
    const lastRetrieved = getState().Crypto.guest.lastRetrieved;
    const addresses = getState().Crypto.guest.addresses;
    if (
      addresses && addresses.length > 0 &&
      moment().diff(moment(lastRetrieved), 'seconds') < 60
    ) {
      // don't get addresses if we did less than a minute ago
      // return;
    }
    dispatch(setLastRetrieved(username));
    api.getAddresses(username).then((data) => {
      dispatch({
        type: SAVE_ADDRESSES_GUEST,
        payload: {addresses: data.addresses, user: data.user},
      });
      _handleCollectionsAndNFTs(data, dispatch, true);
      if (onSuccess) {
        onSuccess();
      }
    });
  };
}

export function removeGuest() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_GUEST,
    });
  };
}

export function getNFTsWithCollections(onSuccess) {
  return (dispatch, getState) => {
    const {uncategorizedNFTs} = getState().Crypto;
    api.getNFTsWithCollections().then((data) => {
      dispatch({
        type: STORE_NFTS,
        payload: {nfts: data.nfts, uncategorizedNFTs},
      });
      if (onSuccess) {
        onSuccess();
      }
    });
  };
}

export function getNFTsByAddresses(addresses, onSuccess) {
  // this function is used for a logged OUT user
  // addresses need to be formatted this way:
  // CHAIN:ADDRESS
  // before being sent to the server
  return (dispatch, getState) => {
    let nftIds = [];
    let foundFlowAddress = false;
    addresses.forEach((a) => {
      if (a.chain === 'flow') {
        foundFlowAddress = true;
        getTopshotMoments(a.address.split(':')[1]).then((resp) => {
          nftIds = [...resp.momentIDs, ...resp.saleMomentIDs];
          api.getNFTs(addresses, nftIds).then((data) => {
            _handleCollectionsAndNFTs(data, dispatch);
            if (onSuccess) {
              onSuccess();
            }
          });
        });
      }
    });
    if (!foundFlowAddress) {
      // const currentNFTs = getState().Crypto.nfts;
      const solNFTs = [] // currentNFTs.filter(nft => nft.chain === 'sol')
      api.getNFTs(addresses, nftIds, solNFTs).then((data) => {
        _handleCollectionsAndNFTs(data, dispatch);
      });
      if (onSuccess) {
        onSuccess();
      }
    }
  };
}

export function getAddressesAuth() {
  return (dispatch, getState) => {
    const lastRetrieved = getState().Crypto.lastRetrieved;
    const addresses = getState().Crypto.addresses;
    if (addresses > 0 && moment().diff(moment(lastRetrieved), 'seconds') < 60) {
      // don't get addresses if we did less than a minute ago
      console.log('addresses retrieved < 1 min ago');
      return;
    }
    dispatch(setLastRetrieved());
    api.getAddressesAuth().then((data) => {
      dispatch({
        type: SAVE_ADDRESSES,
        payload: data.addresses,
      });
      _handleCollectionsAndNFTs(data, dispatch);
    });
  };
}

export function refreshNfts(nfts) {
  return (dispatch, getState) => {
    // contract address to nfts
    // { CONTRACT_ADDRESS: [nfts] }
    const nftMapping = {}
    nfts.forEach(nft => {
      const { contract_address } = nft
      if (contract_address !== 'btc') {
        if (!nftMapping[contract_address]) {
          nftMapping[contract_address] = []
        }
        nftMapping[contract_address].push(nft.chain_token_id)
      }
    })
    Object.keys(nftMapping).forEach(contractAddress => {
      const _nfts = nftMapping[contractAddress]
      getAssetsByTokenIds(contractAddress, _nfts, (assets) => {
        api.refreshNfts(assets)
      })
    })
  }
}

export function refreshAddresses(onSuccess) {
  return (dispatch, getState) => {
    const addresses = getState().Crypto.addresses;
    const currentNFTs = getState().Crypto.nfts;
    const solNFTs = [] // currentNFTs.filter(nft => nft.chain === 'sol')
    const individualNFTs = currentNFTs.filter(nft => !nft.wallet_address_id)

    dispatch(refreshNfts(individualNFTs))

    let flowAddress = false;
    addresses.forEach((a) => {
      if (a.chain === 'flow') {
        flowAddress = a.address;
      }
    });
    if (flowAddress) {
      getTopshotMoments(flowAddress.split(':')[1]).then((resp) => {
        const momentIDs = [...resp.momentIDs, ...resp.saleMomentIDs];
        api.refreshAddresses(momentIDs, [...solNFTs, ...individualNFTs]).then((data) => {
          _handleCollectionsAndNFTs(data, dispatch);
          if (onSuccess) {
            onSuccess();
          }
          if (data.error) {
            console.error(data.error);
          }
        });
      });
    } else {
      api.refreshAddresses([], [...solNFTs, ...individualNFTs]).then((data) => {
        _handleCollectionsAndNFTs(data, dispatch);
        if (onSuccess) {
          onSuccess(0);
        }
        if (data.error) {
          console.error(data.error);
        }
      });
    }
  };
}

export function addAddress(address, alias, coinType, onSuccess, onFailure) {
  return (dispatch, getState) => {
    if (getState().User.isLoggedIn) {
      api.addAddress(address, alias, coinType).then((data) => {
        if (data.error) {
          console.error(data.error);
          if (onFailure) {
            onFailure(data.error);
          }
        } else {
          dispatch({
            type: SAVE_ADDRESSES,
            payload: data.addresses,
          });
          _handleCollectionsAndNFTs(data, dispatch);
          if (onSuccess) {
            onSuccess();
          }
        }
      });
    } else {
      dispatch({
        type: SAVE_ADDRESS,
        payload: {chain: coinType, address, id: address, alias},
      });
      dispatch(getNFTsByAddresses(getState().Crypto.addresses));
      if (onSuccess) {
        onSuccess();
      }
    }
  };
}

export function addSolanaAddress(address, _nfts, onSuccess, onFailure) {
  return (dispatch, getState) => {
    let {uncategorizedNFTs, nfts} = getState().Crypto;
    if (getState().User.isLoggedIn) {
      dispatch(addNFTs(_nfts, onSuccess, onFailure))
    } else {
      dispatch({
        type: SAVE_ADDRESS,
        payload: {chain: 'sol', address, id: address},
      });
      // do not overwrite nfts that already exist in redux store
      const _ = require('lodash');
      nfts = nfts.concat(_nfts)
      nfts = _.uniqBy(nfts, 'id');
      uncategorizedNFTs = uncategorizedNFTs.concat(nfts);
      uncategorizedNFTs = _.uniqBy(uncategorizedNFTs, 'id');
      dispatch({
        type: STORE_NFTS,
        payload: {nfts, uncategorizedNFTs},
      });
      if (onSuccess) onSuccess();
    }
  }
}

export function addTopshotAddress(address, alias, momentIDs, onSuccess) {
  // topshot address needs to be sent with NFT IDs as there's no good way (right now)
  // to get momentIDs from the server API. so we send address + momentIDs to server
  return (dispatch, getState) => {
    if (getState().User.isLoggedIn) {
      api.addTopshotAddress(address, alias, momentIDs).then((data) => {
        dispatch({
          type: SAVE_ADDRESSES,
          payload: data.addresses,
        });
        _handleCollectionsAndNFTs(data, dispatch);
        if (onSuccess) {
          onSuccess();
        }
      });
    } else {
      dispatch({
        type: SAVE_ADDRESS,
        payload: {chain: 'flow', address, alias, id: address},
      });
      dispatch(getNFTsByAddresses(getState().Crypto.addresses));
      if (onSuccess) {
        onSuccess();
      }
    }
  };
}

export function removeAddress(addressId, onSuccess) {
  return (dispatch, getState) => {
    const nfts = getState().Crypto.nfts;

    dispatch({
      type: REMOVE_ADDRESS,
      payload: addressId,
    });

    if (getState().User.isLoggedIn) {
      api.removeAddress(addressId).then((data) => {
        _handleCollectionsAndNFTs(data, dispatch);
      });
    } else {
      const filteredNFTs = nfts.filter(
        (n) => n.wallet_address_id !== addressId,
      );
      _handleCollectionsAndNFTs(
        {nfts: filteredNFTs, categorized_nfts: []},
        dispatch,
      );
    }

    if (onSuccess) {
      onSuccess();
    }
  };
}

export function addCollectionsForAddress(address, collections, merge = false) {
  return (dispatch, getState) => {
    let newState = collections;
    if (getState().Crypto.collections.hasOwnProperty(address) && merge) {
      newState = [...getState().Crypto.collections[address], ...collections];
      console.log('unfiltered', newState);
      // newState = newState.filter((obj, pos, arr) => {
      //     return arr.map(mapObj => mapObj['id']).indexOf(obj['id']) !== pos;
      // });
      var _ = require('lodash');
      newState = _.uniqBy(newState, 'id');
      console.log('filtered', newState);
    }
    dispatch({
      type: ADD_COLLECTIONS,
      payload: {[address]: newState},
    });
  };
}

export function addNewCollection(name, description, creation, onSuccess) {
  return (dispatch, getState) => {
    const newCollections = getState().Crypto.newCollections;
    newCollections.push({name, description, creation, enabled: true});
    dispatch({
      type: STORE_COLLECTIONS,
      payload: newCollections,
    });
    if (onSuccess) {
      onSuccess();
    }

    api.addCollection(name, description, creation).then((data) => {
      dispatch({
        type: STORE_COLLECTIONS,
        payload: data.collections,
      });
    });
  };
}

export function editCollection(collectionId, name, description, onSuccess) {
  return (dispatch, getState) => {
    const newCollections = getState().Crypto.newCollections;
    const index = newCollections.findIndex((c) => c.id === collectionId);
    if (index > -1) {
      api.editCollection(collectionId, name, description);
      let collection = newCollections[index];
      collection.name = name;
      collection.description = description;
      newCollections[index] = collection;

      dispatch({
        type: STORE_COLLECTIONS,
        payload: newCollections,
      });
      if (onSuccess) {
        onSuccess();
      }
    }
  };
}

export function addNFTstoCollections(collectionIds, nftIds, onSuccess) {
  // add all NFTs to every collection
  return (dispatch) => {
    api.addNFTstoCollections(collectionIds, nftIds).then((data) => {
      _handleCollectionsAndNFTs(data, dispatch);
      if (onSuccess) {
        onSuccess();
      }
    });
  };
}

export function removeNewCollection(collectionId, index, onSuccess) {
  return (dispatch) => {
    api.removeNewCollection(collectionId);
    dispatch({
      type: REMOVE_NEW_COLLECTION,
      payload: index,
    });
    if (onSuccess) {
      onSuccess();
    }
  };
}

export function toggleNewCollection(collectionId, index) {
  return (dispatch) => {
    api.toggleNewCollection(collectionId);
    dispatch({
      type: TOGGLE_NEW_COLLECTION,
      payload: collectionId,
    });
  };
}

export function swapCollections(collectionId, collectionId2, onSuccess) {
  return (dispatch) => {
    dispatch({
      type: SWAP_COLLECTIONS,
      payload: {collectionId, collectionId2},
    });
    api.swapCollections(collectionId, collectionId2).then((data) => {
      // _handleCollectionsAndNFTs(data, dispatch)
      if (data.error) {
        console.log('error swapping collections!');
      } else if (onSuccess) {
        onSuccess();
      }
    });
  };
}

export function swapNFTs(collectionId, nftId, nftId2, onSuccess) {
  return (dispatch) => {
    dispatch({
      type: SWAP_NFTS,
      payload: {collectionId, nftId, nftId2},
    });
    api.swapNFTs(collectionId, nftId, nftId2).then((data) => {
      // _handleCollectionsAndNFTs(data, dispatch)
      if (data.error) {
        console.log('error swapping nfts!');
      } else if (onSuccess) {
        onSuccess();
      }
    });
  };
}

export function hideNFTs(nftIds) {
  return (dispatch, getState) => {
    const {uncategorizedNFTs} = getState().Crypto;
    api.hideNFTs(nftIds).then((data) => {
      const nfts = data.nfts;
      dispatch({
        type: STORE_NFTS,
        payload: {nfts, uncategorizedNFTs},
      });
    });
  };
}

export function showNFTs(nftIds) {
  return (dispatch, getState) => {
    const {uncategorizedNFTs} = getState().Crypto;

    api.showNFTs(nftIds).then((data) => {
      const nfts = data.nfts;
      dispatch({
        type: STORE_NFTS,
        payload: {nfts, uncategorizedNFTs},
      });
    });
  };
}

export function toggleNFT(nftId) {
  return (dispatch) => {
    api.toggleNFT(nftId);

    dispatch({
      type: TOGGLE_NFT,
      payload: nftId,
    });
  };
}

export function editNFT(nftId, notes, onSuccess) {
  return (dispatch, getState) => {
    const {nfts, uncategorizedNFTs} = getState().Crypto;

    if (nfts.some((nft) => nft.id === nftId)) {
      api.editNFT(nftId, notes);

      dispatch({
        type: STORE_NFTS,
        payload: {
          nfts: nfts.map((nft) =>
            nft.id === nftId
              ? {
                  ...nft,
                  notes,
                }
              : nft,
          ),
          uncategorizedNFTs,
        },
      });

      if (onSuccess) {
        setTimeout(() => onSuccess(), 200);
      }
    }
  };
}

export function addNFTs(nftsData, onSuccess, onFailure) {
  return (dispatch, getState) => {
    let {uncategorizedNFTs} = getState().Crypto;
    api.addNFTs(nftsData).then(data => {
      if (data.error) {
        console.error(data.error)
        if (onFailure) onFailure(data.error)
      } else {
        _handleCollectionsAndNFTs(data, dispatch)
        if (onSuccess) onSuccess();
      }
    })
  }
}

export function removeNFT(nftId, onSuccess) {
  return (dispatch, getState) => {
    const {nfts, uncategorizedNFTs} = getState().Crypto;

    dispatch({
      type: STORE_NFTS,
      payload: {
        nfts: nfts.filter(n => n.id !== nftId),
        uncategorizedNFTs
      }
    })
    api.removeNFT(nftId).then(data => {
      if (data.error) {

      } else {
        _handleCollectionsAndNFTs(data, dispatch)
        if (onSuccess) onSuccess();
      }
    })
  }
}

export function addAssetsForCollection(assets, onFailure) {
  return (dispatch) => {
    dispatch({
      type: ADD_ASSETS,
      payload: assets,
    });

    if (assets.length === 0 && onFailure) {
      onFailure();
    }
  };
}

export function addEventsForAsset(tokenId, events) {
  return (dispatch) => {
    dispatch({
      type: ADD_EVENTS,
      payload: {tokenId, events},
    });
  };
}

export function clearAssets() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ASSETS,
    });
  };
}

export function setLastRetrieved(guestUsername = false) {
  return (dispatch) => {
    const actionType = guestUsername
      ? SET_LAST_RETRIEVED_GUEST
      : SET_LAST_RETRIEVED;

    dispatch({
      type: actionType,
      payload: guestUsername,
    });
  };
}
/**
 * Reducer
 */

const defaultState = {
  addresses: [],
  newCollections: [],
  nfts: [],
  uncategorizedNFTs: [],
  collectionNFTs: [],
  lastRetrieved: null,
};
const initialState = {
  initialLoad: true,

  guest: {
    username: null,
    user: null,
    ...defaultState,
  },

  ...defaultState,

  // individual NFT page;
  currentAssets: [],
  currentAssetsEvents: [],
};

export default function cryptoReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LAST_RETRIEVED: {
      return {
        ...state,
        lastRetrieved: moment().format(),
      };
    }
    case SET_LAST_RETRIEVED_GUEST: {
      return {
        ...state,
        guest: {
          ...state.guest,
          lastRetrieved: moment().format(),
          username: action.payload,
        },
      };
    }
    case SAVE_ADDRESSES_GUEST: {
      return {
        ...state,
        initialLoad: false,
        guest: {
          ...state.guest,
          addresses: action.payload.addresses,
          user: action.payload.user,
        },
      };
    }
    case REMOVE_GUEST: {
      return {
        ...state,
        initialLoad: true,
        guest: {
          ...state.guest,
          // addresses: initialState.guest.addresses
          // TODO: need to figure out why initialState is getting modified
          // should be able to just use initialState to restore state, but it's not working
          username: null,
          addresses: [],
          newCollections: [],
          nfts: [],
          uncategorizedNFTs: [],
          collectionNFTs: [],
          lastRetrieved: null,
        },
      };
    }
    case STORE_COLLECTIONS_GUEST: {
      return {
        ...state,
        guest: {
          ...state.guest,
          newCollections: action.payload,
        },
      };
    }
    case STORE_NFTS_GUEST: {
      const uncategorizedNFTs =
        action.payload.uncategorizedNFTs || state.guest.uncategorizedNFTs;
      return {
        ...state,
        guest: {
          ...state.guest,
          nfts: action.payload.nfts,
          uncategorizedNFTs,
          collectionNFTs: action.payload.collectionNFTs,
        },
      };
    }

    case SAVE_ADDRESSES: {
      return {
        ...state,
        initialLoad: false,
        addresses: action.payload,
      };
    }
    case SAVE_ADDRESS: {
      let addresses = state.addresses;
      const address = addresses.find(a => a.id === action.payload.id)
      if (!address) {
        addresses.push(action.payload);
      }
      return update(state, {addresses: {$set: addresses}});
    }
    case REMOVE_ADDRESS: {
      const addressId = action.payload;
      console.log(`removing address w/ ID: ${addressId}`);
      const addresses = state.addresses.filter((a) => a.id !== addressId);
      return update(state, {addresses: {$set: addresses}});
    }
    case ADD_COLLECTIONS:
      return update(state, {
        collections: {
          $merge: action.payload,
        },
      });

    case ADD_NEW_COLLECTION:
      let collections = state.newCollections;
      collections.push(action.payload);
      return update(state, {
        newCollections: {
          $set: collections,
        },
      });
    case REMOVE_NEW_COLLECTION: {
      let collections = state.newCollections;
      collections = [
        ...collections.slice(0, action.payload),
        ...collections.slice(action.payload + 1),
      ];
      return update(state, {
        newCollections: {
          $set: collections,
        },
      });
    }
    case TOGGLE_NEW_COLLECTION: {
      const index = state.newCollections.findIndex(
        (c) => c.id === action.payload,
      );
      if (index === -1) {
        return {
          ...state,
        };
      }
      let collection = state.newCollections[index];
      collection.enabled = !collection.enabled;
      return {
        ...state,
        newCollections: [
          ...state.newCollections.slice(0, index),
          collection,
          ...state.newCollections.slice(index + 1),
        ],
      };
    }

    case SWAP_COLLECTIONS: {
      const {collectionId, collectionId2} = action.payload;
      const {newCollections} = state;
      const collectionIndex = newCollections.findIndex(
        (c) => c.id === collectionId,
      );
      const collectionIndex2 = newCollections.findIndex(
        (c) => c.id === collectionId2,
      );

      if (collectionIndex > -1 && collectionIndex2 > -1) {
        const collectionOrder = newCollections[collectionIndex].order;
        const collectionOrder2 = newCollections[collectionIndex2].order;

        newCollections[collectionIndex].order = collectionOrder2;
        newCollections[collectionIndex2].order = collectionOrder;

        newCollections.sort((a, b) => {
          if (a.order > b.order) return 1;
          if (b.order > a.order) return -1;
          return 0;
        });

        return {
          ...state,
          newCollections: [...newCollections],
        };
      }
      return {...state};
    }

    case SWAP_NFTS: {
      const {collectionId, nftId, nftId2} = action.payload;
      const {collectionNFTs} = state;
      const collectionNFTIndex = collectionNFTs.findIndex(
        (cnft) => cnft.nft_id === nftId && cnft.collection_id === collectionId,
      );
      const collectionNFTIndex2 = collectionNFTs.findIndex(
        (cnft) => cnft.nft_id === nftId2 && cnft.collection_id === collectionId,
      );

      if (collectionNFTIndex > -1 && collectionNFTIndex2 > -1) {
        const collectionOrder = collectionNFTs[collectionNFTIndex].order;
        const collectionOrder2 = collectionNFTs[collectionNFTIndex2].order;

        collectionNFTs[collectionNFTIndex].order = collectionOrder2;
        collectionNFTs[collectionNFTIndex2].order = collectionOrder;

        const sorted = collectionNFTs.sort((a, b) => {
          if (a.order > b.order) return 1;
          if (b.order > a.order) return -1;
          return 0;
        });

        return {
          ...state,
          collectionNFTs: [...sorted],
        };
      }
      return {...state};
    }

    case TOGGLE_NFT: {
      const nftId = action.payload;
      const index = state.nfts.findIndex((n) => n.id === nftId);

      if (index === -1) {
        return {
          ...state,
        };
      }

      let nft = state.nfts[index];
      nft.enabled = !nft.enabled;

      const newCollections = state.newCollections;

      return {
        ...state,
        newCollections,
        nfts: [
          ...state.nfts.slice(0, index),
          nft,
          ...state.nfts.slice(index + 1),
        ],
      };
    }
    case STORE_COLLECTIONS: {
      return {
        ...state,
        newCollections: action.payload,
      };
    }
    case STORE_NFTS: {
      const uncategorizedNFTs =
        action.payload.uncategorizedNFTs || state.uncategorizedNFTs;
      const collectionNFTs =
        action.payload.collectionNFTs || state.collectionNFTs;
      const addresses = action.payload.addresses || state.addresses;
      return {
        ...state,
        nfts: action.payload.nfts,
        uncategorizedNFTs,
        collectionNFTs,
        addresses,
      };
    }

    case ADD_ASSETS:
      return update(state, {
        $merge: {
          currentAssets: action.payload,
        },
      });
    case ADD_EVENTS:
      const {events} = action.payload;
      return update(state, {currentAssetsEvents: {$set: events}});
    case CLEAR_ASSETS:
      return update(state, {
        $merge: {
          currentAssets: [],
          currentAssetsEvents: [],
        },
      });
    case USER_LOGOUT:
      // TODO: need to figure out why initialState is getting modified
      // should be able to just use initialState to restore state, but it's not working
      return {
        initialLoad: true,
        addresses: [],
        guest: {
          addresses: [],
          newCollections: [],
          nfts: [],
          uncategorizedNFTs: [],
          collectionNFTs: [],
          lastRetrieved: null,
          user: null,
          username: null,
        },
        collections: {},
        currentAssets: [],
        currentAssetsEvents: [],

        newCollections: [],
        nfts: [],
        uncategorizedNFTs: [],
        collectionNFTs: [],
        lastRetrieved: null,
      };
    default:
      return state;
  }
}
