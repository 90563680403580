import React from 'react';
import moment from 'moment';

import {VIDEO_FORMATS} from '../../services/bettercalldev';

import {
  HIC_ET_NUNC_CONTRACT,
  KALAMINT_CONTRACT,
  TEZZARDS_CONTRACT,
} from '../../consts/Contract';

import NFTPageItem from './NFTPageItem';

import { getIpfsUrl } from '../../services/utils'

const NFTPageHicetnunc = ({asset, nft}) => {
  let image_url, video_url, svg_url, audio_url;

  const { imageUrl, videoUrl, marketplaceUrl } = asset || {}
  // const { mimeType, uri } = (formats && formats[0]) || (asset.token_info && { mimeType: asset.token_info.mimeType, uri: asset.artifact_uri}) || {};
  // formats.forEach(fmt => {
  //   const { mimeType, uri } = fmt
  //   const formattedUri = getIpfsUrl(uri)
  //   if (!video_url && mimeType && mimeType.toLowerCase() in VIDEO_FORMATS) {
  //     video_url = formattedUri
  //   }
  //   if (!svg_url) {
  //     if (mimeType === 'image/svg+xml') {
  //       svg_url = formattedUri
  //     }
  //     if (mimeType === 'application/x-directory') {
  //       svg_url = formattedUri
  //     }
  //   }
  //   if (!audio_url && mimeType === 'audio/mpeg') {
  //     audio_url = formattedUri
  //   }
  //   if (!image_url && mimeType.includes('image')) {
  //     image_url = formattedUri
  //   }
  // })

  // if (asset.display_uri && !image_url) {
  //   image_url = getIpfsUrl(asset.display_uri)
  // }
  image_url = imageUrl && getIpfsUrl(imageUrl)
  video_url = videoUrl && getIpfsUrl(videoUrl)

  let mediaDiv = <img alt={image_url} src={image_url} />;

  if (video_url) {
    mediaDiv = (
      <video controls="controls" preload="metadata" loop>
        <source src={video_url} />
      </video>
    );
  } else if (svg_url) {
    mediaDiv = <iframe title={svg_url} src={svg_url} />;
  } else if (audio_url) {
    mediaDiv = (
      <div className="audio">
        <img className={'img'} src={image_url} alt={image_url} />
        <audio
          controls
          controlsList="nodownload"
          loop
          preload="none"
          src={audio_url}></audio>
      </div>
    )
  }

  let linkDiv = (
    <a
      target="_blank"
      rel="noreferrer"
      href={marketplaceUrl}>
      View on objkt.com
    </a>
  );

  if (asset.contract === KALAMINT_CONTRACT) {
    linkDiv = (
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://kalamint.io/token/${asset.tokenId}`}>
        View on kalamint.io
      </a>
    );
  } else if (asset.symbol === 'Tezos') {
    linkDiv = (
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://tezos.com/nftgallery/`}>
        View on tezos.com
      </a>
    );
  }

  let mintDate = moment(asset.timestamp).format('MMMM D, YYYY');

  return (
    <NFTPageItem
      allowFullscreen={!Boolean(video_url)}
      className="xtz"
      collection={asset.collection}
      description={asset.description}
      imageUrl={image_url}
      mediaDiv={mediaDiv}
      mintDate={mintDate}
      mintDateDarkModeIcon="/xtz-dark.svg"
      mintDateLightModeIcon="/xtz-light.svg"
      name={asset.name || asset.chain_token_id}
      nft={nft}
      linkDiv={linkDiv}
      traits={asset.traits}
      floorPrice={asset.collection.stats.floorPrice}
      floorPriceCurrency='XTZ'
      simpleDescription
    />
  );
};

export default NFTPageHicetnunc;
