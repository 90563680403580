import React from 'react';

import './styles.scss';

const TextButton = ({children, onClick}) => (
  <button className="TextButton" onClick={onClick}>
    {children}
  </button>
);

export default TextButton;
