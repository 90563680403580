import React, {useRef} from 'react';
import moment from 'moment';

import NFTPageItem from './NFTPageItem';

const NFTPageTopShot = ({asset, nft}) => {
  const vidRef = useRef(null);

  const {play, owner, set = {}} = asset;
  let stats = {};
  let description;
  if (play) {
    stats = play.stats;
    description = play.description;
  }

  let video_url = `${asset.assetPathPrefix}Animated_1080_1920_Black.mp4`;

  let mediaDiv = (
    <video ref={vidRef} muted autoPlay loop playsInline preload="auto">
      <source src={video_url} type="video/mp4" />
    </video>
  );

  const name = stats.playerName;
  // let purchasePriceDiv = (<div>Purchase Price: ${asset.purchasedPrice}</div>)
  // if (asset.purchasedPrice === "None") {
  //   purchasePriceDiv = (<div>Purchase Price: Pack</div>)
  // }

  if (vidRef.current) {
    vidRef.current.play();
  }

  const mintDate = moment(stats.dateOfMoment).format('MMMM D, YYYY');
  const permalink = `https://nbatopshot.com/moment/${asset.id}`;

  const parts = set.setVisualId && set.setVisualId.split('_');
  let setType;
  if (parts) {
    setType = parts[parts.length - 1].toLowerCase();
  }

  const traits = React.useMemo(
    () => [
      {
        trait_type: 'Play Category',
        value: stats.playCategory,
      },
      {
        trait_type: set.flowName,
        value: 'Series ' + set.flowSeriesNumber,
      },
      {
        trait_type: setType,
        value: `#${asset.flowSerialNumber}/${
          asset.setPlay && asset.setPlay.circulationCount
        }`,
      },
    ],
    [asset, set, stats],
  );

  return (
    <NFTPageItem
      allowFullscreen
      className="flow"
      collection={{name: 'NBA Top Shot'}}
      username={owner?.username}
      description={description}
      descriptionAdditional={
        <>
          <div className="NFTPageItem__description__break">----</div>
          <div>
            {stats.awayTeamName}: {stats.awayTeamScore}
          </div>
          <div>
            {stats.homeTeamName}: {stats.homeTeamScore}
          </div>
        </>
      }
      imageUrl={video_url}
      mediaDiv={mediaDiv}
      mintDate={mintDate}
      mintDateDarkModeIcon="/flow-dark.svg"
      mintDateLightModeIcon="/flow-light.svg"
      mintDateText="Play date"
      name={name}
      nft={nft}
      linkDiv={
        <a target="_blank" rel="noreferrer" href={permalink}>
          View on nbatopshot.com
        </a>
      }
      simpleDescription
      traits={traits}
    />
  );
};

export default NFTPageTopShot;
