import { decodeMetadata } from './solanaUtils.ts'
import { PublicKey, Connection } from '@solana/web3.js'

import { addSolanaAddress, addAssetsForCollection } from '../redux/crypto'


const SOLANA_RPC_URL = "https://api.mainnet-beta.solana.com"
const METADATA_PUBKEY = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");

// export function validateAddress(walletAddress) {
//   const connection = new Connection(SOLANA_RPC_URL, 'confirmed')
//   const publicKey = new PublicKey(walletAddress).toBuffer()
//   connection.getAccountInfo(publicKey).then(account => {
//     console.log(account)
//     return account
//   })
// }

export function validateAddress(walletAddress) {
  const data = {
    "jsonrpc": "2.0",
    "id": 1,
    "method": "getAccountInfo",
    "params": [
      walletAddress,
      {
        "encoding": "base64"
      }
    ]
  }
  return fetch(SOLANA_RPC_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => res.json())
  .then(data => {
    if (data.error) {
      console.error(data.error)
      return false
    } else if (data.result.value) {
      return true
    }
  })
}

export function handleSolanaAddress(walletAddress, onSuccess, onFailure, onAddSuccess=null, solNFTs=[]) {
  return (dispatch) => {
    const data = {
      "jsonrpc": "2.0",
      "id": 1,
      "method": "getTokenAccountsByOwner",
      "params": [
        walletAddress,
        {
          "programId": "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
        },
          {
            "encoding": "jsonParsed"
          }
      ]
    }
    fetch(SOLANA_RPC_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(data => {
      const foundNFTAddresses = []
      data.result.value.forEach(item => {
        const parsedInfo = item.account.data.parsed.info
        if (parsedInfo.tokenAmount.amount === "1") {
          foundNFTAddresses.push(parsedInfo.mint)
        }
      })
      const nfts = []
      foundNFTAddresses.forEach((mintAddress, index) => {
        getNft(mintAddress).then(asset => {
          if (asset) {
            if (solNFTs.find(snft => snft.chain_token_id === mintAddress)) {
              console.log('found sol NFT', asset.name)
              nfts.push({})
            } else {
              nfts.push(
                {
                  id: mintAddress,
                  name: asset.name,
                  contract_address: mintAddress,
                  wallet_address: walletAddress,
                  wallet_address_id: walletAddress,
                  token_id: mintAddress,
                  chain: 'sol',
                  chain_main_id: mintAddress,
                  chain_token_id: mintAddress,
                  image_url: asset.image,
                  creation: false,
                  enabled: true
                }
              )
            }
          } else {
            nfts.push({})
          }
          if (nfts.length === foundNFTAddresses.length) {
            dispatch(addSolanaAddress(walletAddress, nfts, onSuccess, onFailure))
          }
        })
      })
      if (onAddSuccess && onSuccess) {
        onSuccess()
      }
    })
  }
}

export function getAsset(tokenId, dispatch) {
  getNft(tokenId).then(asset => {
    if (asset) {
      asset.token_id = tokenId;
      dispatch(addAssetsForCollection([asset]));
    }
  })
}

export async function getNft(mintAddress) {
  try {
    // input mint here
    let address = new PublicKey(mintAddress)
    let [pda, bump] = await PublicKey.findProgramAddress([
      Buffer.from("metadata"),
      METADATA_PUBKEY.toBuffer(),
      new PublicKey(address).toBuffer(),
    ], METADATA_PUBKEY)
    // console.log(pda.toBase58())

    const data = {
      "jsonrpc": "2.0",
      "id": 1,
      "method": "getAccountInfo",
      "params": [
        pda.toBase58(),
        {
          "encoding": "base64"
        }
      ]
    }
    return fetch(SOLANA_RPC_URL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())
      .then((data) => {
        let buf = Buffer.from(data.result.value.data[0], 'base64')
        let m = decodeMetadata(buf)
        return fetch(m.data.uri)
        .then((res) => res.json())
        .then((data) => {
          return data
        })
      }).catch((e) => {
        console.log(e)
      })
  } catch (e) {
    console.log(e)
  }
}

const exportedObject = {getAsset};
export default exportedObject;
