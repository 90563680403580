import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect, useParams, useLocation} from 'react-router-dom';
import QRCode from "react-qr-code";
import {validate} from 'multicoin-address-validator';

import opensea from '../../services/opensea';
import bettercalldev from '../../services/bettercalldev';
import topshot from '../../services/topshot';
import solana from '../../services/solana';
import counterparty from '../../services/counterparty';
import {requestFullscreen} from '../../services/utils';
import { getWltAsset, getWltOrdinalsAsset } from '../../services/wlt-graphql';

import {clearAssets, removeGuest, getAddresses} from '../../redux/crypto';

import NFTPageHicetnunc from './NFTPageHicetnunc';
import NFTPageOpensea from './NFTPageOpensea';
import NFTPageSolana from './NFTPageSolana';
import NFTPageTopShot from './NFTPageTopShot';
import NFTPageXCP from './NFTPageXCP';
import NFTPageOrdinals from './NFTPageOrdinals';

import {
  HIC_ET_NUNC_CONTRACT,
  KALAMINT_CONTRACT,
  TEZZARDS_CONTRACT,
  TOPSHOT_CONTRACT,
} from '../../consts/Contract';

import {useQueryParams} from '../../services/utils';

import './styles.scss';
import ThemeAwareIcon from '../../components/ThemeAwareIcon';

const QR_SMALL = 120
const QR_BIG   = 250

const NFTPage = () => {
  const location = useLocation();
  const queryParams = useQueryParams();
  const user = useSelector((state) => state.User.user);

  const collectionId = queryParams.get('collection');
  const username = queryParams.get('username');

  const isGuestView =
    username && username !== 'null' && (!user || user.username !== username);

  const collections = useSelector((state) =>
    isGuestView
      ? state.Crypto.guest.newCollections
      : state.Crypto.newCollections,
  );
  const uncategorizedNFTs = useSelector((state) =>
    isGuestView
      ? state.Crypto.guest.uncategorizedNFTs
      : state.Crypto.uncategorizedNFTs,
  );
  const collectionNFTs = useSelector((state) =>
    isGuestView
      ? state.Crypto.guest.collectionNFTs
      : state.Crypto.collectionNFTs,
  );
  const nfts = useSelector((state) =>
    isGuestView ? state.Crypto.guest.nfts : state.Crypto.nfts,
  );

  const collection = collections.find((c) => c.id === collectionId);
  const creatorView = collection && collection.creation;

  const _getFilteredNFTs = () => {
    return creatorView
      ? nfts.filter((n) => n.creation)
      : nfts.filter((n) => !n.creation);
  };

  const currentCollectionNFTs = [];
  if (collectionNFTs) {
    collectionNFTs
      .filter((cnft) => cnft.collection_id === collectionId)
      .forEach((cnft) => {
        const nft = _getFilteredNFTs().find((n) => n.id === cnft.nft_id);
        if (nft && nft.enabled) {
          currentCollectionNFTs.push(nft);
        }
      });
  }

  const currentAssets = useSelector((state) => state.Crypto.currentAssets);
  const guestUsername = useSelector((state) => state.Crypto.guest.username);

  const dispatch = useDispatch();

  let {contract_address, token_id} = useParams();
  token_id = token_id.split('?')[0];

  const [appState, setAppState] = React.useState({
    loading: true,
    assetNotFoundError: false,
  });

  const [qrSize, setQrSize] = React.useState(QR_SMALL);

  function assetNotFound() {
    setAppState({assetNotFoundError: true});
  }

  function getCoinType() {
    let coinType;
    if (contract_address) {
      if (contract_address === TOPSHOT_CONTRACT) {
        return 'flow';
      }
      if (contract_address === 'btc') {
        return 'btc'
      }
      ['eth', 'xtz'].forEach((c) => {
        if (validate(contract_address, c)) {
          coinType = c;
        }
      });
    }
    if (!coinType) {
      // check to see if sol address
      if (location.pathname.includes('sol')) {
        coinType = 'sol';
      }
    }
    return coinType;
  }

  const coinType = getCoinType();

  const getLink = (contract_address, token_id) => {
    if (contract_address && token_id) {
      let pathname = `/nft/${contract_address}/${token_id}?collection=${collectionId}`;

      if (coinType === 'sol') {
        pathname = `/nft/sol/${token_id}?collection=${collectionId}`
      }

      if (location.pathname.includes('polygon')) {
        pathname = `/nft/polygon/${contract_address}/${token_id}?collection=${collectionId}`
      }

      if (username) {
        pathname += `&username=${username}`;
      }
      return pathname;
    }
  };

  function getPrevLink() {
    const idx = getAssetIndex();
    if (idx > -1) {
      if (collectionId === 'undefined') {
        const prevIdx = idx === 0 ? uncategorizedNFTs.length - 1 : idx - 1;
        const asset = uncategorizedNFTs[prevIdx];
        return getLink(asset.contract_address, asset.chain_token_id);
      } else {
        if (collections.length > 0) {
          const collection = collections.find((c) => c.id === collectionId);
          if (collection) {
            const prevIdx =
              idx === 0 ? currentCollectionNFTs.length - 1 : idx - 1;
            const prev = currentCollectionNFTs[prevIdx];
            if (prev) {
              const asset = nfts.find((n) => n.id === prev.id);
              if (asset) {
                return getLink(asset.contract_address, asset.chain_token_id);
              }
            }
          }
        }
      }
    }
  }

  function getNextLink() {
    const idx = getAssetIndex();
    if (idx > -1) {
      if (collectionId === 'undefined') {
        const nextIdx = idx === uncategorizedNFTs.length - 1 ? 0 : idx + 1;
        const asset = uncategorizedNFTs[nextIdx];
        return getLink(asset.contract_address, asset.chain_token_id);
      } else {
        if (collections.length > 0) {
          const collection = collections.find((c) => c.id === collectionId);
          const nextIdx =
            idx === currentCollectionNFTs.length - 1 ? 0 : idx + 1;
          const collectionNFT = currentCollectionNFTs[nextIdx];
          if (collection && collectionNFT) {
            const asset = nfts.find((n) => n.id === collectionNFT.id);
            if (asset) {
              return getLink(asset.contract_address, asset.chain_token_id);
            }
          }
        }
      }
    }
  }

  function getAssetIndex() {
    if (currentAssets.length > 0 && collectionId) {
      const asset = currentAssets[0];
      const contract_address =
        coinType === 'btc' ? coinType :
        coinType === 'xtz' ? asset.contract : asset.contract_address;
      const tokenId = (asset.token_id || asset.tokenId).toString()
      if (collectionId === 'undefined') {
        // look through uncategorized NFTs
        return uncategorizedNFTs.findIndex(
          (u) =>
            u.contract_address === contract_address &&
            u.chain_token_id === tokenId
        );
      } else {
        const collection = collections.find((c) => c.id === collectionId);
        const currentNFTs = _getFilteredNFTs().filter(
          (n) =>
            n.contract_address === contract_address &&
            n.chain_token_id === tokenId,
        );
        if (collection && currentNFTs.length > 0) {
          return currentCollectionNFTs.findIndex(
            (cnft) => currentNFTs.map((n) => n.id).indexOf(cnft.id) > -1,
          );
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  const _getNFT = () => {
    if (currentAssets.length > 0 && collectionId) {
      const asset = currentAssets[0];
      const contract_address =
        coinType === 'xtz' ? asset.contract : asset.contract_address;
      if (collectionId === 'undefined') {
        // look through uncategorized NFTs
        return uncategorizedNFTs.find(
          (u) =>
            u.contract_address === contract_address &&
            u.chain_token_id === asset.token_id?.toString(),
        );
      } else {
        return _getFilteredNFTs().find(
          (n) =>
            n.contract_address === contract_address &&
            n.chain_token_id === asset.token_id?.toString(),
        );
      }
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearAssets());
    if (contract_address === 'btc') {
      if (!!parseInt(token_id)) {
        getWltOrdinalsAsset(token_id, dispatch)
      } else {
        counterparty.getAsset(token_id, dispatch)
      }
    } else if (validate(contract_address, 'eth')) {
      if (location.pathname.includes('rinkeby')) {
        opensea.getAsset(contract_address, token_id, dispatch, assetNotFound, true);
      } else if (location.pathname.includes('polygon')) {
        opensea.getAssetPolygon(contract_address, token_id, dispatch);
      } else {
        opensea.getAsset(contract_address, token_id, dispatch, assetNotFound);
      }
    } else if (validate(contract_address, 'xtz')) {
      getWltAsset('xtz', contract_address, token_id, dispatch);
    } else if (contract_address === TOPSHOT_CONTRACT) {
      topshot.getAsset(token_id, dispatch);
    } else if (location.pathname.includes('sol')) {
      getWltAsset('sol', token_id, token_id, dispatch, false);
    }

    if (isGuestView) {
      if (username !== guestUsername) {
        dispatch(removeGuest());
      }
      dispatch(getAddresses(username));
    } else {
      dispatch(removeGuest());
    }

    return function cleanup() {
      dispatch(clearAssets());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!contract_address && !location.pathname.includes('sol')) {
    return <Redirect to="/app" />;
  }

  if (appState.assetNotFoundError) {
    return (
      <div>
        NFT doesn't exist. Please check contract address and token ID in URL
      </div>
    );
  }

  if (!currentAssets || currentAssets.length === 0) {
    return <div>Loading...</div>;
  }

  let qrTitle = '';
  let qrLink = '';
  let key = '';
  const assetsDiv = [];

  currentAssets.forEach((ca, i) => {
    let AssetComponent;
    if (coinType === 'btc') {
      if (ca.tokenType === 'ordinals') {
        qrTitle = 'Ordinals'
        qrLink = ca.externalUrl
        AssetComponent = NFTPageOrdinals
        key = `ordinals_${i}`
      } else {
        console.log(ca)
        qrTitle = 'XChain'
        qrLink = `https://xchain.io/asset/${ca.name}`
        AssetComponent = NFTPageXCP
        key = `xcp_${i}`
      }
    } else if (coinType === 'sol') {
      qrTitle = 'Magic Eden'
      qrLink = `https://magiceden.io/item-details/${ca.tokenId}`
      AssetComponent = NFTPageSolana;
      key = `solana_${i}`
    } else if (coinType === 'flow') {
      qrTitle = 'NBA Top Shot';
      qrLink = `https://nbatopshot.com/moment/${ca.id}`;
      AssetComponent = NFTPageTopShot;
      key = `topshot_${i}`;
    } else if (coinType === 'xtz') {
      switch (ca.contract) {
        case HIC_ET_NUNC_CONTRACT:
          qrTitle = 'objkt';
          qrLink = `https://objkt.com/asset/${ca.contract}/${ca.token_id}`;
          break;
        case KALAMINT_CONTRACT:
          qrTitle = 'kalamint';
          qrLink = `https://kalamint.io/token/${ca.token_id}`;
          break;
        case TEZZARDS_CONTRACT:
          qrTitle = 'objkt';
          qrLink = `https://objkt.com/asset/tezzardz/${ca.token_id}`;
          break;
        default:
          qrTitle = 'objkt';
          qrLink = `https://objkt.com/collection/${ca.contract}`;
      }
      if (ca.symbol === 'Tezos') {
        qrLink = null;
      }
      AssetComponent = NFTPageHicetnunc;
      key = key = `tezos_${i}`;
    } else {
      // eth
      if (ca.contract) {
        qrTitle = 'Opensea';
        qrLink = `${ca.opensea_url}?ref=${process.env.REACT_APP_STCL_ADDRESS}`;

        AssetComponent = NFTPageOpensea;
        key = `opensea_${i}`;
      }
    }

    if (AssetComponent) {
      assetsDiv.push(<AssetComponent key={key} asset={ca} nft={_getNFT()} />);
    }
  });

  const prevLink = getPrevLink();
  const nextLink = getNextLink();

  return (
    <div className="NFTPage">
      <div className="NFTPage__main">
        {prevLink && (
          <Link
            className="NFTPage__main__arrow NFTPage__main__arrow--prev"
            to={prevLink}>
            <ThemeAwareIcon
              darkModeIconSrc="/prev-dark.svg"
              lightModeIconSrc="/prev-light.svg"
              alt="previous"
            />
          </Link>
        )}
        {assetsDiv}
        {nextLink && (
          <Link
            className="NFTPage__main__arrow NFTPage__main__arrow--next"
            to={nextLink}>
            <ThemeAwareIcon
              darkModeIconSrc="/next-dark.svg"
              lightModeIconSrc="/next-light.svg"
              alt="next"
            />
          </Link>
        )}
      </div>
      {qrLink && (
        <div className="NFTPage__qr">
          <div className="NFTPage__qr__header">
            <a target="_blank" href={qrLink}><span className="NFTPage__qr__title">{qrTitle}</span></a>
            <span> / GLLRY</span>
          </div>
          <div className="NFTPage__qr__container">
            <QRCode onClick={() => { qrSize === QR_SMALL ? setQrSize(QR_BIG) : setQrSize(QR_SMALL)}} size={qrSize} value={qrLink} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NFTPage;
