import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {register} from '../../../redux/user';

import CustomModal from '../../CustomModal';
import ModalButtons from '../../ModalButtons';

import '../../components.scss';
import FormInput from '../../Form/FormInput';

export const USERNAME_REGEX = '[^A-Za-z0-9_-]+';

const LayoutHeaderRegisterModal = ({closeModal}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [buttonState, setButtonState] = React.useState('default');
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [validationMsg, setValidationMsg] = React.useState('');

  const handleSubmit = React.useCallback(() => {
    const _username = username.trim();
    let valid = true;
    setValidationMsg('');

    if (_username.length === 0) {
      setValidationMsg('Please enter a username');
      valid = false;
    }
    if (_username.length < 3) {
      setValidationMsg('Username must be at least 3 characters');
      valid = false;
    }
    if (_username.match(USERNAME_REGEX)) {
      setValidationMsg(
        'Only letters, numbers, underscores, and hyphens are allowed in the username',
      );
      valid = false;
    }
    if (email.length > 0) {
      // TODO: handle email validation
    }
    if (password.length < 8 || password2 < 8) {
      setValidationMsg('Password must be at least 8 characters');
      valid = false;
    }
    if (password !== password2) {
      setValidationMsg('Passwords do not match');
      valid = false;
    }

    if (valid) {
      setButtonState('pending');
      dispatch(
        register(
          _username,
          password,
          email,
          (username) => { history.push(`/${username}`); closeModal(); },
          (msg) => {
            setButtonState('default');
            setValidationMsg(msg);
          },
        ),
      );
    } else {
      setButtonState('default');
    }
  }, [dispatch, email, history, password, password2, username]);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <CustomModal title="Create profile" onRequestClose={closeModal}>
      <form onSubmit={onSubmit}>
        <FormInput
          autoFocus
          label="Choose a username"
          type="text"
          value={username}
          onChange={setUsername}
        />
        <FormInput
          label="Email (optional)"
          note="Required to reset forgotten passwords"
          type="text"
          value={email}
          onChange={setEmail}
        />
        <FormInput
          label="Password"
          note="Minimum 8 characters"
          type="password"
          value={password}
          onChange={setPassword}
        />
        <FormInput
          label="Confirm Password"
          type="password"
          value={password2}
          onChange={setPassword2}
        />
        <ModalButtons
          buttonText="Save profile"
          buttonState={buttonState}
          buttonPendingText="saving..."
          closeModal={closeModal}
        />
        {validationMsg.length > 0 && (
          <div className="form__validation">{validationMsg}</div>
        )}
      </form>
    </CustomModal>
  );
};

export default LayoutHeaderRegisterModal;
