import {Link} from 'react-router-dom';

import NFTMedia from '../NFTMedia';

import './styles.scss';

const NFTsListItem = ({collectionId, nft, username, size, rinkeby=false}) => {
  let {chain, contract_address, chain_token_id} = nft;

  const token_id = chain_token_id;

  let pathname = '/nft';
  if (rinkeby) {
    pathname += '/rinkeby';
  } else if (chain === 'polygon') {
    pathname += '/polygon';
  }
  // make sure to add collection name to path (this is needed for left/right arrows)
  pathname += `/${contract_address}/${token_id}`;

  if (chain === 'sol') {
    pathname = `/nft/sol/${token_id}`;
  }

  let search = `?collection=${collectionId}`;
  if (username !== 'app') {
    search += `&username=${username}`
  }

  return (
    <Link
      to={{
        pathname,
        query: {username},
        search,
      }}>
      <NFTMedia nft={nft} size={size} showControls />
    </Link>
  );
};

export default NFTsListItem;
