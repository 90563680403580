import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {useQueryParams} from '../../../services/utils';

const NFTPageBreadcrumb = () => {
  const queryParams = useQueryParams();

  const user = useSelector((state) => state.User.user);
  const collectionId = queryParams.get('collection');
  const username = queryParams.get('username');

  const isGuestView =
    username && username !== 'null' && (!user || user.username !== username);

  const collections = useSelector((state) =>
    isGuestView
      ? state.Crypto.guest.newCollections
      : state.Crypto.newCollections,
  );

  const collection = collections.find((c) => c.id === collectionId);

  return (
    <div className="NFTPageBreadcrumb">
      <Link to={username ? `/${username}` : '/app'}>
        {'Home' || '(unsaved profile)'}
      </Link>
      <span>{'>'}</span>
      {collection?.id ? (
        <Link to={`/${username}/${collectionId}`}>{collection.name}</Link>
      ) : collectionId === 'undefined' || collectionId === 'uncategorized' ? (
        'Uncategorized'
      ) : collectionId === 'multiple' ? (
        'Multiple'
      ) : (
        collectionId
      )}
    </div>
  );
};

export default NFTPageBreadcrumb;
