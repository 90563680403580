import { ApolloClient, InMemoryCache, gql } from '@apollo/client'

import { addAssetsForCollection } from '../redux/crypto'

const getWltClient = () => {
  return new ApolloClient({
    uri: 'https://d2asw9lpli.execute-api.us-east-1.amazonaws.com/',
    cache: new InMemoryCache()
  })
}

export const getWltAsset = async (chain, contractAddress, tokenId, dispatch, includeCollection=false) => {
  const query = gql`
    query getAsset {
      asset(chain: "${chain}", contractAddress: "${contractAddress}", tokenId: "${tokenId}", includeCollection: ${includeCollection}) {
        name
        description
        traits {
          trait_type
          value
        }
        collection {
          name
          stats {
            floorPrice
          }
        }
        traits {
          trait_type
          value
        }
        chain
        contractAddress
        tokenId
        imageUrl
        thumbnailImageUrl
        videoUrl
        marketplace
        marketplaceUrl
        mintDate
      }
    }
  `
  try {
    const client = getWltClient()
    const result = await client.query({query})
    dispatch(addAssetsForCollection([result.data.asset]))
  } catch (err) {
    console.warn(err)
  }
}

export const getWltOrdinalsAsset = async (tokenId, dispatch=null) => {
  const query = gql`
    query getAsset {
      ordinal(inscriptionId: "${tokenId}") {
        chain
        name
        mediaUrl
        mimeType
        contractAddress
        tokenId
        tokenType
        traits {
          trait_type
          value
        }
        externalUrl
      }
    }
  `
  try {
    const client = getWltClient()
    const result = await client.query({query})
    if (dispatch) {
      dispatch(addAssetsForCollection([result.data.ordinal]))
    } else {
      return result.data.ordinal
    }
  } catch (err) {
    console.warn(err)
  }
}
