import React from 'react';

import {
  getReadableChainName,
  getShortenedAddress,
} from '../../../../services/NFTUtils';

import NFTMedia from '../../../../components/NFTMedia';

import './styles.scss';

const AdminPageNFTsNotesModalDetails = ({nft}) => {
  const shortenedAddress = React.useMemo(
    () => getShortenedAddress(nft.contract_address),
    [nft],
  );

  const readableChainName = React.useMemo(
    () => getReadableChainName(nft),
    [nft],
  );

  return (
    <div className="AdminPageNFTsNotesModalDetails">
      <NFTMedia nft={nft} size={64} square />
      <div className="AdminPageNFTsNotesModalDetails__data">
        <div className="AdminPageNFTsNotesModalDetails__name">{nft.name}</div>
        <div className="AdminPageNFTsNotesModalDetails__values">
          <div className="AdminPageNFTsNotesModalDetails__values-item">
            <span className="AdminPageNFTsNotesModalDetails__values-item-header">
              Blockchain
            </span>
            <span>{readableChainName}</span>
          </div>
          <div className="AdminPageNFTsNotesModalDetails__values-item">
            <span className="AdminPageNFTsNotesModalDetails__values-item-header">
              Contract
            </span>
            <span>{shortenedAddress}</span>
          </div>
          <div className="AdminPageNFTsNotesModalDetails__values-item">
            <span className="AdminPageNFTsNotesModalDetails__values-item-header">
              Token ID
            </span>
            <span>{nft.chain_token_id}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPageNFTsNotesModalDetails;
