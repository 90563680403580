import React, { useState, useEffect } from 'react';

import { getAllAssets } from '../../services/opensea'

import NFTsList from '../../components/NFTsList'

const WALLET_ADDRESS = '0xECae2bF6c1197dd17fAC8C84227be6BF39E5a356'

export default function Rinkeby() {
  const [nfts, setNFTs] = useState([])

  React.useEffect(() => {
    getAllAssets(WALLET_ADDRESS, null, foundNFTs)
    return
  }, []);

  const foundNFTs = (_nfts) => {
    const formattedNfts = []
    _nfts.forEach(n => {
      if (!n.image_url) {
        return
      }
      if (n.asset_contract.address != '0x39b7ebc34ed5b070e084bd355a159af3f44bfbc0') {
        return
      }
      if (['3', '8', '9', '10'].indexOf(n.token_id) === -1) {
        return
      }
      let formattedNft = {
        ...n,
        contract_address: n.asset_contract.address,
        chain_token_id: n.token_id,
        chain_main_id: n.id
      }
      if (n.image_url.includes('.mp4') || n.image_url.includes('.mov')) {
        formattedNft.media_url = n.image_url
        formattedNft.media_type = 'video/mp4'
      }
      formattedNfts.push(formattedNft)
    })
    setNFTs(formattedNfts)
  }

  return (
    <div className="CollectionPage">
      <NFTsList
        rinkeby={true}
        collectionId={null}
        nfts={nfts}
        username={null}
      />
    </div>
  )
}
