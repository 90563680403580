import './styles.scss'

import { useQueryParams } from '../../services/utils'

const DEFAULT_BG_COLOR = '#171717'

const AudioPage = () => {
  const queryParams = useQueryParams()
  const uri = queryParams.get('uri')
  const bgColor = queryParams.get('bgColor');

  document.body.style.backgroundColor = bgColor ? `#${bgColor}` : DEFAULT_BG_COLOR

  return (
    <audio
      controls
      controlsList="nodownload"
      loop
      preload
      src={uri}></audio>
  )
}
export default AudioPage
