import React from 'react';
import {useLocation} from 'react-router-dom';
import {useIsLightMode} from '../redux/ui';

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const isGGallery = () => {
  return window.location.href.includes('ggallery.')
}

export const isRizzle = () => {
  return window.location.href.includes('rizzlewallet.')
}

export const isBitBasel = () => {
  return window.location.href.includes('bitbasel.')
}

export const getIpfsUrl = (url) => {
  return url.replace('ipfs://', process.env.REACT_APP_IPFS_GATEWAY)
}

export const getImageUrl = (url, defaultUrl) =>
  typeof url === 'string'
    ? url.startsWith('blob')
      ? url
      : `https://gllry-image-uploads.s3.amazonaws.com/${url}`
    : typeof url === 'object'
    ? URL.createObjectURL(url)
    : defaultUrl;

export const addClassIfTrue = (className, classToAdd, condition) =>
  className + (condition ? ' ' + classToAdd : '');

export const useAddClassForLightMode = () => {
  const isLightMode = useIsLightMode();

  return React.useMemo(
    () => (className) =>
      addClassIfTrue(className, `${className}--isLightMode`, isLightMode),
    [isLightMode],
  );
};

export function requestFullscreen(event) {
  const element = event.target.classList.contains(
    'NFTPage__item__main__fullscreen',
  )
    ? event.target.parentElement.children[0]
    : event.target;
  const requestMethod =
    element.requestFullScreen ||
    element.webkitRequestFullscreen ||
    element.mozRequestFullScreen ||
    element.msRequestFullscreen;

  if (requestMethod) {
    requestMethod.apply(element);
  }
}

export function sortNFTs(nfts) {
  return nfts.sort((a, b) => {
    if (a.contract_address > b.contract_address) return 1;
    if (b.contract_address > a.contract_address) return -1;
    if (parseInt(a.token_id) > parseInt(b.token_id)) return 1;
    if (parseInt(b.token_id) > parseInt(a.token_id)) return -1;
    return 0;
  });
}

export const getNFTsForCollection = (collectionId, nfts, collectionNFTs) => {
  const foundNFTs = [];

  collectionNFTs
    .filter((cnft) => cnft.collection_id === collectionId)
    .forEach((cnft) => {
      const nft = nfts.find((nft) => nft.id === cnft.nft_id);

      if (nft?.enabled) {
        foundNFTs.push({
          ...nft,
          order: cnft.order,
        });
      }
    });

  return foundNFTs.sort((a, b) => {
    const a_order = a.order;
    const b_order = b.order;
    if (a_order > b_order) return 1;
    if (b_order > a_order) return -1;
    return 0;
  });
};

export const getCollectionsForNFT = (nftId, collections, collectionNFTs) => {
  const currentCollectionNFTs = collectionNFTs
    ? collectionNFTs.filter((cnft) => cnft.nft_id === nftId)
    : [];

  return currentCollectionNFTs.reduce((acc, cur) => {
    const collection = collections.find((c) => c.id === cur.collection_id);
    return collection ? [...acc, collection] : acc;
  }, []);
};
