import React from 'react';
import {useDispatch} from 'react-redux';

import {removeNFT} from '../../../redux/crypto';

import CustomModal from '../../../components/CustomModal';
import ModalButtons from '../../../components/ModalButtons';

const AdminPageNFTsRemoveModal = ({nft, closeModal}) => {
  const dispatch = useDispatch()

  const removeNftClicked = React.useCallback(
    () => dispatch(removeNFT(nft.id, closeModal)),
    [dispatch, nft, closeModal],
  )

  return (
    <CustomModal title="Remove NFT" onRequestClose={closeModal}>
      <div className="modal-boxed-text">{nft.name}</div>
      <div className="modal-confirmation-title">Are you sure?</div>
      <div className="modal-confirmation-text">
        Removing this NFT from your profile will permanently erase all
        user-generated curation data including NFT collection assignments,
        collector descriptions, tags, and guest book entries.
      </div>
      <ModalButtons
        buttonType="default"
        buttonClassName="red"
        buttonOnClick={removeNftClicked}
        buttonText="Yes, Remove"
        closeModal={closeModal}
      />
    </CustomModal>
  );
};

export default AdminPageNFTsRemoveModal;
