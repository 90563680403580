import React from 'react';
import {useDispatch} from 'react-redux';

import {removeAddress} from '../../../redux/crypto';

import CustomModal from '../../../components/CustomModal';
import ModalButtons from '../../../components/ModalButtons';

const AdminPageWalletsRemoveModal = ({address, closeModal}) => {
  const dispatch = useDispatch();

  const removeWalletClicked = React.useCallback(
    () => dispatch(removeAddress(address.id, closeModal)),
    [dispatch, address.id, closeModal],
  );

  return (
    <CustomModal title="Remove wallet" onRequestClose={closeModal}>
      <div className="modal-boxed-text">{address.alias || address.address}</div>
      <div className="modal-confirmation-title">Are you sure?</div>
      <div className="modal-confirmation-text">
        Removing this wallet from your profile will permanently erase all
        user-generated curation data including NFT collection assignments,
        collector descriptions, tags, and guest book entries.
      </div>
      <ModalButtons
        buttonType="default"
        buttonClassName="red"
        buttonOnClick={removeWalletClicked}
        buttonText="Yes, Remove"
        closeModal={closeModal}
      />
    </CustomModal>
  );
};

export default AdminPageWalletsRemoveModal;
