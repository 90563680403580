import {Link} from 'react-router-dom';

import {addClassIfTrue} from '../../../services/utils';
import {getShortenedAddress} from '../../../services/NFTUtils';

import NFTMedia from '../../../components/NFTMedia';
import FormSwitch from '../../../components/Form/FormSwitch';

export const getColumns = (addToCollection, toggleShowNFT, editNFTNotes, removeNFTClicked) => [
  {
    Header: 'Image',
    accessor: (nft) => (
      <Link to={nft.pathname}>
        <NFTMedia nft={nft} size={48} square />
      </Link>
    ),
    disableSortBy: true,
  },
  {
    Header: 'Title',
    Cell: ({row: {original: nft, index}}) => {
      return (
        <div>
          <Link to={nft.pathname}>
            <span>{nft.name}</span>
          </Link>
          <span className="AdminPageNFTs__hideDesktop">
            {getShortenedAddress(nft.wallet, 20)}
          </span>
          <span
            className={addClassIfTrue(
              'AdminPageNFTs__collection AdminPageNFTs__hideDesktop',
              'AdminPageNFTs__collection--withBrackets',
              nft.collectionName === 'uncategorized' ||
                nft.collectionName === 'multiple',
            )}>
            <span>{nft.collectionName}</span>
            <span onClick={() => addToCollection({[index]: true})}>(Edit)</span>
          </span>
        </div>
      );
    },
    accessor: 'name',
  },
  {
    Header: 'Wallet',
    Cell: ({row: {original: nft}}) => (
      <>
        <span className="AdminPageNFTs__hideMobile">
          {nft.wallet !== 'None' && getShortenedAddress(nft.wallet, 20)}
          {nft.wallet === 'None' &&
            (<div>{nft.wallet} <span onClick={() => removeNFTClicked(nft)}>Remove</span></div>)
          }
        </span>
        <div className="AdminPageNFTs__hideDesktop">
          <FormSwitch
            onChange={() => toggleShowNFT(nft.id)}
            checked={nft.enabled}
            color="default"
          />
          <img
            onClick={() => editNFTNotes(nft)}
            src="/edit-light.svg"
            alt="Edit NFTs"
          />
        </div>
      </>
    ),
    accessor: 'wallet',
  },
  {
    hideMobile: true,
    Header: 'Collection',
    Cell: ({row: {original: nft, index}}) => (
      <span
        className={addClassIfTrue(
          'AdminPageNFTs__collection',
          'AdminPageNFTs__collection--withBrackets',
          nft.collectionName === 'uncategorized' ||
            nft.collectionName === 'multiple',
        )}>
        <span>{nft.collectionName}</span>
        <span onClick={() => addToCollection({[index]: true})}>(Edit)</span>
      </span>
    ),
    accessor: 'collectionName',
  },
  {
    hideMobile: true,
    Header: 'Show / Hide',
    sortType: (nft1, nft2) =>
      nft1.original.enabled === nft2.original.enabled
        ? 0
        : nft1.original.enabled
        ? -1
        : 1,
    accessor: (nft) => (
      <FormSwitch
        onChange={() => toggleShowNFT(nft.id)}
        checked={nft.enabled}
        color="default"
      />
    ),
  },
  {
    hideMobile: true,
    Header: 'Notes',
    accessor: (nft) => (
      <img
        className="AdminPageNFTs__editIcon"
        onClick={() => editNFTNotes(nft)}
        src="/edit-light.svg"
        alt="Edit NFTs"
      />
    ),
    disableSortBy: true,
  },
];
