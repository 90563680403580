import React from 'react';

import {getShortenedAddress} from '../../../../services/NFTUtils';

import NFTMedia from '../../../../components/NFTMedia';

const AdminPageCollectionsOrganizeModalNFT = ({
  collectionLength,
  index,
  isMoving,
  moveDown,
  moveUp,
  nft,
}) => {
  const handleMoveUp = React.useCallback(() => moveUp(index), [index, moveUp]);

  const handleMoveDown = React.useCallback(
    () => moveDown(index),
    [index, moveDown],
  );

  return (
    <div className="AdminPageCollectionsOrganizeModalNFT">
      <div className="AdminPageCollectionsOrganizeModalNFT__upDown">
        <img
          onClick={handleMoveUp}
          className={index === 0 || isMoving ? 'disabled' : ''}
          src="/button_up.svg"
          alt="up"
        />
        <img
          onClick={handleMoveDown}
          className={
            index === collectionLength - 1 || isMoving ? 'disabled' : ''
          }
          src="/button_down.svg"
          alt="down"
        />
      </div>
      <NFTMedia nft={nft} size={48} square />
      <div className="AdminPageCollectionsOrganizeModalNFT__desc">
        <span className="AdminPageCollectionsOrganizeModalNFT__name">
          {nft.name}
        </span>
        <span className="AdminPageCollectionsOrganizeModalNFT__address">
          {getShortenedAddress(nft.contract_address)}
        </span>
      </div>
    </div>
  );
};

export default AdminPageCollectionsOrganizeModalNFT;
