import React from 'react';
import {useDispatch} from 'react-redux';

import {editNFT} from '../../../../redux/crypto';

import CustomModal from '../../../../components/CustomModal';
import FormTextarea from '../../../../components/Form/FormTextarea';
import ModalButtons from '../../../../components/ModalButtons';

import AdminPageNFTsNotesModalDetails from './AdminPageNFTsNotesModalDetails';

const AdminPageNFTsNotesModal = ({closeModal, nft}) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = React.useState(nft.notes);

  const handleSubmit = React.useCallback(
    () => dispatch(editNFT(nft.id, notes, closeModal)),
    [closeModal, dispatch, nft.id, notes],
  );

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <CustomModal title="Details & Notes" onRequestClose={closeModal}>
      <AdminPageNFTsNotesModalDetails nft={nft} />
      <form onSubmit={onSubmit}>
        <FormTextarea
          autoFocus
          label="Collector Notes"
          value={notes}
          onChange={setNotes}
        />
        <ModalButtons
          buttonText="Save Changes"
          buttonPendingText="Saving..."
          closeModal={closeModal}
        />
      </form>
    </CustomModal>
  );
};

export default AdminPageNFTsNotesModal;
