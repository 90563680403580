import React from 'react';

import {useAddClassForLightMode} from '../../../services/utils';

const NFTPageNotes = ({nft}) => {
  const addClassForLightMode = useAddClassForLightMode();

  return nft && nft.notes ? (
    <div className="NFTPageNotes">
      <div className="NFTPageNotes__header">
        <>{nft.creation ? 'Creator notes' : 'Collector notes'}</>
        <span>Edit</span>
      </div>
      <div className={addClassForLightMode('NFTPageNotes__text')}>
        {nft.notes}
      </div>
    </div>
  ) : null;
};

export default NFTPageNotes;
