import React from 'react';
import {addClassIfTrue} from '../../services/utils';

import NFTsListItem from './NFTsListItem';

import './styles.scss';

const getNFTWidthForCols = (listWidth, cols) => (listWidth - 36 * cols) / cols;

const NFTsList = ({collapsed, collectionId, minSize = 240, nfts, username, rinkeby=false}) => {
  const NFTsListRef = React.useRef();
  const [itemSize, setItemSize] = React.useState(minSize);
  const [wasOpen, setWasOpen] = React.useState(!collapsed);

  const updateItemSize = React.useCallback(() => {
    const listComponent = NFTsListRef.current;

    if (listComponent) {
      const listWidth = listComponent.clientWidth;

      if (getNFTWidthForCols(listWidth, 4) > minSize) {
        setItemSize(getNFTWidthForCols(listWidth, 4));
      } else if (getNFTWidthForCols(listWidth, 3) > minSize) {
        setItemSize(getNFTWidthForCols(listWidth, 3));
      } else if (getNFTWidthForCols(listWidth, 2) > minSize) {
        setItemSize(getNFTWidthForCols(listWidth, 2));
      } else {
        setItemSize(getNFTWidthForCols(listWidth, 1));
      }
    }
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', updateItemSize);
    updateItemSize();
    return () => window.removeEventListener('resize', updateItemSize);
  }, []);

  React.useEffect(() => {
    if (!collapsed) {
      setWasOpen(true);
    }
  }, [collapsed]);

  const style = React.useMemo(
    () => ({
      minHeight: itemSize,
    }),
    [itemSize],
  );

  return (
    <div
      className={addClassIfTrue('NFTsList', 'NFTsList--collapsed', collapsed)}
      style={style}
      ref={NFTsListRef}>
      {(!collapsed || wasOpen) &&
        nfts.map((nft) => (
          <NFTsListItem
            key={`nft_${nft.chain_main_id}_${nft.chain_token_id}`}
            collectionId={collectionId}
            nft={nft}
            username={username}
            size={itemSize}
            rinkeby={rinkeby}
          />
        ))}
    </div>
  );
};

export default NFTsList;
