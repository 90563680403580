import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import CustomModal from '../CustomModal';
import ModalButtons from '../ModalButtons';

import {logout} from '../../redux/user';

const LogoutModal = ({closeModal}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutClicked = React.useCallback(() => {
    dispatch(logout());
    history.push('/');
    closeModal()
  }, [dispatch, history]);

  return (
    <CustomModal title="Log out" onRequestClose={closeModal}>
      <div className="modal-boxed-text disabled">Are you sure you want to log out?</div>
      <ModalButtons
        buttonType="default"
        buttonOnClick={logoutClicked}
        buttonText="Yes, Log out"
        closeModal={closeModal}
      />
    </CustomModal>
  );
};

export default LogoutModal;
