import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, Redirect, useRouteMatch} from 'react-router-dom';

import GllryMetaTags from '../../components/GllryMetaTags';
import FormSwitch from '../../components/Form/FormSwitch';

import { toggleKioskMode } from '../../redux/user'
import { addClassIfTrue } from '../../services/utils'

import AdminPageCollections from './AdminPageCollections';
import AdminPageHeader from './AdminPageHeader';
import AdminPageNFTs from './AdminPageNFTs';
import AdminPageWallets from './AdminPageWallets';

import './styles.scss';

const AdminPage = () => {
  const dispatch = useDispatch()
  const {
    params: {tab},
  } = useRouteMatch();
  const user = useSelector((state) => state.User.user);
  const kioskModeEnabled = useSelector(state => state.User.kioskModeEnabled);

  const onChange = () => {
    dispatch(toggleKioskMode(() => window.location.reload()))
  }

  return user ? (
    <div className="AdminPage">
      <GllryMetaTags />
      <AdminPageHeader user={user} />
      <div className='AdminPage__pincode'>
        <div>Enable password for Kiosk Mode</div>
        <FormSwitch checked={kioskModeEnabled} onChange={onChange} />
      </div>
      <div className="AdminPage__main">
        <div className="AdminPage__tabs">
          <Link
            to="/admin"
            className={addClassIfTrue('AdminPage__tab', 'active', !tab)}>
            wallets
          </Link>
          <Link
            to="/admin/collections"
            className={addClassIfTrue(
              'AdminPage__tab',
              'active',
              tab === 'collections',
            )}>
            collections
          </Link>
          <Link
            to="/admin/nfts"
            className={addClassIfTrue(
              'AdminPage__tab',
              'active',
              tab === 'nfts',
            )}>
            nfts
          </Link>
        </div>
        <div className="AdminPage__body">
          {!tab && <AdminPageWallets />}
          {tab === 'nfts' && <AdminPageNFTs />}
          {tab === 'collections' && <AdminPageCollections />}
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/app" />
  );
};

export default AdminPage;
