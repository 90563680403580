import React from 'react';
import moment from 'moment';

import NFTPageItem from './NFTPageItem';

const NFTPageSolana = ({asset, nft}) => {
  let image_url, video_url, svg_url;

  if (asset.imageUrl) {
    image_url = asset.imageUrl
  }

  if (image_url && image_url.includes('/ipfs/')) {
    const parts = image_url.split('/ipfs/')
    image_url = `${process.env.REACT_APP_IPFS_GATEWAY}${parts[parts.length-1]}`
  }

  let mediaDiv = <img alt={image_url} src={image_url} />;

  if (video_url) {
    mediaDiv = (
      <video controls="controls" preload="metadata" loop>
        <source src={video_url} type="video/mp4" />
      </video>
    );
  } else if (svg_url) {
    mediaDiv = <iframe title={svg_url} src={svg_url} />;
  }

  let linkDiv = (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://magiceden.io/item-details/${asset.tokenId}`}>
      View on Magic Eden
    </a>
  );

  let mintDate = moment(asset.timestamp).format('MMMM D, YYYY');

  return (
    <NFTPageItem
      allowFullscreen={!Boolean(video_url)}
      className="sol"
      collection={asset.collection}
      description={asset.description}
      imageUrl={image_url}
      mediaDiv={mediaDiv}
      // mintDate={mintDate}
      mintDateDarkModeIcon="/sol-dark.svg"
      mintDateLightModeIcon="/sol-light.svg"
      name={asset.name}
      nft={nft}
      linkDiv={linkDiv}
      traits={asset.traits}
      floorPrice={asset.collection?.stats?.floorPrice}
      floorPriceCurrency='SOL'
      simpleDescription
    />
  );
};

export default NFTPageSolana;
