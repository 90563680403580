import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useHistory} from 'react-router-dom';

import {
  useAddClassForLightMode,
  addClassIfTrue,
  getImageUrl,
} from '../../../../services/utils';

import Button from '../../../Button';

import LayoutHeaderThemeSwitch from '../LayoutHeaderThemeSwitch';
import LayoutHeaderMenuBurger from './LayoutHeaderMenuBurger';
import LayoutHeaderKioskModal from '../LayoutHeaderKioskModal';
import LogoutModal from '../../../LogoutModal';

import './styles.scss';

const MODAL_TYPES = {
  KIOSK_MODE: 'kiosk',
  LOGOUT: 'logout'
}

const LayoutHeaderMenu = ({openLoginModal, openRegisterModal}) => {
  const addClassForLightMode = useAddClassForLightMode();
  const location = useLocation();
  const history = useHistory()

  const [isOpen, setIsOpen] = React.useState(false);

  const user = useSelector((state) => state.User.user);
  const kioskModeEnabled = useSelector((state) => state.User.kioskModeEnabled);
  const addresses = useSelector((state) => state.Crypto.addresses);

  const [modalType, setModalType] = React.useState(false);
  const openModal = React.useCallback((_type) => setModalType(_type), []);
  const closeModal = React.useCallback(() => setModalType(false), []);

  const toggleOpen = React.useCallback(
    () => setIsOpen((prevState) => !prevState),
    [],
  );

  const profileClicked = () => {
    if (kioskModeEnabled) {
      openModal(MODAL_TYPES.KIOSK)
    } else {
      history.push('/admin')
    }
  }

  React.useEffect(() => {
    isOpen && setIsOpen(false);
  }, [location.pathname]);

  React.useEffect(() => {
    if (document) {
      document.documentElement.style.overflow = isOpen ? 'hidden' : 'auto';
      document.documentElement.scrollTop = 0;
    }
  }, [isOpen]);

  return (
    <div className="LayoutHeaderMenu">
      {location.pathname.includes('/admin') && (
        <Link to="/" className="LayoutHeaderMenu__return">
          <div className="LayoutHeaderMenu__text">{'< Return to GLLRY'}</div>
        </Link>
      )}
      <LayoutHeaderMenuBurger
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        withDot={!user && addresses.length}
      />
      <div
        className={addClassIfTrue(
          addClassForLightMode('LayoutHeaderMenu__list'),
          'LayoutHeaderMenu__list--isOpen',
          isOpen,
        )}>
        {user ? (
          <>
            <div onClick={profileClicked} className="LayoutHeaderMenu__text">
              <img
                className="LayoutHeaderMenu__avatar"
                src={getImageUrl(user.profile_picture_url, '/profile-nav.svg')}
                alt="avatar"
              />
              {user.display_name || user.username}
            </div>
            {location.pathname.includes('/admin') && (
              <div className="LayoutHeaderMenu__text" onClick={() => openModal(MODAL_TYPES.LOGOUT)}>
                Logout
              </div>
            )}
          </>
        ) : (
          <>
            <div className="LayoutHeaderMenu__text" onClick={openLoginModal}>
              Login
            </div>
            <Button
              className="LayoutHeaderMenu__hideMobile"
              state={addresses.length ? '' : 'secondary'}
              onClick={openRegisterModal}
              text={addresses.length ? 'Save profile' : 'Create profile'}
            />
            <div
              className={addClassIfTrue(
                'LayoutHeaderMenu__text LayoutHeaderMenu__hideDesktop',
                'LayoutHeaderMenu__text--green',
                addresses.length,
              )}
              onClick={openRegisterModal}>
              {addresses.length ? 'Save profile' : 'Create profile'}
            </div>
          </>
        )}
        <LayoutHeaderThemeSwitch />
      </div>
      <div
        className={addClassIfTrue(
          'LayoutHeaderMenu__hideDesktop LayoutHeaderMenu__list-overlay',
          'LayoutHeaderMenu__list--isOpen',
          isOpen,
        )}
        onClick={toggleOpen}
      />
      {modalType === MODAL_TYPES.KIOSK &&
        <LayoutHeaderKioskModal closeModal={closeModal} />
      }
      {modalType === MODAL_TYPES.LOGOUT && (
        <LogoutModal closeModal={closeModal} />
      )}
    </div>
  );
};

export default LayoutHeaderMenu;
