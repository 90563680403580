import React, {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useAsyncDebounce} from 'react-table';

import {addNFTs} from '../../redux/crypto';

import {
  getCoinTypeFromAddress,
  addressAbbrv
} from '../../services/addressUtils';

import { initializeOpensea } from '../../services/opensea'
import { getAsset as getAlchemyAsset } from '../../services/alchemy';
import { getWltOrdinalsAsset } from '../../services/wlt-graphql';

import CustomModal from '../CustomModal';
import FormInput from '../Form/FormInput';
import ModalButtons from '../ModalButtons';
import TextButton from '../TextButton';

const SMART_CONTRACTS = {
  'Select': '0',
  'Custom contract': '-1',
  'OpenSea': '0x495f947276749ce646f68ac8c248420045cb7b5e',
  'SuperRare': '0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0',
  'Foundation': '0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405',
  'Foundation 2': '0x15142bfE5da675666C8895a3E3e2dE5d1aa74482',
  'Zora': '0xabEFBc9fD2F806065b4f3C237d4b59D9A97Bcac7',
  'Rarible': '0x60F80121C31A0d46B5279700f9DF786054aa5eE5',
  'Rarible Singles': '0xf6793da657495ffeff9ee6350824910abc21356c',
  'Rarible Multiples': '0xB66a603f4cFe17e3D27B87a8BfCaD319856518B8',
  'Rarible alt 1': '0xc9154424B823b10579895cCBE442d41b9Abd96Ed',
  'Rarible alt 2': '0xd07dc4262bcdbf85190c01c996b4c06a461d2430',
  'KnownOrigin v2': '0xFBeef911Dc5821886e1dda71586d90eD28174B7d',
  'KnownOrigin v3': '0xABB3738f04Dc2Ec20f4AE4462c3d069d02AE045B',
  'Makersplace MKT2': '0x2a46f2ffd99e19a89476e2f62270e0a35bbf0756',
  'Mintable': '0xc23d925684919c1619f13427817d3fee6c24debb',
  'Ordinals': 'btc'
}

const AddNFTModal = ({openModal, closeModal, onAddSuccess}) => {
  const dispatch = useDispatch();

  const [seaport, setSeaport] = useState(initializeOpensea())
  const [showManualContractEntry, setShowManualContractEntry] = useState(false)
  const [chain, setChain] = useState('');
  const [address, setAddress] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [addressIsValid, setAddressIsValid] = useState();
  const [addressValidationMessage, setAddressValidationMessage] = useState('');
  const [asset, setAsset] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const _getIpfsUrl = (url) => {
    return url.replace('ipfs://', process.env.REACT_APP_IPFS_GATEWAY)
  }

  const _isVideo = (url) => {
    return url.includes('.mp4') || url.includes('.mov')
  }

  const onSubmit = useCallback((ev) => {
    ev.preventDefault();
    if (asset) {
      let nftJson
      if (address === 'btc') {
        nftJson = {
          id: `ordinals`,
          name: asset.name,
          contract_address: address,
          token_id: asset.tokenId,
          image_url: asset.mediaUrl,
          media_url: asset.mediaUrl,
          media_type: asset.mimeType,
          chain: 'btc'
        }
      } else {
        const { contractAddress, tokenId, imageUrl } = asset
        nftJson = {
          id: `${contractAddress}_${tokenId}`,
          name: asset.name,
          contract_address: contractAddress,
          token_id: tokenId,
          image_url: imageUrl,
          media_url: asset.animation_url,
          chain: chain || 'eth'
        }
      }
      dispatch(addNFTs([nftJson], nftSaved))
    } else {
      console.error('no asset found when attempting to add NFT')
    }
  }, [asset])

  const nftSaved = useCallback(() => {
    setAsset(null)
    if (showManualContractEntry) {
      setAddress('')
      setAddressIsValid(false)
    }
    setAddressValidationMessage('')
    setTokenId('')
    setSuccess('NFT added!')
  }, [address, addressIsValid, addressValidationMessage, tokenId, success])

  const validateAddressAndSet = useCallback((value) => {
    setAddress(value);
    if (!value) {
      setAddressValidationMessage('');
      setAddressIsValid(false);
    } else {
      const coinTypeFromAddress = getCoinTypeFromAddress(value)
      if (coinTypeFromAddress) {
        setAddressValidationMessage(`Valid ${coinTypeFromAddress} address`);
        getAsset(value, tokenId);
      } else {
        setAddressValidationMessage(
          'Invalid address (only ETH currently supported)',
        );
        setAddressIsValid(false);
      }
    }
  }, [tokenId])

  const getAssetDebounce = useAsyncDebounce(
    (address, tokenId) => getAsset(address, tokenId),
    500,
  );

  const validateTokenIdAndSet = useCallback((value) => {
    setTokenId(value);
    if (!value) {
      setAddressValidationMessage('')
      setAsset(null)
    } else {
      getAssetDebounce(address, value);
    }
  }, [address])

  // OPENSEA SPECIFIC METHODS

  const getAsset = async (_address, _tokenId) => {
    if (!_address || !_tokenId) {
      setAsset(null)
      return;
    }
    setError('')
    try {
      // const asset = await seaport.api.getAsset({
      //   tokenAddress: _address, // string
      //   tokenId: _tokenId, // string | number | null
      // })
      let asset
      if (_address === 'btc') {
        asset = await getWltOrdinalsAsset(_tokenId)
      } else if (chain === 'polygon') {
        asset = await getAlchemyAsset(chain, _address, _tokenId)
      } else {
        const apiUrl = `https://api.opensea.io/api/v2/chain/ethereum/contract/${_address}/nfts/${_tokenId}?format=json`;
        const headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
        const res = await fetch(apiUrl, { headers })
        const data = await res.json()
        const nft = data.nft 
        asset = {
          name: nft.name,
          description: nft.description,
          contractAddress: nft.contract,
          tokenId: nft.identifier,
          imageUrl: nft.image_url,
          // media_url: image.animation_url,
          chain: 'eth'
        }
      }
      if (asset) {
        setAsset(asset)
        setAddressIsValid(true)
      }
    } catch (err) {
      setError('NFT not found')
      setAsset(null)
      setAddressIsValid(false)
    }
  }

  const options = []
  for (const [key, value] of Object.entries(SMART_CONTRACTS)) {
    options.push(<option value={value}>{key}</option>)
  }

  const contractSelected = (evt) => {
    const address = evt.target.value
    if (address.startsWith('0x') || address === 'btc') {
      setShowManualContractEntry(false)
      setAddress(address)
      setTokenId('')
      setAsset(null)
    } else if (address === '-1') {
      setShowManualContractEntry(true)
      setAddress('')
    }
  }

  const chainSelected = (evt) => {
    const chain = evt.target.value
    setChain(chain)
  }

  const assetDiv = React.useMemo(() => {
    if (asset) {
      const imageUrl = asset.image_url || asset.imageUrl || asset.mediaUrl
      if (imageUrl && imageUrl.startsWith('ipfs://')) {
        imageUrl = _getIpfsUrl(imageUrl)
      }
      const videoUrl = asset.animation_url || (_isVideo(imageUrl) && imageUrl)
      const { contractAddress, tokenId } = asset
      const abbrvAddress = asset.chain === 'btc' ? contractAddress : addressAbbrv(contractAddress)
      return (
        <div className='form__item add-modal'>
          {videoUrl ? <video src={videoUrl} /> : <img src={imageUrl} />}
          <div>
            <div className='form__item__name bold'>{asset.name}</div>
            {/* <div className='form__item__name'>{assetContract.name}</div> */}
            <div className='form__item__name'>{abbrvAddress} (Token #{tokenId})</div>
          </div>
        </div>
      )
    }
    return null
  }, [asset])

  // END OPENSEA SPECIFIC METHODS

  return (
    <CustomModal
      title="Add Individual NFT"
      titleButton={
        <TextButton onClick={() => openModal('add-nft-by-file')}>
          Add via CSV
        </TextButton>
      }
      onRequestClose={closeModal}
    >
      <form className='form' onSubmit={onSubmit}>
        <div className='form__add-nft'>
          <div className='form__add-nft__header'>Select marketplace contract</div>
          <select onChange={contractSelected}>
            {options}
          </select>
        </div>
        {showManualContractEntry &&
          <>
            <div className="FormInputContainer">
              <div className="FormInputContainer__label">
                <span>{'Blockchain'}</span>
              </div>
              <div>
                <select onChange={chainSelected}>
                  <option value='eth'>ETH</option>
                  <option value='polygon'>Polygon</option>
                </select>
              </div>
            </div>
            <FormInput
                autoFocus
                isValid={addressIsValid}
                label="Custom Contract Address"
                onChange={validateAddressAndSet}
                placeholder="NFT Contract Address (0x...)"
                validationMessage={addressValidationMessage}
                value={address}
            />
          </>
        }
        <FormInput
            label="NFT ID"
            onChange={validateTokenIdAndSet}
            placeholder="NFT ID"
            value={tokenId}
            isValid={!error}
            validationMessage={error}
        />
        {assetDiv}
        <ModalButtons
          buttonText="Add NFT"
          buttonState={
            addressIsValid ? 'default' : 'disabled'
          }
          buttonPendingText="Adding..."
          closeModal={closeModal}
        />
        {success && <div className='FormInputContainer__validation valid'>{success}</div>}
      </form>
    </CustomModal>
  )
}

export default AddNFTModal;
