import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import * as Web3 from 'web3'
import { OpenSeaPort, Network, EventType } from 'opensea-js'
import { OrderSide } from 'opensea-js/lib/types'

import { getAllAssets } from '../../services/opensea'
import QrReader from './QrReader'

import './styles.scss';

const HDWalletProvider = require("@truffle/hdwallet-provider");
const PROVIDER_URL = "https://rinkeby.infura.io/v3/" + process.env.REACT_APP_INFURA_PROJECT_ID

const QR_SCANNING_ENABLED = true

function Purchase() {
  const [secret, setSecret] = useState(process.env.REACT_APP_WALLET_SECRET)
  const [walletConnected, setWalletConnected] = useState(false)
  const [seaport, setSeaport] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(null);
  const [showQr, setShowQr] = useState(false);
  const [url, setUrl] = useState(null)
  const [asset, setAsset] = useState(null)
  const [order, setOrder] = useState(null)
  const [error, setError] = useState('')
  const [balance, setBalance] = useState('loading balance...')
  const [nfts, setNFTs] = useState([])
  const [transactionHash, setTransactionHash] = useState(null)

  const sortAndSetNFTs = (nfts) => {
    setNFTs(nfts.sort((a,b) => {
      if (a.last_sale.created_date < b.last_sale.created_date) return 1;
      if (a.last_sale.created_date > b.last_sale.created_date) return -1;
      return 0
    }))
  }

  function openModal(_type) {
    setIsOpen(_type);
  }

  function closeModal(){
    setIsOpen(false);
  }

  const addWalletClicked = () => {
    setWalletConnected(true)
    closeModal()
    initializeOpensea()
  }

  const qrFoundUrl = (url) => {
    console.log(url)
    setShowQr(false)
    // remove query params from url. opensea sdk doesn't like it
    url = url.split('?')[0]
    setUrl(url)
    getUrlInfo(url)
  }

  const getUrlInfo = (_url) => {
    if (!url && (!_url || typeof(_url) === 'object')) {
      return
    }

    if (!_url || typeof(_url) == 'object') {
      _url = url
    }

    console.log(_url)
    // https://gllry.st/nft/0x56b0935405221e99bf14a358b9bc1e6c911042b8/25
    const parts = _url.split('/')
    const tokenAddress = parts[parts.length - 2]
    const tokenId = parts[parts.length - 1]
    getAsset(seaport, tokenAddress, tokenId).then(asset => {
      setAsset(asset)
      console.log(asset)
    })
  }

  const buyAssetPreview = () => {
    getOrder(seaport, asset.tokenAddress, asset.tokenId).then(order => {
      setOrder(order)
      openModal('purchase-preview')
    })
  }

  const buyAsset = () => {
    openModal('purchasing')
    purchaseAsset(seaport, asset.tokenAddress, asset.tokenId).then(transactionHash => {
      console.log('SUCCESS: ' + transactionHash)
      console.log('view on etherscan: ' + transactionHash)
      console.log('view on opensea: LINK HERE')
      setTransactionHash(transactionHash)
      // TODO: modal that shows link to etherscan, css spinner
      // subscribe to events. wait for confirmed transaction event and change text in modal
      const walletAddress = seaport.web3.currentProvider.addresses[0]
      asset.owner.address = walletAddress
      nfts.unshift(asset)
      getBalance(walletAddress)
      openModal('purchase_complete')
      // setError('purchase complete! view on etherscan: https://rinkeby.etherscan.io/tx/' + transactionHash)
    })
  }

  const handleScan = (data) => {
    console.log(data)
    if (data) {
      setShowQr(false)
    }
  }

  const handleError = (err) => {
    console.error(err)
  }

  // WEB3

  const getBalance = (address) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL))
    web3.eth.getBalance(address, function(err, result) {
      if (err) {
        console.log(err)
      } else {
        const balance = web3.utils.fromWei(result, "ether") + " ETH"
        console.log(balance)
        setBalance(balance)
      }
    })
  }

  // OPENSEA SDK SPECIFIC METHODS

  const _handleOpenseaError = (err) => {
    console.error(err)
    setError(err.message)
  }

  const getAsset = async (seaport, tokenAddress, tokenId) => {
    setError('')
    try {
      const asset: OpenSeaAsset = await seaport.api.getAsset({
        tokenAddress, // string
        tokenId, // string | number | null
      })
      return asset
    } catch (err) {
      _handleOpenseaError(err)
    }
  }

  const getOrder = async (seaport, tokenAddress, tokenId) => {
    try {
      const ordersData = await seaport.api.getOrders({ side: OrderSide.Sell, asset_contract_address: tokenAddress, token_id: tokenId })
      console.log(ordersData.orders)
      const orders = ordersData.orders.sort((a, b) => {
        if (a.basePrice.toString() > b.basePrice.toString()) return 1;
        if (a.basePrice.toString() < b.basePrice.toString()) return -1;
        return 0;
      })
      return orders[0]
    } catch (err) {
      _handleOpenseaError(err)
    }
  }

  const purchaseAsset = async (seaport, tokenAddress, tokenId) => {
    if (!order) {
      console.log('no order present to complete purchase')
      return
    }
    try {
      const accountAddress = seaport.web3.currentProvider.addresses[0]
      const transactionHash = await seaport.fulfillOrder({ order, accountAddress })
      console.log(transactionHash)
      return transactionHash
    } catch(err) {
      closeModal()
      _handleOpenseaError(err)
    }
  }

  const initializeOpensea = () => {
    try {
      const provider = new HDWalletProvider({
        mnemonic: { phrase: secret },
        providerOrUrl: PROVIDER_URL
      });

      setSeaport(new OpenSeaPort(
        provider,
        { networkName: Network.Rinkeby },
        (arg) => console.log(arg)
      ))

    } catch (err) {
      console.error(err)
      setSecret('')
      setWalletConnected(false)
      _handleOpenseaError(err)
      return
    }
  }

  const registerListeners = () => {
    seaport.addListener(EventType.TransactionCreated, ({ transactionHash, event }) => {
      console.info({ transactionHash, event, message: 'TransactionCreated' })
      setTransactionHash(transactionHash)
    })
    seaport.addListener(EventType.TransactionConfirmed, ({ transactionHash, event }) => {
      console.info({ transactionHash, event, message: 'TransactionConfirmed' })
    })
    seaport.addListener(EventType.TransactionDenied, ({ transactionHash, event }) => {
      console.info({ transactionHash, event, message: 'TransactionDenied' })
    })
    seaport.addListener(EventType.TransactionFailed, ({ transactionHash, event }) => {
      console.info({ transactionHash, event, message: 'TransactionFailed' })
    })
    seaport.addListener(EventType.InitializeAccount, ({ accountAddress }) => {
      console.info({ accountAddress, message: 'InitializeAccount' })
    })
    seaport.addListener(EventType.OrderDenied, ({ order, accountAddress }) => {
      console.info({ order, accountAddress, message: 'OrderDenied' })
    })
  }

  // END OPENSEA SDK SPECIFIC METHODS

  React.useEffect(() => {
    if (walletConnected) {
      const walletAddress = seaport.web3.currentProvider.addresses[0]
      getBalance(walletAddress)
      getAllAssets(walletAddress, null, sortAndSetNFTs)
      registerListeners()
    }
    return
  }, [walletConnected]);

  const walletAddress = walletConnected ? seaport.web3.currentProvider.addresses[0] : ''
  let buyButtonText = ''
  let buyButtonClass = 'button '
  let buttonAction = null
  if (asset) {
    if (asset.owner.address === walletAddress) {
      buyButtonText = 'you own this'
      buyButtonClass += 'disabled'
    } else if (asset.sellOrders.length > 0) {
      const sellOrders = asset.sellOrders.sort((a, b) => {
        if (a.basePrice.toString() > b.basePrice.toString()) return 1;
        if (a.basePrice.toString() < b.basePrice.toString()) return -1;
        return 0;
      })
      buyButtonText = `Buy for ${parseInt(sellOrders[0].basePrice.toString()) / 1000000000000000000} ETH`
      buyButtonClass += 'green'
      buttonAction = buyAssetPreview
    } else if (asset.sellOrders.length === 0) {
      buyButtonText = 'not for sale'
      buyButtonClass += 'disabled'
    }
  }

  const nftsDiv = []
  if (nfts && nfts.length) {
    nfts.forEach(nft => {
      const address = nft.assetContract ? nft.assetContract.address : nft.asset_contract.address;
      const middle = Math.floor(address.length / 2);
      const address_1 = address.substr(0, Math.min(middle, 8));
      const address_2 = address.substr(middle + 14);

      nftsDiv.push(
        <div key={(nft.id || 'newly purchased nft')} className='purchase__nft-container'>
          <div className='purchase__nft-container__image'><img src={(nft.image_thumbnail_url || nft.imageUrlThumbnail)} /></div>
          <div className='purchase__nft-container__name-container'>
            <div>{nft.name} - {nft.collection.name}</div>
            <div>{`${address_1}...${address_2}`} (Token #{(nft.token_id || nft.tokenId)})</div>
          </div>
        </div>
      )
    })
  }

  const addressAbbrv = (address) => {
    const middle = Math.floor(address.length / 2);
    const address_1 = address.substr(0, Math.min(middle, 8));
    const address_2 = address.substr(middle + 14);
    return `${address_1}...${address_2}`
  }

  return (
    <div className='purchase'>
      {!walletConnected &&
        <div className='button-container'>
          <div className='button green initial' onClick={() => openModal('wallet-connect')}>Begin Demo</div>
          {error &&
            <div style={{fontSize: '12px', color: 'red', marginTop: '12px'}}>{error}</div>
          }
        </div>
      }
      {walletConnected &&
        <div className='purchase__connected'>
          <div className='purchase__connected__address'>{addressAbbrv(walletAddress)}</div>
          <div className='purchase__connected__container'>
            <div className='purchase__connected__container__header'>rinkeby balance:</div>
            <div className='purchase__connected__container__balance'>{balance}</div>

            {(QR_SCANNING_ENABLED && showQr) &&
              <div className='purchase__connected__qr'>
                <QrReader foundUrl={qrFoundUrl} />
                <div className='purchase__close' onClick={() => setShowQr(false)}>Close</div>
              </div>
            }
            {!QR_SCANNING_ENABLED &&
              <div className='purchase__connected__url'>
                <div>Enter gllry/opensea nft url:</div>
                <input type='text' onChange={e => setUrl(e.target.value)} />
                <div onClick={getUrlInfo} className='button green'>Get Info</div>
                {error &&
                  <div style={{fontSize: '12px', color: 'red', marginTop: '12px'}}>{error}</div>
                }
              </div>
            }
            {asset &&
              <div className='purchase__asset-container'>
                <div><img src={asset.imageUrl} /></div>
                <div>{asset.name} - {asset.collection.name}</div>
                <div>{addressAbbrv(asset.assetContract.address)} (Token #{asset.tokenId})</div>
                <div onClick={buttonAction} className={buyButtonClass}>{buyButtonText}</div>
                <div className='purchase__close' onClick={() => setAsset(null)}>Close</div>
                {error &&
                  <div style={{fontSize: '12px', color: 'red', marginTop: '12px'}}>{error}</div>
                }
              </div>
            }

            <div className='purchase__connected__container__header'>nfts:</div>
            <div>{nftsDiv}</div>
          </div>
          {!showQr &&
            <div className='button green floating' onClick={() => setShowQr(true)} ><img src='/qr_black.svg' /></div>
          }
        </div>
      }
      <Modal
        isOpen={modalIsOpen == 'wallet-connect'}
        onRequestClose={closeModal}
        className='CustomModal'
      >
        <div className='modal'>
          <div className='form'>
            <div className='form__header'>Set up wallet</div>
            <div className='form__graybox'>Demo uses Rinkeby (Ethereum) testnet</div>
            <div className='form__input-container'>
              <div className='form__input-container__header'>Enter seed phrase</div>
              <div><textarea type='text' value={secret} onChange={e => setSecret(e.target.value)} /></div>
              <div className='button green small' onClick={addWalletClicked}>Continue</div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen == 'purchase-preview'}
        onRequestClose={closeModal}
        className='CustomModal'
      >
        <div className='modal preview'>
          <div className='form'>
            <div className='form__header'>Confirm Purchase</div>
            {order &&
              <div>
                <div className='form__item'>
                  <img src={order.asset.imageUrl} />
                  <div>
                    <div className='form__item__name bold'>{order.asset.name}</div>
                    <div className='form__item__name'>{order.asset.assetContract.name}</div>
                    <div className='form__item__name'>{addressAbbrv(order.asset.assetContract.address)} (Token #{order.asset.tokenId})</div>
                  </div>
                </div>
                <div className='form__item-price'>
                  <div className='form__item-price__container'>
                    <span className='form__item-price__header'>list price</span>
                    <span>{parseInt(order.basePrice.toString()) / 1000000000000000000} ETH</span>
                  </div>
                  <div className='form__item-price__container'>
                    <span className='form__item-price__header'>gas cost (est.)</span>
                    <span>{Math.floor(Math.random() * 500) / 1000000} ETH</span>
                  </div>
                </div>
                <div onClick={buyAsset} className={'green button small'}>Buy NFT</div>
              </div>
            }
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen == 'purchasing'}
        onRequestClose={closeModal}
        className='CustomModal'
      >
        <div className='modal'>
          <div className='form'>
            <div className='form__header'>Purchasing...</div>
            <div className="loader">Loading...</div>
            <div className='form__subtext'>Your transaction is pending on the Ethereum blockchain...</div>
            {transactionHash &&
              <div className='form__subtext link'><a target="_blank" href={'https://rinkeby.etherscan.io/tx/' + transactionHash}>View on Etherscan</a></div>
            }
            <br/>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen == 'purchase_complete'}
        onRequestClose={closeModal}
        className='CustomModal'
      >
        <div className='modal'>
          <div className='form'>
            <div className='form__header'>Success!</div>
            {order &&
              <div className='form__item'>
                <img src={order.asset.imageUrl} />
                <div>
                  <div className='form__item__name bold'>{order.asset.name}</div>
                  <div className='form__item__name'>{order.asset.assetContract.name}</div>
                  <div className='form__item__name'>{addressAbbrv(order.asset.assetContract.address)} (Token #{order.asset.tokenId})</div>
                </div>
              </div>
            }
            <div className='form__subtext'>Your purchase has been confirmed on the Ethereum blockchain.</div>
            {transactionHash &&
              <div className='form__subtext link'><a target="_blank" href={'https://rinkeby.etherscan.io/tx/' + transactionHash}>View on Etherscan</a></div>
            }
            <br/>
            <div className='button green' onClick={closeModal}>Return to wallet</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Purchase;
