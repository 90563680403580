import React from 'react';
import {useDispatch} from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';

import ProfileHeader from '../../../components/ProfileHeader';
import ThemeAwareIcon from '../../../components/ThemeAwareIcon';

import {updateUserPhoto} from '../../../redux/user';

import {getImageUrl} from '../../../services/utils';

import AdminPageEditProfileModal from './AdminPageEditProfileModal';

const FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

const AdminPageHeader = ({user}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const openModal = React.useCallback(() => setModalIsOpen(true), []);
  const closeModal = React.useCallback(() => setModalIsOpen(false), []);

  const dispatch = useDispatch();

  const [userPhoto, setUserPhoto] = React.useState(
    user && user.profile_picture_url,
  );
  const [error, setError] = React.useState('');

  const onAvatarClick = React.useCallback(
    () => document.getElementById('file-upload').click(),
    [],
  );

  const uploadImgToS3 = React.useCallback(
    (e) => {
      setError('');
      const file = e.target.files[0];
      if (!file) {
        setError('An error occurred. Please try again.');
        return;
      }
      if (FILE_TYPES.indexOf(file.type) === -1) {
        setError('Photo must be of type PNG, JPG, or GIF');
        return;
      }

      dispatch(
        updateUserPhoto(
          file,
          () => {
            console.log('photo uploaded');
          },
          () => {
            console.error('photo upload failure');
            setError('Error uploading photo. Please try again.');
          },
        ),
      );
      setUserPhoto(file);
    },
    [dispatch],
  );

  return (
    <>
      <input id="file-upload" type="file" onChange={uploadImgToS3} />
      <ProfileHeader
        avatarSrc={getImageUrl(userPhoto, '/profile.svg')}
        avatarTooltipTitle={
          userPhoto
            ? 'Click to update profile photo'
            : 'Click to upload profile photo'
        }
        button={
          <Tooltip title="Edit profile" aria-label="add">
            <ThemeAwareIcon
              alt="edit"
              lightModeIconSrc="/edit-light.svg"
              darkModeIconSrc="/edit-dark.svg"
              onClick={openModal}
            />
          </Tooltip>
        }
        error={error}
        onAvatarClick={onAvatarClick}
        username={user.display_name || user.username}
      />
      {modalIsOpen && <AdminPageEditProfileModal closeModal={closeModal} />}
    </>
  );
};

export default AdminPageHeader;
