import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect, useRouteMatch} from 'react-router-dom';

import {getAddresses, removeGuest} from '../../redux/crypto';

import {
  useAddClassForLightMode,
  getNFTsForCollection,
} from '../../services/utils';

import CopyButton from '../../components/CopyButton';
import GllryMetaTags from '../../components/GllryMetaTags';
import NFTsList from '../../components/NFTsList';

import './styles.scss';

const CollectionPage = () => {
  const addClassForLightMode = useAddClassForLightMode();
  const dispatch = useDispatch();
  const {
    params: {username, collection_id},
  } = useRouteMatch();

  const user = useSelector((state) => state.User.user);
  const guestUser = useSelector((state) => state.Crypto.guest.user);

  const isGuestView =
    username !== 'app' && (!user || user.username !== username);

  const [addressesLoaded, setAddressLoaded] = React.useState(!isGuestView);

  const collection = useSelector((state) =>
    (isGuestView
      ? state.Crypto.guest.newCollections
      : state.Crypto.newCollections
    ).find((c) => c.id === collection_id),
  );
  const nfts = useSelector((state) =>
    isGuestView ? state.Crypto.guest.nfts : state.Crypto.nfts,
  );
  const collectionNFTs = useSelector((state) =>
    isGuestView
      ? state.Crypto.guest.collectionNFTs
      : state.Crypto.collectionNFTs,
  );
  const collectionNfts = React.useMemo(
    () =>
      getNFTsForCollection((collection && collection.id), nfts, collectionNFTs).filter(
        (n) => n.enabled,
      ),
    [collection, nfts, collectionNFTs],
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (isGuestView) {
      if (!addressesLoaded) {
        dispatch(getAddresses(username, () => setAddressLoaded(true)));
      }
    } else {
      dispatch(removeGuest());
    }
  });

  if (username === 'app' || (collection && !collection.enabled)) {
    return <Redirect to="/" />;
  }

  return collection ? (
    <div className="CollectionPage">
      <GllryMetaTags
        title={`${collection.name} | GLLRY`}
        description={collection.description}
        url={window.location.href}
        imageUrl="/splash1.png"
      />
      <div className="CollectionPage__name-container">
        <Link to={`/${username}`}>
          <span className="CollectionPage__name-container__username">
            {isGuestView && guestUser
              ? guestUser.display_name || guestUser.username
              : user
              ? user.display_name || user.username
              : '(unsaved profile)'}
          </span>
        </Link>
        <span className="CollectionPage__name-container__name">
          <span>{collection.name}</span>
          <CopyButton />
        </span>
      </div>
      {collection.description && (
        <div className={addClassForLightMode('CollectionPage__description')}>
          {collection.description}
        </div>
      )}
      <div className="CollectionPage__list">
        <NFTsList
          collectionId={collection.id}
          nfts={collectionNfts}
          username={username}
        />
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default CollectionPage;
