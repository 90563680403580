import React from 'react';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import {useTheme} from '@material-ui/core/styles';

const TablePaginationActions = ({count, page, rowsPerPage, onPageChange}) => {
  const theme = useTheme();

  const maxPage = React.useMemo(
    () => Math.max(0, Math.ceil(count / rowsPerPage) - 1),
    [count, rowsPerPage],
  );

  const handleFirstPageButtonClick = React.useCallback((event) =>
    onPageChange(event, 0),
  );

  const handleBackButtonClick = React.useCallback((event) =>
    onPageChange(event, page - 1),
  );

  const handleNextButtonClick = React.useCallback((event) =>
    onPageChange(event, page + 1),
  );

  const handleLastPageButtonClick = React.useCallback((event) =>
    onPageChange(event, maxPage),
  );

  return (
    <div className="NFTsTablePaginationActions">
      <IconButton
        size="small"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        size="small"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <span>
        Page {page + 1} of {maxPage + 1}
      </span>
      <IconButton
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= maxPage}
        aria-label="next page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        size="small"
        onClick={handleLastPageButtonClick}
        disabled={page >= maxPage}
        aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

export default TablePaginationActions;
