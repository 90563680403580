import React from 'react';
import ReactMarkdown from 'react-markdown';

import CopyButton from '../../../components/CopyButton';

import {
  addClassIfTrue,
  requestFullscreen,
  useAddClassForLightMode,
} from '../../../services/utils';

import GllryMetaTags from '../../../components/GllryMetaTags';
import ThemeAwareIcon from '../../../components/ThemeAwareIcon';

import NFTPageBreadcrumb from './NFTPageBreadcrumb';
import NFTPageNotes from './NFTPageNotes';

import './styles.scss';

const NFTPageItem = ({
  allowFullscreen,
  className,
  collection,
  description,
  descriptionAdditional,
  imageUrl,
  linkDiv,
  listingPriceText = 'Current price',
  listingPrice,
  mediaDiv,
  mintDate,
  mintDateText = 'Mint date',
  mintDateDarkModeIcon,
  mintDateLightModeIcon,
  name,
  nft,
  offerPrice,
  offerPriceText = 'Highest offer',
  floorPrice,
  floorPriceCurrency,
  simpleDescription,
  traits,
  username,
}) => {
  const addClassForLightMode = useAddClassForLightMode();
  const [showDescription, setShowDescription] = React.useState(false);

  const toggleDescription = React.useCallback(() => {
    setShowDescription((prevState) => !prevState);
  }, []);

  return (
    <div className={`NFTPageItem ${className || ''}`}>
      <GllryMetaTags
        title={`${name} | GLLRY`}
        description={description}
        url={window.location.href}
        imageUrl={imageUrl}
      />
      <NFTPageBreadcrumb />
      <div className="NFTPageItem__container">
        <div
          className={`NFTPageItem__main ${className || ''}`}
          onClick={allowFullscreen ? requestFullscreen : null}>
          <div className="NFTPageItem__main__media-container">
            {mediaDiv}
            {allowFullscreen && (
              <img
                className="NFTPageItem__main__fullscreen"
                src="/fullscreen.svg"
                alt="fullscreen"
              />
            )}
          </div>
        </div>
        <div className="NFTPageItem__sub">
          {collection && (
            <>
              <span
                className={addClassForLightMode('NFTPageItem__collectionName')}>
                {collection.name}
                {collection.description && (
                  <ThemeAwareIcon
                    onClick={toggleDescription}
                    className="NFTPageItem__collectionName__info"
                    lightModeIconSrc="/info-light.svg"
                    darkModeIconSrc="/info-dark.svg"
                    alt="Show Description"
                  />
                )}
              </span>
              <div
                className={addClassIfTrue(
                  addClassForLightMode('NFTPageItem__collectionDescription'),
                  'NFTPageItem__collectionDescription--active',
                  showDescription,
                )}>
                <ReactMarkdown>{collection.description}</ReactMarkdown>
              </div>
            </>
          )}
          <div className="NFTPageItem__name">
            <span>{name}</span>
            {/*<CopyButton />*/}
          </div>
          {username && <div className="NFTPageItem__username">{username}</div>}
          {description && (
            <div className="NFTPageItem__description">
              {simpleDescription ? (
                <div dangerouslySetInnerHTML={{__html: description}}></div>
              ) : (
                <ReactMarkdown>{description}</ReactMarkdown>
              )}
              {descriptionAdditional}
            </div>
          )}
          {traits && traits.length > 0 && (
            <div className={addClassForLightMode('NFTPageItem__traits')}>
              {traits.map((t, i) => {
                const isString = typeof t === 'string';

                return !(isString && t === '') ? (
                  <div
                    key={`trait_${i}`}
                    className={`trait ${isString ? 'tag' : ''}`}>
                    <span className="semi-bold">
                      {isString ? t : t.trait_type + ':'}
                    </span>
                    {!isString && (
                      <span
                        className={addClassIfTrue(
                          'trait__value',
                          'trait__value--noCapitalize',
                          typeof t.value === 'string' &&
                            t.value.includes('http'),
                        )}>
                        {t.value}
                      </span>
                    )}
                  </div>
                ) : null;
              })}
            </div>
          )}
          {mintDate &&
            <div className="NFTPageItem__url">
              <ThemeAwareIcon
                darkModeIconSrc={mintDateDarkModeIcon}
                lightModeIconSrc={mintDateLightModeIcon}
                alt="mint date"
              />
              <>
                {mintDateText && <span>{mintDateText}: </span>}
                {mintDate}
              </>
            </div>
          }
          {listingPrice &&
            <div className="NFTPageItem__url">
              <ThemeAwareIcon
                darkModeIconSrc="/price-dark.svg"
                lightModeIconSrc="/price-light.svg"
                alt="listing price"
              />
              <span>{listingPriceText}: </span>
              {listingPrice}
            </div>
          }
          {offerPrice &&
            <div className="NFTPageItem__url">
              <ThemeAwareIcon
                darkModeIconSrc="/offer-dark.svg"
                lightModeIconSrc="/offer-light.svg"
                alt="listing price"
              />
              <span>{offerPriceText}: </span>
              {offerPrice}
            </div>
          }
          {(floorPrice && floorPriceCurrency && floorPrice !== '0') &&
            <div className="NFTPageItem__url">
              <ThemeAwareIcon
                darkModeIconSrc="/floor-dark.svg"
                lightModeIconSrc="/floor-light.svg"
                alt="floor price"
              />
              <span>Floor price: </span>
              {floorPrice} {floorPriceCurrency}
            </div>
          }
          {/*{collection && collection.stats && collection.stats.floor_price > 0 &&
            <div className="NFTPageItem__url">
              <ThemeAwareIcon
                darkModeIconSrc='/floor-dark.svg'
                lightModeIconSrc='/floor-light.svg'
                alt="floor price"
              />
              <span>Floor price: </span>
              {collection.stats.floor_price} ETH
            </div>
          }*/}
          {/*isERC1155() && <div className='NFTPage__url'><img src={`/eth2.svg`} /><span>Token type: </span>ERC-1155</div>*/}
          {/*<div className="NFTPageItem__url">
            <ThemeAwareIcon
              darkModeIconSrc="/qr-dark.svg"
              lightModeIconSrc="/qr-light.svg"
              alt="link"
            />
            {linkDiv}
          </div>*/}
          <NFTPageNotes nft={nft} />
        </div>
      </div>
    </div>
  );
};

export default NFTPageItem;
