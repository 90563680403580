import React from 'react';

import './styles.scss';

const Button = ({
  className = '',
  text,
  pendingText,
  onClick,
  state = 'default',
  type,
}) => {
  const [buttonState, setButtonState] = React.useState(state);

  React.useEffect(() => {
    setButtonState(state);
  }, [state]);

  const buttonClicked = () => {
    if (buttonState === 'pending' || buttonState === 'disabled') {
      return;
    }

    if (pendingText) {
      setButtonState('pending');
    }

    onClick && onClick();
  };

  let buttonText = text;
  let buttonClasses;

  if (buttonState === 'pending') {
    buttonText = pendingText;
    buttonClasses = 'pending';
  } else if (buttonState !== 'secondary') {
    buttonClasses = 'green';
  }

  return (
    <button
      className={`Button ${className} ${buttonClasses}`}
      onClick={buttonClicked}
      type={type}
      disabled={buttonState === 'disabled'}>
      {buttonText}
    </button>
  );
};

export default Button;
