import React from 'react';

import Button from '../Button';
import TextButton from '../TextButton';

import './styles.scss';

const ModalButtons = ({
  buttonText,
  buttonClassName,
  buttonPendingText,
  buttonState,
  buttonType = 'submit',
  buttonOnClick,
  closeModal,
}) => (
  <div className="ModalButtons">
    <Button
      className={buttonClassName}
      text={buttonText}
      pendingText={buttonPendingText}
      state={buttonState}
      onClick={buttonOnClick}
      type={buttonType}
    />
    {closeModal && <TextButton onClick={closeModal}>Cancel</TextButton>}
  </div>
);

export default ModalButtons;
