import React from 'react';
import {useDispatch} from 'react-redux';

import {login} from '../../../redux/user';

import TextButton from '../../TextButton';
import CustomModal from '../../CustomModal';
import ModalButtons from '../../ModalButtons';
import FormInput from '../../Form/FormInput';

import '../../components.scss';

const LayoutHeaderLoginModal = ({closeModal, openForgotPasswordModal}) => {
  const [buttonState, setButtonState] = React.useState('default');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [validationMsg, setValidationMsg] = React.useState('');

  const dispatch = useDispatch();

  const onFailure = (msg) => {
    setButtonState('default');
    setValidationMsg(msg);
  };

  const handleSubmit = React.useCallback(() => {
    const _username = username.trim();

    if (_username.length === 0) {
      setValidationMsg('Please enter a username');
      return;
    }
    if (_username.length < 3) {
      setValidationMsg('Username must be at least 3 characters');
      return;
    }
    // if (_username.match(USERNAME_REGEX)) {
    //   setValidationMsg("Only letters, numbers, underscores, and hyphens are allowed in the username")
    //   return
    // }
    if (password.length === 0) {
      setValidationMsg('Please enter a password');
      return;
    }
    setButtonState('pending');
    dispatch(login(username, password, closeModal, onFailure));
  }, [username, password, closeModal, dispatch]);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <CustomModal
      title="Login"
      titleButton={
        <TextButton onClick={openForgotPasswordModal}>
          I forgot my password.
        </TextButton>
      }
      onRequestClose={closeModal}>
      <form onSubmit={onSubmit}>
        <FormInput
          autoFocus
          label="Username"
          onChange={setUsername}
          value={username}
        />
        <FormInput
          label="Password"
          onChange={setPassword}
          type="password"
          value={password}
        />
        <ModalButtons
          buttonText="login"
          buttonPendingText="logging in..."
          buttonState={buttonState}
          closeModal={closeModal}
        />
        {validationMsg && (
          <div className="form__validation">{validationMsg}</div>
        )}
      </form>
    </CustomModal>
  );
};

export default LayoutHeaderLoginModal;
