import React from 'react';
import {useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux'

import {addClassIfTrue, useAddClassForLightMode} from '../../services/utils';

import LayoutHeader from './LayoutHeader';

import './styles.scss';

const EVENTS = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "touchstart"
];

const Layout = ({children}) => {
  const location = useLocation();
  const addClassForLightMode = useAddClassForLightMode();

  const user = useSelector((state) => state.User.user);
  const kioskModeEnabled = useSelector(state => state.User.kioskModeEnabled)

  // timer logic to track inactivity. after 30 seconds, reset page back to home
  let timer;
  const handleInactivityTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(EVENTS).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      inactivityAction();
    }, 7*30000); // 30secs * 7 = 3.5 min
  }
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  }
  const inactivityAction = React.useCallback(() => {
    if (!kioskModeEnabled) return;
    window.scrollTo(0,0)
    if (user && window.location.pathname !== `/${user.username}`) {
      window.location.pathname = "/";
    }
  }, [kioskModeEnabled])

  React.useEffect(() => {
    Object.values(EVENTS).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleInactivityTimer();
      });
    });
  }, []);
  // end timer logic


  return (
    <div
      className={addClassIfTrue(
        addClassForLightMode('Layout__wrapper'),
        'Layout__wrapper--isLandingPage',
        ['/', '/reset_password'].includes(location.pathname),
      )}>
      <div className="Layout">
        <LayoutHeader />
        <div className="Layout__body">{children}</div>
        <div className="Layout__footer">© Satoshi's Closet 2023</div>
      </div>
    </div>
  );
};

export default Layout;
