import React from 'react';

import TablePagination from '@material-ui/core/TablePagination';

import NFTsTablePaginationActions from './NFTsTablePaginationActions';

import './styles.scss';

const NFTsTablePagination = ({
  numRows,
  onPageChange,
  onRowsPerPageChange,
  pageIndex,
  rowsPerPage,
}) => (
  <TablePagination
    component="div"
    className="NFTsTablePagination"
    rowsPerPageOptions={[25, 50, 100, {label: 'All', value: numRows}]}
    colSpan={7}
    count={numRows}
    labelRowsPerPage="Show per page:"
    rowsPerPage={rowsPerPage}
    page={pageIndex}
    SelectProps={{
      inputProps: {'aria-label': 'show per page'},
      native: true,
    }}
    onPageChange={onPageChange}
    onRowsPerPageChange={onRowsPerPageChange}
    ActionsComponent={NFTsTablePaginationActions}
  />
);

export default NFTsTablePagination;
