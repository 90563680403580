import Web3 from 'web3';

import {validate} from 'multicoin-address-validator';

import {TezosToolkit} from '@taquito/taquito';
import {Tzip16Module} from '@taquito/tzip16';
import {TaquitoTezosDomainsClient} from '@tezos-domains/taquito-client';

import {validateAddress} from '../services/solana';

const AVAILABLE_COINS = ['eth', 'sol', 'btc', 'xtz'];

const PROVIDER = new Web3.providers.WebsocketProvider(
  'wss://mainnet.infura.io/ws/v3/' + process.env.REACT_APP_INFURA_PROJECT_ID,
);
const web3 = new Web3(PROVIDER);

export const getCoinTypeFromAddress = (address) => {
  const coinType = AVAILABLE_COINS.find((coin) => validate(address, coin));
  if (coinType) {
    return coinType
  }
  return null
}

export const getCoinTypeFromAlias = (alias) => {
  if (alias.endsWith('eth')) {
    return ['eth', 'eth'];
  } else if (alias.endsWith('tez')) {
    return ['tez', 'xtz'];
  }
};

export const getEthAddress = (eth) =>
  web3.eth.ens.getOwner(eth).then((address) =>
    address && !address.startsWith('0x0000000') ? address : '',
  );

export const getTezAddress = (tez) => {
  const tezos = new TezosToolkit('https://rpc.tzbeta.net/');
  const client = new TaquitoTezosDomainsClient({network: 'mainnet', tezos});

  tezos.addExtension(new Tzip16Module());

  return client.resolver.resolveNameToAddress(tez);
};

export const addressAbbrv = (address, index1 = 8, index2 = 14) => {
  const middle = Math.floor(address.length / 2);
  const address_1 = address.substr(0, Math.min(middle, index1));
  const address_2 = address.substr(middle + index2);
  return `${address_1}...${address_2}`
};
