import MetaTags from 'react-meta-tags';

const GllryMetaTags = (props) => {
  const {
    title = 'GLLRY: The most versatile NFT gallery',
    description = "The best web gallery for serious NFT collectors. Ethereum, Tezos, TopShot and more coming soon. GLLRY is made by Satoshi's Closet",
    url = 'https://gllry.st',
    imageUrl = 'https://gllry.st/splash1.png',
  } = props;

  return (
    <MetaTags>
      <meta charSet="UTF-8" />
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={url} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:card" content={imageUrl} />
    </MetaTags>
  );
};
export default GllryMetaTags;
