import React, { useState, useEffect } from 'react';

import QRReader from '../../services/qrscan'

export default function QrReader(props) {
  useEffect(() => {
    window.iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
    window.isMediaStreamAPISupported = navigator && navigator.mediaDevices && 'enumerateDevices' in navigator.mediaDevices;
    window.noCameraPermission = false;

    var frame = null;

    window.appOverlay = document.querySelector('.app__overlay');

    //Initializing qr scanner
    QRReader.init(); //To initialize QR Scanner
    // Set camera overlay size
    setTimeout(() => {
      setCameraOverlay();
      if (window.isMediaStreamAPISupported) {
        scan();
      }
    }, 1000);

    // To support other browsers who dont have mediaStreamAPI
    // selectFromPhoto();

    function setCameraOverlay() {
      // window.appOverlay.style.borderStyle = 'solid';
    }

    function createFrame() {
      frame = document.createElement('img');
      frame.src = '';
      frame.id = 'frame';
    }

    //Scan
    function scan(forSelectedPhotos = false) {
      QRReader.scan((result) => {
        props.foundUrl(result)
      }, forSelectedPhotos);
    }

    return () => { QRReader.destroy() }
  })
  return (
    <div className="app__layout">
      <main className="app__layout-content">
        <video autoPlay controls={false}></video>
        <div className="app__dialog-overlay app__dialog--hide"></div>
        <div className="app__infodialog-overlay app__infodialog--hide"></div>
      </main>
    </div>
  )
}
