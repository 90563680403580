import React from 'react';
import {useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';

import {isRizzle, isBitBasel, isGGallery} from '../../services/utils';

import './styles.scss';

const LandingPage = () => {
  const user = useSelector((state) => state.User.user);

  if (isRizzle()) {
    return (<Redirect to="/rizzle" />)
  } else if (isBitBasel()) {
    return (<Redirect to="/bitbasel_2021" />)
  }
  else if (isGGallery()) {
    return (<Redirect to="/g-gallery" />)
  }

  return user ?
  (<Redirect to={`/${user.username}`} />)
  :
  (
    <div className="LandingPage">
      <div className="LandingPage__section padding">
        <div className="LandingPage__image">
          <img
            className="LandingPage__image__mobile"
            src="splash1_mobile.png"
            alt="Splash 1"
          />
        </div>
        <div className="LandingPage__section__container">
          <div className="LandingPage__header primary">
            Display, organize, & manage
            <br />
            your entire NFT collection -
          </div>
          <div className="LandingPage__header primary mobile">
            Display, organize, & manage your entire NFT collection -
          </div>
          <div className="LandingPage__subheader italic">
            *without* connecting to MetaMask.
          </div>
          <div className="LandingPage__subtext">
            Multi-wallet, multi-chain, and mobile friendly.
          </div>
          <div className="LandingPage__button-container">
            <div className="LandingPage__button-container__logos-container">
              <img src={`/eth-dark.svg`} alt={`eth logo`} />
              <img src={`/xtz-dark.svg`} alt={`xtz logo`} />
              <img src={`/sol-dark.svg`} alt={`sol logo`} />
              <Link to="/purchase"><img src={`/flow-dark.svg`} alt={`top shot logo`} /></Link>
            </div>
            <Link to="/app">
              <div className="button green wide">preview your gllry</div>
            </Link>
          </div>
        </div>
        <div className="LandingPage__image">
          <img
            className="LandingPage__image__desktop png"
            src="splash1.png"
            alt="Splash 1"
          />
        </div>
      </div>
      <img
        className="LandingPage__divider"
        src="homepage-curve-divider.png"
        alt="divider"
      />
      <div className="LandingPage__section column wide hide">
        <div className="LandingPage__section__featured-header">
          Featured Profiles
        </div>
        <div className="LandingPage__section__featured-profiles">
          <div className="LandingPage__section__featured-profiles__profile">
            <img src="/splash_featured.svg" alt="featured" />
            <div className="LandingPage__section__featured-profiles__profile__footer">
              <span>Sgt_Slaughtermelon</span>
              <span className="LandingPage__section__featured-profiles__profile__footer__link">
                View
              </span>
            </div>
          </div>
          <div className="LandingPage__section__featured-profiles__profile">
            <img src="/splash_featured.svg" alt="featured" />
            <div className="LandingPage__section__featured-profiles__profile__footer">
              <span>RareScrilla</span>
              <span className="LandingPage__section__featured-profiles__profile__footer__link">
                View
              </span>
            </div>
          </div>
          <div className="LandingPage__section__featured-profiles__profile">
            <img src="/splash_featured.svg" alt="featured" />
            <div className="LandingPage__section__featured-profiles__profile__footer">
              <span>nftranch</span>
              <span className="LandingPage__section__featured-profiles__profile__footer__link">
                View
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="LandingPage__section white">
        <div className="LandingPage__section__inner">
          <div className="LandingPage__header padding">
            Display NFTs from multiple wallet addresses across multiple
            blockchains.
          </div>
          <div className="LandingPage__subheader white">
            GLLRY currently supports Ethereum, Tezos, Solana, and NBA TopShot.
          </div>
          <img
            className="LandingPage__subheader__cryptos"
            src={`/coins-light.svg`}
            alt={`Supported cryptos`}
          />
          <img
            className="LandingPage__image__desktop png"
            src={`/splash_nfts.png`}
            alt={`Example NFTs`}
          />
          <img
            className="LandingPage__image__mobile"
            src={`/splash_nfts_mobile.png`}
            alt={`Example NFTs`}
          />
          <div className="LandingPage__section__inner__list">
            <div className="LandingPage__section__inner__list__header">
              Yes:
            </div>
            <div className="LandingPage__section__inner__list__checklist">
              <div>
                <img src="/greenCheckmark.svg" alt="checkmark" />
                <span>
                  Add multiple wallet addresses from multiple blockchains
                </span>
              </div>
              <div>
                <img src="/greenCheckmark.svg" alt="checkmark" />
                <span>
                  Toggle visibility of individual NFT’s to display select pieces
                </span>
              </div>
              <div>
                <img src="/greenCheckmark.svg" alt="checkmark" />
                <span>
                  Organize your NFT’s into linkable cross-chain collections
                </span>
              </div>
            </div>
            <div className="LandingPage__section__inner__list__header">No:</div>
            <div className="LandingPage__section__inner__list__checklist">
              <div>
                <img src="/redX.svg" alt="x" />
                <span>Endless content feeds or distractions</span>
              </div>
              <div>
                <img src="/redX.svg" alt="x" />
                <span>'Likes', follower counts, or vanity metrics</span>
              </div>
              <div>
                <img src="/redX.svg" alt="x" />
                <span>Gatekeeping, leaderboards, or gimmicks</span>
              </div>
            </div>
            <div className="LandingPage__section__inner__list__header">
              use gllry to:
            </div>
            <div className="LandingPage__section__inner__list__checklist">
              <div>
                <img src="/greenCheckmark.svg" alt="checkmark" />
                <span>Flex on your friends at brunch</span>
              </div>
              <div>
                <img src="/greenCheckmark.svg" alt="checkmark" />
                <span>Create a collection of NFT’s you are trying to sell</span>
              </div>
              <div>
                <img src="/greenCheckmark.svg" alt="checkmark" />
                <span>Showcase an artist or curate a group gallery show</span>
              </div>
              <div>
                <img src="/greenCheckmark.svg" alt="checkmark" />
                <span>
                  Set up a retail kiosk and point-of-sale at live events
                </span>
              </div>
            </div>
          </div>
          <div className="LandingPage__button-container center top-spacing">
            <Link to="/app">
              <div className="button green">Try it now</div>
            </Link>
            <div className="LandingPage__button-container__subtext black">
              MetaMask and email address <b>not</b> required.
            </div>
          </div>
          <div
            style={{
              margin: '50px 0 0',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <img
              className="LandingPage__image__desktop"
              src="/splash3.png"
              alt="Splash 3"
            />
          </div>
        </div>
      </div>
      <div className="LandingPage__section mobile white">
        <img
          className="LandingPage__image"
          src="/splash3_mobile.png"
          alt="Splash 3"
        />
      </div>
      <img
        className="LandingPage__divider flipped"
        src="homepage-curve-divider.png"
        alt="divider"
      />
      <div className="LandingPage__section column">
        <div className="LandingPage__section__inner">
          <div className="LandingPage__header center padding">
            The cleanest, easiest way to showcase your CryptoArt and NFT
            collectibles.
          </div>
          <img
            className="LandingPage__image__desktop png"
            src="/splash2.png"
            alt="Splash 2"
          />
          <img
            className="LandingPage__image__mobile"
            src="/splash2_mobile.png"
            alt="Splash 2"
          />
          <div className="LandingPage__button-container center">
            <Link to="/app">
              <div className="button green _258">enter a wallet address</div>
            </Link>
            <div className="LandingPage__button-container__subtext">
              MetaMask and email address <b>not</b> required.
            </div>
          </div>
          <div className="LandingPage__section__contact">
            <div>
              Are you a gallerist, virtual builder, or collector interested in
              API products or custom integrations?
            </div>
            <a
              target="_blank"
              href="mailto:hi@satoshiscloset.com"
              rel="noreferrer">
              <div className="button">Contact Us</div>
            </a>
          </div>
        </div>
      </div>
      <div className="LandingPage__footer">
        GLLRY is made with 🤑🕴️ by{' '}
        <a target="_blank" href="https://satoshiscloset.com" rel="noreferrer">
          Satoshi’s Closet
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
