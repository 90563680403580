import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import ThemeAwareIcon from '../ThemeAwareIcon';

import './styles.scss';

const CopyButton = ({link}) => {
  const [tooltipTitle, setTooltipTitle] = React.useState('Copy');

  const copyToClipboard = React.useCallback(() => {
    navigator.clipboard.writeText(link || window.location.href);
    setTooltipTitle('Copied!');

    setTimeout(() => setTooltipTitle('Copy'), 1000);
  }, [link]);

  return (
    <Tooltip className="CopyButton" title={tooltipTitle} aria-label="add">
      <ThemeAwareIcon
        alt="copy to clipboard"
        lightModeIconSrc="/copy-light.svg"
        darkModeIconSrc="/copy-dark.svg"
        onClick={copyToClipboard}
      />
    </Tooltip>
  );
};

export default CopyButton;
