import React from 'react';

import './styles.scss';

const FormInputContainer = ({
  children,
  isValid,
  label,
  note,
  validationMessage,
}) => {
  const validationClassName = React.useMemo(
    () =>
      'FormInputContainer__validation ' +
      (isValid === true ? 'valid' : isValid === false ? 'invalid' : undefined),
    [isValid],
  );

  return (
    <div className="FormInputContainer">
      <div className="FormInputContainer__label">
        <span>{label}</span>
        {note && <div className="FormInputContainer__note">{note}</div>}
      </div>
      {children}
      {validationMessage &&
        (validationMessage.includes('://') ? (
          <a
            href={validationMessage}
            target="_blank"
            rel="noreferrer"
            className={validationClassName}>
            {validationMessage}
          </a>
        ) : (
          <div className={validationClassName}>{validationMessage}</div>
        ))}
    </div>
  );
};

export default FormInputContainer;
