import React, {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useAsyncDebounce} from 'react-table';
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';

import {addNFTs} from '../../redux/crypto';

import {
  getCoinTypeFromAddress,
  addressAbbrv
} from '../../services/addressUtils';

import { initializeOpensea } from '../../services/opensea'

import CustomModal from '../CustomModal';
import FormInput from '../Form/FormInput';
import ModalButtons from '../ModalButtons';
import TextButton from '../TextButton';

const SMART_CONTRACTS = {}

const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
)

const AddNftsByFileModal = ({openModal, closeModal, onAddSuccess}) => {
  const dispatch = useDispatch();

  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const [seaport, setSeaport] = useState(initializeOpensea())
  const [showManualContractEntry, setShowManualContractEntry] = useState(false)
  const [address, setAddress] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [addressIsValid, setAddressIsValid] = useState();
  const [addressValidationMessage, setAddressValidationMessage] = useState('');
  const [asset, setAsset] = useState(null);
  const [assets, setAssets] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const _getIpfsUrl = (url) => {
    return url.replace('ipfs://', process.env.REACT_APP_IPFS_GATEWAY)
  }

  const _isVideo = (url) => {
    return url.includes('.mp4') || url.includes('.mov')
  }

  const onSubmit = useCallback((ev) => {
    ev.preventDefault();
    if (assets.length) {
      const nfts = []
      assets.forEach(asset => {
        const nftJson = {
          id: `${asset.asset_contract.address}_${asset.token_id}`,
          name: asset.name,
          contract_address: asset.asset_contract.address,
          token_id: asset.token_id,
          image_url: asset.image_url || asset.animation_url || '',
          media_url: asset.animation_url,
          chain: 'eth'
        }
        nfts.push(nftJson)
      })
      dispatch(addNFTs(nfts, nftSaved))
    } else {
      console.error('no assets found to add')
    }
  }, [assets])

  const nftSaved = useCallback(() => {
    setAssets([])
    setSuccess('NFTs added!')
  }, [])

  // OPENSEA SPECIFIC METHODS

  const _getAssetsOpensea = async (contractAddress, tokenIds) => {
    try {
      const apiUrl = `https://api.opensea.io/api/v1/assets?limit=50&asset_contract_address=${contractAddress}&token_ids=${tokenIds.join('&token_ids=')}&format=json`;
      const headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
      const res = await fetch(apiUrl, { headers })
      const data = await res.json()
      return data.assets

    } catch (err) {
      setError('error gettings NFTs')
      setAssets([])
    }
  }

  const getAssets = async (data = {}) => {
    const contractAddresses = Object.keys(data)
    if (!contractAddresses.length) {
      return;
    }
    setError('')
    let allAssets = []
    for (const ca of contractAddresses) {
      const tokenIds = data[ca]
      if (!tokenIds.length) {
        return
      }
      let i = 0
      while (i < tokenIds.length) {
        const currentTokenIds = tokenIds.splice(i, i+30)
        const _assets = await _getAssetsOpensea(ca, currentTokenIds)
        allAssets = allAssets.concat(_assets)
      }
    }
    const sortedAssets = allAssets.sort((a,b) => {
      if (a.asset_contract.address > b.asset_contract.address) return 1;
      if (a.asset_contract.address < b.asset_contract.address) return -1;
      const a_token_id = parseInt(a.token_id)
      const b_token_id = parseInt(b.token_id)
      if (a_token_id > b_token_id) return 1
      if (a_token_id < b_token_id) return -1
      return 0
    })
    setAssets(sortedAssets)
  }

  const assetDiv = React.useMemo(() => {
    if (assets.length) {
      const div = []
      assets.forEach(asset => {
        const imageUrl = asset.image_url
        if (imageUrl && imageUrl.startsWith('ipfs://')) {
          imageUrl = _getIpfsUrl(imageUrl)
        }
        const videoUrl = asset.animation_url || (asset.image_url && _isVideo(asset.image_url) && asset.image_url)
        const assetContract = asset.asset_contract || {}
        div.push(
          <div key={`${assetContract.address}_${asset.token_id}`} className='form__item add-modal'>
            {videoUrl ? <video src={videoUrl} /> : <img src={imageUrl} />}
            <div>
              <div className='form__item__name bold'>{asset.name}</div>
              <div className='form__item__name'>{assetContract.name}</div>
              <div className='form__item__name'>{addressAbbrv(assetContract.address || '')} (Token #{asset.token_id})</div>
            </div>
          </div>
        )
      })
      return div
    }
    return null
  }, [assets])

  // END OPENSEA SPECIFIC METHODS

  return (
    <CustomModal
      title="Add NFTs via CSV"
      titleButton={
        <TextButton onClick={() => openModal('add-nft')}>
          Add Individual NFT
        </TextButton>
      }
      onRequestClose={closeModal}
    >
      <CSVReader
        onUploadAccepted={(results: any) => {
          const data = {}
          results.data.forEach(result => {
            const contractAddress = result[0]
            const tokenId = result[1]

            if (contractAddress && contractAddress.length && tokenId) {
              if (!data[contractAddress]) {
                data[contractAddress] = []
              }
              data[contractAddress].push(tokenId)
            }
          })
          console.log('---------------------------');
          console.log(data);
          console.log('---------------------------');
          getAssets(data)
          setZoneHover(false);
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
          Remove,
        }: any) => (
          <>
            <div
              {...getRootProps()}
              style={Object.assign(
                {},
                {
                  alignItems: 'center',
                  border: `2px dashed gray`,
                  borderRadius: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'center',
                  padding: 20,
                },
                zoneHover && { border: '2px dashed red' }
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={{marginRight: 5}}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event: Event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                'Drop CSV file here or click to upload'
              )}
            </div>
          </>
        )}
      </CSVReader>
      <form className='form' onSubmit={onSubmit}>
        {assets.length ?
          <>
            <br/>
            <span>Assets to import:</span>
            {assetDiv}
          </>
          :
          null
        }
        <ModalButtons
          buttonText="Add NFTs"
          buttonState={assets.length ? 'default' : 'disabled'}
          buttonPendingText="Adding..."
          closeModal={closeModal}
        />
        {success && <div className='FormInputContainer__validation valid'>{success}</div>}
      </form>
    </CustomModal>
  )
}

const styles = { }

export default AddNftsByFileModal;
