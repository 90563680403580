import React from 'react';

import {
  useAddClassForLightMode,
  addClassIfTrue,
} from '../../../../services/utils';

const LayoutHeaderMenuBurger = ({isOpen, toggleOpen, withDot}) => {
  const addClassForLightMode = useAddClassForLightMode();

  return (
    <div
      className={addClassIfTrue(
        addClassIfTrue(
          `${addClassForLightMode(
            'LayoutHeaderMenuBurger',
          )} LayoutHeaderMenu__hideDesktop`,
          'LayoutHeaderMenuBurger--isOpen',
          isOpen,
        ),
        'LayoutHeaderMenuBurger--withDot',
        withDot,
      )}
      onClick={toggleOpen}>
      <div />
      <div />
      <div />
    </div>
  );
};

export default LayoutHeaderMenuBurger;
