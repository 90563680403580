import React from 'react';

import CustomModal from '../../../components/CustomModal';
import ModalButtons from '../../../components/ModalButtons';

import './styles.scss';

const ProfilePageNftsLoadingModal = ({closeModal, isLoggedIn}) => (
  <CustomModal title="NFTs loading..." onRequestClose={closeModal}>
    {isLoggedIn ? (
      <div className="ProfilePageNftsLoadingModal__header">
        Visit the admin panel to:
      </div>
    ) : (
      <div className="ProfilePageNftsLoadingModal__header">
        Save your profile to:
      </div>
    )}
    <div className="ProfilePageNftsLoadingModal__bullets">
      <div>
        <strong> {'>'} </strong>
        <span>Set up custom collections</span>
      </div>
      <div>
        <strong> {'>'} </strong>
        <span>Add your NFTs to collections</span>
      </div>
    </div>
    <div className="ProfilePageNftsLoadingModal__note">
      You can also add more wallets to your profile, hide individual NFTs, and
      add creator or collector notes to each collection and individual piece.
    </div>
    <ModalButtons
      buttonText="Got it!"
      buttonOnClick={closeModal}
      type="default"
    />
  </CustomModal>
);

export default ProfilePageNftsLoadingModal;
