import update from 'immutability-helper';

import * as api from '../services/wlt';

import {getAddressesAuth} from './crypto';

/**
 * Constants
 */

const VERSION_STORE = 'VERSION_STORE';
const USER_STORE = 'USER_STORE';
const USER_KIOSK_MODE = 'USER_KIOSK_MODE'
export const USER_LOGOUT = 'USER_LOGOUT';

/*
  Actions logic
*/
/** Checks if there is a currently logged in user to the app. */
export function getVersion(onSuccess) {
  return (dispatch, getState) => {
    api.getVersion().then((data) => {
      const {version} = data;
      const currentVersion = getState().User.latestVersion;
      if (currentVersion && currentVersion !== version) {
        // currentVersion does not exist === legacy system (potentially old redux store)
        // clear localstorage and refresh to make sure user's redux store is most up to date
        dispatch(logout());
        dispatch({
          type: VERSION_STORE,
          payload: version,
        });
      }
      if (onSuccess) {
        onSuccess();
      }
    });
  };
}

export function checkLoggedIn() {
  return (dispatch) => {
    dispatch(
      getVersion(() => {
        const userId = localStorage.getItem('userId');
        const accessToken = localStorage.getItem('accessToken');

        if (userId !== null && accessToken !== null) {
          console.info(
            `looks like user is logged in as ${userId}, loading user data`,
          );
          dispatch({
            type: 'PROJECTS_LOADING',
            payload: true,
          });
          dispatch(userLoad());
        }
      }),
    );
  };
}

/** Loads a user id into the redux store. */
export function userLoad(user) {
  return (dispatch) => {
    if (user) {
      dispatch({
        type: USER_STORE,
        payload: {user},
      });
      dispatch(getAddressesAuth());
    } else {
      api.getUser().then((data) => {
        if (data.user) {
          dispatch({
            type: USER_STORE,
            payload: {
              user: data.user,
            },
          });
          dispatch(getAddressesAuth());
        } else {
          dispatch(logout());
        }
      });
    }
  };
}

export function register(username, password, email, onSuccess, onError) {
  return (dispatch, getState) => {
    api.register(username, password, email, getState().Crypto.addresses)
       .then((data) => {
        if (data.error) {
          console.error(data.error);
          if (onError) {
            onError(data.error);
          }
        } else if (data) {
          dispatch(saveLogin(data));

          if (onSuccess) {
            onSuccess(username);
          }
        }
      });
  };
}

export function saveLogin({access_token, user}, loadUser = true) {
  return (dispatch) => {
    localStorage.setItem('userId', user.id);
    localStorage.setItem('accessToken', access_token);

    if (loadUser) {
      dispatch(userLoad(user));
    }
  };
}

export function login(username, password, onSuccess, onError, save=true) {
  return (dispatch) => {
    api.login(username, password).then((data) => {
      if (data.error) {
        console.error(data.error);
        if (onError) {
          onError(data.error);
        }
      } else if (data) {
        if (save) {
          dispatch(saveLogin(data));
        }

        if (onSuccess) {
          onSuccess();
        }
      }
    });
  };
}

export function updateUserPhoto(file, onSuccess, onError) {
  return (dispatch) => {
    api.updateUserPhoto(file.type).then((data) => {
      if (data.error) {
        console.error(data.error);
        if (onError) {
          onError(data.error);
        }
      } else if (data) {
        const user = data.user;
        user.profile_picture_url = URL.createObjectURL(file);
        dispatch({
          type: USER_STORE,
          payload: {
            user: user,
          },
        });
        if (data.upload_url) {
          api.uploadPhoto(data.upload_url, file).then((data) => {
            if (onSuccess) {
              onSuccess();
            }
          });
        }
      }
    });
  };
}

export function updateUser(
  username,
  displayName,
  passwordOld,
  password,
  passwordConfirm,
  bio,
  onSuccess,
  onError,
) {
  return (dispatch) => {
    api
      .updateUser(username, displayName, passwordOld, password, passwordConfirm, bio)
      .then((data) => {
        if (data.error) {
          console.error(data.error);
          if (onError) {
            onError(data.error);
          }
        } else if (data) {
          dispatch(saveLogin(data));
          if (onSuccess) {
            onSuccess();
          }
        }
      });
  };
}

export function resetPassword(
  code,
  password,
  passwordConfirm,
  onSuccess,
  onError,
) {
  return () => {
    api.resetPassword(code, password, passwordConfirm).then((data) => {
      _handleData(data, onSuccess, onError);
    });
  };
}

export function forgotPassword(email, onSuccess, onError) {
  return () => {
    api.forgotPassword(email).then((data) => {
      _handleData(data, onSuccess, onError);
    });
  };
}

function _handleData(data, onSuccess, onError) {
  if (data.error) {
    console.error(data.error);
    if (onError) {
      onError(data.error);
    }
  } else if (data) {
    if (onSuccess) {
      onSuccess(data.success);
    }
  }
}

export const toggleKioskMode = (onSuccess) => (dispatch, getState) =>  {
  dispatch({
    type: USER_KIOSK_MODE
  })
  if (onSuccess) {
    onSuccess()
  }
}

/** Resets the db, clears redux, clears async storage. */
export function logout() {
  return (dispatch) => {
    dispatch({type: USER_LOGOUT});
    localStorage.clear();
  };
}

/*
  Reducer logic
*/
const initialState = {
  isLoggedIn: false,
  user: null,
  latestVersion: null,
  kioskModeEnabled: false
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case VERSION_STORE:
      return {
        ...state,
        latestVersion: action.payload,
      };
    case USER_STORE:
      const {user} = action.payload;
      return update(state, {
        $merge: {
          isLoggedIn: !!user,
          user,
        },
      });

    case USER_KIOSK_MODE:
      return {
        ...state,
        kioskModeEnabled: !state.kioskModeEnabled
      }

    case USER_LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
