import React from 'react';

import FormInputContainer from '../FormInputContainer';

import './styles.scss';

const FormTextarea = ({
  autoFocus,
  label,
  onChange,
  maxLength,
  note,
  type = 'text',
  isValid,
  validationMessage,
  value = '',
}) => {
  const handleOnChange = React.useCallback(
    (ev) => {
      const value = ev.target.value;

      if (!maxLength || value.length <= maxLength) {
        onChange(ev.target.value);
      }
    },
    [maxLength, onChange],
  );

  const inputNote = React.useMemo(() => {
    if (maxLength) {
      return `${value.length}/${maxLength}`;
    }

    return note;
  }, [note, value, maxLength]);

  return (
    <FormInputContainer
      label={label}
      note={inputNote}
      isValid={isValid}
      validationMessage={validationMessage}>
      <textarea
        autoFocus={autoFocus}
        className="FormTextarea"
        type={type}
        value={value}
        onChange={handleOnChange}
      />
    </FormInputContainer>
  );
};

export default FormTextarea;
