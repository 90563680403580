import { addAssetsForCollection } from '../redux/crypto'

import { getXcpImageUrl } from './NFTUtils'

export const getAsset = (name, dispatch) => {
  const url = `https://xchain.io/api/asset/${name}`
  console.log(url)
  fetch(url).then(res => res.json()).then(result => {
    if(result) {
      _formatData(result).then(nft => {
        dispatch(addAssetsForCollection([nft]))
      })
    }
  })
}

const _formatData = async (nft) => {
  let name = nft.asset_longname || nft.asset
  let description = nft.description
  let attributes = null
  let category = null
  let imageUrl = getXcpImageUrl({name, description})
  let videoUrl = null

  if (description.trim().endsWith('.json')) {
    // json file in description. fetch to get more metadata info about nft
    let url = nft.description.replace('\t', '')
    if (!url.startsWith('https://')) {
      url = url.replace('http://', '') // just in case url is not secure
      url = `https://${url}`
    }
    try {
      const res = await fetch(`https://xchain.io/relay?url=${url}`)
      const result = await res.json()

      if (result) {
        if (result.name) {
          name = result.name.trim()
        }
        if (result.description && !result.description.includes('<')) {
          description = result.description
        }

        const { image_large_hd, image_large, image, video } = result
        imageUrl = image_large_hd || image_large || image
        videoUrl = video

        attributes = result.attributes
        category = result.category || result.subcategory
      }

    } catch(err) {
      console.log('error getting xcp json file')
    }
  }

  return {
    token_id: nft.asset,
    name,
    description,
    attributes,
    category,
    imageUrl,
    videoUrl
  }
}

export default { getAsset }
