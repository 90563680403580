import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {addClassIfTrue, getNFTsForCollection} from '../../../../services/utils';

import {swapNFTs} from '../../../../redux/crypto';

import CustomModal from '../../../../components/CustomModal';
import ModalButtons from '../../../../components/ModalButtons';

import AdminPageCollectionsOrganizeModalNFT from './AdminPageCollectionsOrganizeModalNFT';

import './styles.scss';

const AdminPageCollectionsOrganizeModal = ({closeModal, collection}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMoving, setIsMoving] = React.useState(false);

  const collectionNFTs = useSelector((state) => state.Crypto.collectionNFTs);
  const nfts = useSelector((state) => state.Crypto.nfts);

  const currentCollectionNFTs = getNFTsForCollection(
    collection.id,
    nfts,
    collectionNFTs,
  );

  const isEmpty = !currentCollectionNFTs.length;

  const moveUp = (index) => {
    if (!isMoving && index > 0) {
      // swap collection with index - 1
      setIsMoving(true);
      dispatch(
        swapNFTs(
          collection.id,
          currentCollectionNFTs[index].id,
          currentCollectionNFTs[index - 1].id,
          () => setIsMoving(false),
        ),
      );
    }
  };

  const moveDown = (index) => {
    if (!isMoving && index < currentCollectionNFTs.length - 1) {
      // swap collection with index + 1
      setIsMoving(true);
      dispatch(
        swapNFTs(
          collection.id,
          currentCollectionNFTs[index].id,
          currentCollectionNFTs[index + 1].id,
          () => setIsMoving(false),
        ),
      );
    }
  };

  const buttonOnClick = React.useCallback(() => {
    if (isEmpty) {
      history.push('/admin/nfts');
    } else {
      closeModal();
    }
  }, [closeModal, history, isEmpty]);

  return (
    <CustomModal
      buttons={
        <ModalButtons
          buttonText={isEmpty ? "Add NFTs" : 'Save changes'}
          buttonOnClick={buttonOnClick}
          closeModal={closeModal}
        />
      }
      title="Organize collection"
      onRequestClose={closeModal}
      scrollable>
      <div className={addClassIfTrue('modal-boxed-text', 'disabled', isEmpty)}>
        {isEmpty
          ? 'You have not added any NFTs to this collection'
          : collection.name}
      </div>
      {!isEmpty && (
        <div className="AdminPageCollectionsOrganizeModal__list">
          {currentCollectionNFTs.map((nft, index) => (
            <AdminPageCollectionsOrganizeModalNFT
              key={`${nft.collection_id}_${index}`}
              collectionLength={currentCollectionNFTs.length}
              index={index}
              isMoving={isMoving}
              moveDown={moveDown}
              moveUp={moveUp}
              nft={nft}
            />
          ))}
        </div>
      )}
    </CustomModal>
  );
};

export default AdminPageCollectionsOrganizeModal;
