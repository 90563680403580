import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CustomModal from '../../CustomModal';
import ModalButtons from '../../ModalButtons';
import FormInput from '../../Form/FormInput';

import {login} from '../../../redux/user';

const LayoutHeaderKioskModal = ({closeModal}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(state => state.User.user)

  const [buttonState, setButtonState] = React.useState('default');
  const [password, setPassword] = React.useState('');
  const [validationMsg, setValidationMsg] = React.useState('');

  const onSuccess = () => {
    history.push('/admin')
    closeModal()
  }

  const onFailure = (msg) => {
    setButtonState('default');
    setValidationMsg(msg);
  }

  const onSubmit = React.useCallback((ev) => {
    ev.preventDefault()
    setValidationMsg(null)

    if (password.length === 0) {
      setValidationMsg('Please enter a password');
      return;
    }
    setButtonState('pending');
    dispatch(login(user.username, password, onSuccess, onFailure, false))
  }, [dispatch, password]);

  return (
    <CustomModal title="Enter password" onRequestClose={closeModal}>
      <form onSubmit={onSubmit}>
        <FormInput
          label="Password"
          onChange={setPassword}
          type="password"
          value={password}
        />
        <ModalButtons
          buttonText="continue"
          buttonPendingText="Verifying..."
          buttonState={buttonState}
          closeModal={closeModal}
        />
        {validationMsg && (
          <div className="form__validation">{validationMsg}</div>
        )}
      </form>
    </CustomModal>
  );
};

export default LayoutHeaderKioskModal;
