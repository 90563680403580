import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {toggleNewCollection, swapCollections} from '../../../redux/crypto';
import {useIsCreatorView} from '../../../redux/ui';

import {addClassIfTrue} from '../../../services/utils';

import CollectorCreatorToggle from '../../../components/CollectorCreatorToggle';
import FormSwitch from '../../../components/Form/FormSwitch';

import AdminPageCollectionsAddModal from './AdminPageCollectionsAddModal';
import AdminPageCollectionsOrganizeModal from './AdminPageCollectionsOrganizeModal';
import AdminPageCollectionsRemoveModal from './AdminPageCollectionsRemoveModal';

import './styles.scss';

const AdminPageCollections = () => {
  const dispatch = useDispatch();
  const isCreatorView = useIsCreatorView();

  const [selectedCollection, setSelectedCollection] = React.useState(null);
  const [isMovingCollection, setIsMovingCollection] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const user = useSelector((state) => state.User.user);

  const newCollections = useSelector(
    (state) => state.Crypto.newCollections || [],
  );

  const onAddCollection = React.useCallback(
    () => setOpenModal('add-collection'),
    [],
  );

  const closeModal = React.useCallback(() => setOpenModal(), []);

  const organizeCollectionClicked = React.useCallback(
    (collectionId) => {
      const collection = newCollections.find((c) => c.id === collectionId);

      if (collection) {
        setSelectedCollection(collection);
        setOpenModal('organize-collection');
      }
    },
    [newCollections],
  );

  const removeCollectionClicked = React.useCallback(
    (collectionId) => {
      const collection = newCollections.find((c) => c.id === collectionId);

      if (collection) {
        setSelectedCollection(collection);
        setOpenModal('remove-collection');
      }
    },
    [newCollections],
  );

  const editCollectionClicked = React.useCallback(
    (collectionId) => {
      const collection = newCollections.find((c) => c.id === collectionId);

      if (collection) {
        setSelectedCollection(collection);
        setOpenModal('edit-collection');
      }
    },
    [newCollections],
  );

  const _getFilteredCollections = () => {
    return isCreatorView
      ? newCollections.filter((c) => c.creation)
      : newCollections.filter((c) => !c.creation);
  };

  const moveCollectionUp = (index) => {
    if (isMovingCollection) {
      return;
    }
    if (index > 0) {
      // swap collection with index - 1
      const filteredCollections = _getFilteredCollections();

      setIsMovingCollection(true);
      dispatch(
        swapCollections(
          filteredCollections[index].id,
          filteredCollections[index - 1].id,
          () => setIsMovingCollection(false),
        ),
      );
    }
  };

  const moveCollectionDown = (index) => {
    if (isMovingCollection) {
      return;
    }
    if (index < newCollections.length - 1) {
      // swap collection with index + 1
      const filteredCollections = _getFilteredCollections();
      setIsMovingCollection(true);
      dispatch(
        swapCollections(
          filteredCollections[index].id,
          filteredCollections[index + 1].id,
          () => setIsMovingCollection(false),
        ),
      );
    }
  };

  const filteredCollections = _getFilteredCollections()

  return (
    <div className="AdminPageCollections">
      {/*<CollectorCreatorToggle baseClassName="AdminPage" />*/}
      <div className="AdminPageCollections__list">
        {filteredCollections.map((collection, index) => (
          <div
            className={'AdminPageCollections__item'}
            key={collection + '_' + index}>
            <div className="AdminPageCollections__upDown">
              <img
                onClick={() => moveCollectionUp(index)}
                className={addClassIfTrue(
                  '',
                  'disabled',
                  index === 0 || isMovingCollection,
                )}
                src="/button_up.svg"
                alt="up"
              />
              <img
                onClick={() => moveCollectionDown(index)}
                className={addClassIfTrue(
                  '',
                  'disabled',
                  index === newCollections.length - 1 || isMovingCollection,
                )}
                src="/button_down.svg"
                alt="down"
              />
            </div>
            <div className="AdminPageCollections__content">
              <div
                className={addClassIfTrue(
                  'AdminPageCollections__header',
                  'AdminPageCollections__header--disabled',
                  !collection.enabled,
                )}>
                <div className="AdminPageCollections__name">
                  <Link to={`/${user.username}/${collection.id}`}>
                    {collection.name}
                  </Link>
                  <span onClick={() => editCollectionClicked(collection.id)}>
                    (Edit)
                  </span>
                </div>
                <div className="AdminPageCollections__icons">
                  <div>
                    <FormSwitch
                      checked={collection.enabled}
                      onChange={() =>
                        dispatch(toggleNewCollection(collection.id, index))
                      }
                    />
                    <div
                      className="AdminPageCollections__organize"
                      onClick={() => organizeCollectionClicked(collection.id)}>
                      <img
                        src="/organize.svg"
                        alt="Organize NFTs within Collection"
                      />
                    </div>
                    <div
                      className="AdminPageCollections__remove"
                      onClick={() =>
                        removeCollectionClicked(collection.id, index)
                      }>
                      <img src="/trash.svg" alt="Remove Collection" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="AdminPageCollections__description">
                {collection.description}
              </div>
            </div>
          </div>
        ))}
        {filteredCollections.length === 0 &&
          <div className={'AdminPageCollections__item italic'}>
            {!isCreatorView &&
              "You have not created any Collections yet for your collected NFTs"
            }
            {isCreatorView &&
              "You have not created any Collections yet for your created NFTs"
            }
          </div>
        }
      </div>
      <div className={filteredCollections.length === 0 ? "button green center" : "button green"} onClick={onAddCollection}>
        new collection
      </div>
      {filteredCollections.length === 0 &&
        <div className='AdminPageCollections__subtext'>Create any number of cross-chain NFT collections. You may add NFTs to multiple collections.</div>
      }
      {openModal === 'organize-collection' && selectedCollection && (
        <AdminPageCollectionsOrganizeModal
          collection={selectedCollection}
          closeModal={closeModal}
        />
      )}
      {(openModal === 'edit-collection' || openModal === 'add-collection') && (
        <AdminPageCollectionsAddModal
          collectionToEdit={
            openModal === 'edit-collection' ? selectedCollection : null
          }
          closeModal={closeModal}
        />
      )}
      {selectedCollection && openModal === 'remove-collection' && (
        <AdminPageCollectionsRemoveModal
          collection={selectedCollection}
          index={newCollections.findIndex(
            (c) => c.id === selectedCollection.id,
          )}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default AdminPageCollections;
