import React from 'react';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {isEmpty} from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {addClassIfTrue} from '../../services/utils';

import FormCheckbox from '../Form/FormCheckbox';

import TablePagination from './NFTsTablePagination';
import TableToolbar from './NFTsTableToolbar';

import './styles.scss';

const IndeterminateCheckbox = React.forwardRef(
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <FormCheckbox ref={resolvedRef} {...rest} />;
  },
);

const NFTsTable = ({
  addToCollection,
  columns,
  data,
  setDisplayOverlay,
  toggleShowSelected,
  openModal,
  closeModal
}) => {
  const {
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    preGlobalFilteredRows,
    prepareRow,
    setGlobalFilter,
    setPageSize,
    state: {pageIndex, pageSize, selectedRowIds, globalFilter},
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {pageSize: 25},
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        {
          id: 'selection',
          disableSortBy: true,
          Header: ({getToggleAllRowsSelectedProps}) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({row}) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns.map((column, index) =>
          index === 0
            ? {
                ...column,
                Header: ({state: {selectedRowIds}}) => {
                  const disabled = isEmpty(selectedRowIds);

                  return (
                    <div className="NFTsTable__header-buttons">
                      <Tooltip
                        title="Add selected NFTs to collection"
                        placement="bottom"
                        disableFocusListener={disabled}
                        disableTouchListener={disabled}
                        disableHoverListener={disabled}>
                        <img
                          className={addClassIfTrue(
                            'NFTsTable__header-button',
                            'NFTsTable__header-button--disabled',
                            disabled,
                          )}
                          onClick={() => addToCollection(selectedRowIds)}
                          src="/add-to-collection.svg"
                          alt="add selected to collection"
                        />
                      </Tooltip>
                      <Tooltip
                        title="Toggle show for selected NFTs"
                        placement="bottom"
                        disableFocusListener={disabled}
                        disableTouchListener={disabled}
                        disableHoverListener={disabled}>
                        <img
                          className={addClassIfTrue(
                            'NFTsTable__header-button',
                            'NFTsTable__header-button--disabled',
                            disabled,
                          )}
                          onClick={() => toggleShowSelected(selectedRowIds)}
                          src="/show-hide.svg"
                          alt="toggle show selected"
                        />
                      </Tooltip>
                    </div>
                  );
                },
              }
            : column,
        ),
      ]);
    },
  );

  const onPageChange = React.useCallback((event, newPage) => {
    gotoPage(newPage);
  }, []);

  const onRowsPerPageChange = React.useCallback((event) => {
    setPageSize(Number(event.target.value));
  }, []);

  return (
    <>
      <TableToolbar
        globalFilter={globalFilter}
        numRows={preGlobalFilteredRows.length}
        numSelected={Object.keys(selectedRowIds).length}
        onPageChange={onPageChange}
        setDisplayOverlay={setDisplayOverlay}
        setGlobalFilter={setGlobalFilter}
        openModal={openModal}
        closeModal={closeModal}
      />
      <Table {...getTableProps()} className="NFTsTable">
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  className={column.hideMobile ? 'NFTsTable__hideMobile' : ''}
                  {...(column.id === 'selection'
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}>
                  {column.render('Header')}
                  {!column.disableSortBy && (
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                      IconComponent={ArrowDropDownIcon}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                className={!row.original.enabled ? 'NFTsTable--disabled' : ''}>
                {row.cells.map((cell) => (
                  <TableCell
                    className={
                      cell.column.hideMobile ? 'NFTsTable__hideMobile' : ''
                    }
                    {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        numRows={data.length}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsPerPage={pageSize}
        pageIndex={pageIndex}
      />
    </>
  );
};

export default NFTsTable;
