import React from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {setIsLightMode} from '../../../../redux/ui';

import './styles.scss';

const LayoutHeaderThemeSwitch = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const onLightModeClick = React.useCallback(
    () => dispatch(setIsLightMode(true)),
    [],
  );
  const onDarkModeClick = React.useCallback(
    () => dispatch(setIsLightMode(false)),
    [],
  );

  return !['/', '/reset_password'].includes(location.pathname) ? (
    <div className="LayoutHeaderThemeSwitch">
      <img src="/mode-light.svg" alt="light mode" onClick={onLightModeClick} />
      <img src="/mode-dark.svg" alt="dark mode" onClick={onDarkModeClick} />
    </div>
  ) : null;
};

export default LayoutHeaderThemeSwitch;
