import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {addClassIfTrue} from '../../../../services/utils';

import {addNFTstoCollections} from '../../../../redux/crypto';
import {useIsCreatorView} from '../../../../redux/ui';

import CustomModal from '../../../../components/CustomModal';
import ModalButtons from '../../../../components/ModalButtons';
import FormCheckbox from '../../../../components/Form/FormCheckbox';

import './styles.scss';

const AdminPageNFTsAddToCollectionModal = ({closeModal, nfts}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCreatorView = useIsCreatorView();

  const collections = useSelector((state) => state.Crypto.newCollections);
  const collectionNFTs = useSelector((state) => state.Crypto.collectionNFTs);

  const filteredCollections = React.useMemo(
    () => collections.filter((c) => (isCreatorView ? c.creation : !c.creation)),
    [isCreatorView, collections],
  );

  const getCheckedCollections = () => {
    if (nfts.length === 1) {
      const currentCollectionNFTs = collectionNFTs.filter(
        (cnft) => cnft.nft_id === nfts[0].id,
      );

      return filteredCollections.map((c) =>
        currentCollectionNFTs.some((nc) => nc.collection_id === c.id),
      );
    }

    return new Array(filteredCollections.length).fill(false);
  };

  const [checkedState, setCheckedState] = React.useState(getCheckedCollections);

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    );

    setCheckedState(updatedCheckedState);
  };

  const isEmpty = !filteredCollections.length;

  const onSubmit = React.useCallback(() => {
    if (isEmpty) {
      history.push('/admin/collections');
    } else {
      const selectedCollectionIds = [];

      checkedState.forEach(
        (cs, i) => cs && selectedCollectionIds.push(filteredCollections[i].id),
      );

      dispatch(
        addNFTstoCollections(
          selectedCollectionIds,
          nfts.map((n) => n.id),
          closeModal,
        ),
      );
    }
  }, [
    dispatch,
    nfts,
    checkedState,
    closeModal,
    filteredCollections,
    history,
    isEmpty,
  ]);

  const nftsText = React.useMemo(
    () => nfts.map((n) => n.name).join(', '),
    [nfts],
  );

  return (
    <CustomModal
      buttons={
        <ModalButtons
          buttonText={isEmpty ? 'New collection' : 'Save changes'}
          buttonPendingText="Saving..."
          buttonOnClick={onSubmit}
          closeModal={closeModal}
        />
      }
      title="Add to Collection"
      onRequestClose={closeModal}
      scrollable>
      <div className={addClassIfTrue('modal-boxed-text', 'disabled', isEmpty)}>
        {isEmpty ? 'You have not created any collections yet.' : nftsText}
      </div>
      {!isEmpty && (
        <div className="AdminPageNFTsAddToCollectionModal__list">
          {filteredCollections.map((c, i) => (
            <FormCheckbox
              checked={checkedState[i]}
              key={c.name + i}
              label={c.name}
              onChange={handleOnChange}
              value={i}
            />
          ))}
        </div>
      )}
    </CustomModal>
  );
};

export default AdminPageNFTsAddToCollectionModal;
