// https://api.better-call.dev/v1/account/mainnet/tz1eGbj5hDj5iVnPrB1DrC6Bbq89bTG1uBKs/token_balances?size=10&offset=20

import { addAssetsForCollection } from '../redux/crypto'

export const VIDEO_FORMATS = {'video/mp4': true, 'video/quicktime': true}

export const getAsset = (address, tokenId, dispatch) => {
  const apiUrl = `https://api.better-call.dev/v1/tokens/mainnet/metadata?contract=${address}&token_id=${tokenId}`
  fetch(apiUrl)
    .then((res) => res.json())
    .then((data) => {
      dispatch(addAssetsForCollection(data))
    })
}

export const getBetterCallDevMediaUrl = (nft) => {
  let video_url = ''
  let image_url = nft.image_url
  const mimeType = nft.media_type
  if (mimeType) {
    if (mimeType.toLowerCase() in VIDEO_FORMATS) {
      video_url = nft.media_url
    } else if (mimeType === 'image/svg+xml') {
      image_url = nft.image_url
    } else if (mimeType === 'application/x-directory') {
      image_url = nft.image_url
    }
  }
  return [
    image_url.replace("ipfs://", process.env.REACT_APP_IPFS_GATEWAY),
    video_url.replace("ipfs://", process.env.REACT_APP_IPFS_GATEWAY)
  ]
}

const exportedObject = { getAsset, getBetterCallDevMediaUrl }
export default exportedObject
