// import './styles.scss'

import { useQueryParams } from '../../services/utils'

const DEFAULT_BG_COLOR = '#171717'

const GlbPage = () => {
  const queryParams = useQueryParams()
  const uri = queryParams.get('uri')
  const width = queryParams.get('width') || 500;

  return (
    <model-viewer
      auto-rotate="true"
      autoplay="true"
      camera-controls="true"
      className="model-viewer"
      src={uri}
      ar-status="not-presenting"
      style={{height: width, width: width}}
    ></model-viewer>
  )
}
export default GlbPage
