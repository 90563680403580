import React from 'react';
import {useSelector} from 'react-redux';

import CHAINS from '../../../consts/Chains';

import AddAddressModal from '../../../components/AddAddressModal';
import AddNFTModal from '../../../components/AddNFTModal';
import AddNftsByFileModal from '../../../components/AddNFTModal/AddNftsByFileModal';

import AdminPageWalletsRemoveModal from './AdminPageWalletsRemove';

import './styles.scss';

const AdminPageWallets = () => {
  const [modalIsOpen, setIsOpen] = React.useState();
  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const addresses = useSelector((state) => state.Crypto.addresses);

  const onAddAddress = React.useCallback(() => setIsOpen('add-address'), []);

  const openModal = React.useCallback((modalType) => setIsOpen(modalType), []);

  const closeModal = React.useCallback(() => setIsOpen(undefined), []);

  const onRemoveAddress = React.useCallback((address) => {
    setSelectedAddress(address);
    setIsOpen('remove-wallet');
  }, []);

  const noAddressesFound = !addresses || addresses.length === 0;

  const renderAddresses = () => {
    const addressesDiv = [];

    CHAINS.forEach((x) => {
      const filteredAddresses = addresses.filter((a) => a.chain === x.chain);
      if (filteredAddresses.length > 0) {
        addressesDiv.push(
          <div
            key={x.name}
            className="AdminPageWallets__collections__collection__header">
            {x.name}
          </div>,
        );
        filteredAddresses.forEach((address) => {
          addressesDiv.push(
            <div
              className="AdminPageWallets__collections__collection"
              key={address.alias || address.address}>
              <div>
                <div className="AdminPageWallets__collections__collection__description">
                  {address.alias || address.address}
                </div>
              </div>
              <div
                className="AdminPageWallets__collections__collection__remove"
                onClick={() => onRemoveAddress(address)}>
                <img src="trash.svg" alt="Remove Collection" />
              </div>
            </div>,
          );
        });
      }
    });

    if (noAddressesFound) {
      addressesDiv.length = 0
      addressesDiv.push(
        <div className="AdminPageWallets__collections__collection italic">
            You have not added any wallet addresses to your profile.
        </div>
      )
    }

    return addressesDiv;
  };

  let buttonCls = 'button green '
  if (noAddressesFound) {
    buttonCls += 'center'
  }

  return (
    <div className="AdminPageWallets">
      <div className="AdminPageWallets__address">{renderAddresses()}</div>
      <div className={buttonCls} onClick={onAddAddress}>
        add wallet
      </div>
      {modalIsOpen === 'add-nft' && (
        <AddNFTModal openModal={openModal} closeModal={closeModal} />
      )}
      {modalIsOpen === 'add-nft-by-file' && (
        <AddNftsByFileModal openModal={openModal} closeModal={closeModal} />
      )}
      {modalIsOpen === 'add-address' && (
        <AddAddressModal openModal={openModal} closeModal={closeModal} hideAddresses />
      )}
      {modalIsOpen === 'remove-wallet' && (
        <AdminPageWalletsRemoveModal
          address={selectedAddress}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default AdminPageWallets;
