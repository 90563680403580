import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';

import './styles.scss';

const FormCheckbox = React.forwardRef(
  ({checked, label, value, onChange}, ref) => {
    const handleOnChange = React.useCallback((ev) =>
      typeof value !== 'undefined' ? onChange(value) : onChange(ev),
    );

    return (
      <label className="FormCheckbox">
        <Checkbox
          ref={ref}
          checked={checked}
          disableRipple
          onChange={handleOnChange}
          size="small"
          color="default"
        />
        <span className="FormCheckbox__label">{label}</span>
      </label>
    );
  },
);

export default FormCheckbox;
