import React from 'react';
import moment from 'moment';

import NFTPageItem from './NFTPageItem';

const NFTPageXCP = ({asset, nft}) => {
  const { imageUrl, videoUrl } = asset

  let mediaDiv = <img alt={imageUrl} src={imageUrl} />;
  if (videoUrl) {
    mediaDiv = (
      <video controls="controls" preload="metadata" loop>
        <source src={videoUrl} type="video/mp4" />
      </video>
    );
  }

  let linkDiv = (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://xchain.io/asset/${asset.token_id}`}>
      View on XChain
    </a>
  );

  return (
    <NFTPageItem
      allowFullscreen={!Boolean(videoUrl)}
      className="xcp"
      collection={asset.category}
      description={asset.description}
      imageUrl={imageUrl}
      mediaDiv={mediaDiv}
      mintDate={'Minted on Counterparty'}
      mintDateText={null}
      mintDateDarkModeIcon="/xcp-dark.svg"
      mintDateLightModeIcon="/xcp-light.svg"
      name={asset.name}
      nft={nft}
      linkDiv={linkDiv}
      traits={asset.attributes}
      simpleDescription
    />
  );
};

export default NFTPageXCP;
