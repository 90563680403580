const baseUrl = 'https://polygon-mainnet.g.alchemy.com/nft/v3/Eh3Xe-AqtV-v037CzEYjtpgYnA9niHUr'

export const getAsset = async (chain, contractAddress, tokenId) => {
  if (chain !== 'polygon') {
    console.log('only polygon currently supported')
    return 
  }
  const apiUrl = `${baseUrl}/getNFTMetadata?contractAddress=${contractAddress}&tokenId=${tokenId}`
  console.log(apiUrl)
  const res = await fetch(apiUrl)
  const data = await res.json()
  const { contract, description, image, name, raw } = data
  return {
    name,
    description,
    contractAddress: contract.address,
    tokenId: data.tokenId,
    imageUrl: image.cachedUrl,
    // media_url: image.animation_url,
    chain
  }
}


// id: `${asset.contract}_${asset.identifier}`,
// name: asset.name,
// contract_address: contract,
// token_id: identifier,
// image_url: image_url || asset.animation_url || '',
// media_url: asset.animation_url,
// chain: 'eth'

