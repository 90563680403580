import './config';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import configureStore from './store';

import AdminPage from './pages/AdminPage';
import CollectionPage from './pages/CollectionPage';
import LandingPage from './pages/LandingPage';
import NFTPage from './pages/NFTPage';
import ProfilePage from './pages/ProfilePage';
import AudioPage from './pages/AudioPage';
import GlbPage from './pages/GlbPage';

import PurchaseDemo from './pages/PurchaseDemo';
import Rinkeby from './pages/PurchaseDemo/rinkeby';

import Layout from './components/Layout';

import {checkLoggedIn} from './redux/user';

import './index.css';

// import reportWebVitals from './reportWebVitals';

const store = configureStore();

// initialize Bugsnag to track javascript errors
Bugsnag.start({
  apiKey: '7d15cc7e95091d23ceb11384d37a14b5',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  appVersion: process.env.REACT_APP_BUGSNAG_VERSION
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path='/audio' component={AudioPage} />
          <Route path='/model' component={GlbPage} />
          <Layout>
            <Switch>
            <Route path='/purchase' component={PurchaseDemo} />
            <Route path='/rinkeby' component={Rinkeby} />
            <Route path="/admin/:tab?" component={AdminPage} />
            <Route
              path={['/', '/reset_password']}
              exact
              component={LandingPage}
            />
            <Route
              path={[
                '/nft/sol/:token_id',
                '/nft/polygon/:contract_address/:token_id',
                '/nft/rinkeby/:contract_address/:token_id',
                '/nft/:contract_address/:token_id',
              ]}
              component={NFTPage}
            />
            <Route
              path="/:username/:collection_id"
              component={CollectionPage}
            />
            <Route path="/:username" component={ProfilePage} />
            </Switch>
          </Layout>
        </Switch>
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// initialize the app with the current user, if available
store.dispatch(checkLoggedIn());
