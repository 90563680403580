import React from 'react';
import {useDispatch} from 'react-redux';

import {forgotPassword} from '../../../redux/user';

import CustomModal from '../../CustomModal';
import FormInput from '../../Form/FormInput';
import ModalButtons from '../../ModalButtons';

import '../../components.scss';

const LayoutHeaderForgotPasswordModal = ({closeModal}) => {
  const [buttonState, setButtonState] = React.useState('default');
  const [email, setEmail] = React.useState('');
  const [validationMsg, setValidationMsg] = React.useState('');

  const dispatch = useDispatch();

  const handleSubmit = React.useCallback(() => {
    const _email = email.trim();
    if (_email.length === 0) {
      setValidationMsg('Please enter an email');
      setButtonState('disabled');
      return;
    }
    setButtonState('pending');
    dispatch(
      forgotPassword(
        email,
        (msg) => {
          setButtonState('default');
          setValidationMsg(msg);
        },
        (msg) => {
          setButtonState('default');
          setValidationMsg(msg);
        },
      ),
    );
  }, [dispatch, email]);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <CustomModal title="Forgot Password" onRequestClose={closeModal}>
      <form onSubmit={onSubmit}>
        <FormInput
          autoFocus
          label="Email Address"
          value={email}
          onChange={setEmail}
        />
        <ModalButtons
          buttonText="send reset email"
          buttonPendingText="sending..."
          buttonState={buttonState}
          closeModal={closeModal}
        />
        {validationMsg.length > 0 && (
          <div className="form__validation success">{validationMsg}</div>
        )}
      </form>
    </CustomModal>
  );
};

export default LayoutHeaderForgotPasswordModal;
